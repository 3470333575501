import {
  getEventDispatcherHandlerInstance,
  getAmplitudeEventDispatcherInstance,
} from '@quintoandar/event-tracker-core';

import { getLoggedUser } from 'Base/Security/user';
import { trackingConfig } from '../trackingConfig';

export const useEventDispatcherHandler = () => {
  const amplitudeEventDispatcher = getAmplitudeEventDispatcherInstance(
    getLoggedUser,
    trackingConfig.amplitudeConfig,
    trackingConfig.globalConfig,
  );

  const handlers = {
    amplitudeEventDispatcher,
  };

  return getEventDispatcherHandlerInstance(
    handlers,
    trackingConfig.globalConfig,
  );
};
