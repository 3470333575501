export const NONE = 'None';
export const DIDNT_GET_KEYS = 'DidntGetKeys';
export const WRONG_ADDRESS = 'WrongAddress';
export const RENTED_OR_UNAVAILABLE = 'RentedOrUnavailable';
export const LOCKBOX_PROBLEMS = 'LockboxProblems';
export const NO_ENTRANCE_PERMISSION = 'NoEntrancePermission';
export const TENANT_DELAY = 'TenantDelay';
export const KEYS_KEEPER_NOT_PRESENT = 'KeysKeeperNotPresent';
export const DAMAGED_KEYS = 'DamagedKeys';
export const KEYS_NOT_AVAILABLE = 'KeysNotAvailable';
export const RENTED = 'Rented';
export const RENTED_OR_LANDLORD_QUIT_RENTING = 'RentedOrLandlordQuitRenting';
export const TEMPORARILY_UNAVAILABLE = 'TemporarilyUnavailable';
export const RESIDENT_TENANT_DIDNT_KNOW_VISIT = 'ResidentTenantDidntKnowVisit';
export const NO_LOCKBOX = 'NoLockbox';
export const BROKEN_LOCKBOX = 'BrokenLockbox';
export const NO_KEYS_LOCKBOX = 'NoKeysLockbox';
export const NAME_NOT_IN_THE_LIST = 'NameNotInTheList';
export const NO_INDIVIDUAL_AUTHORIZATION = 'NoIndividualAuthorization';
export const NO_COLLECTIVE_AUTHORIZATION = 'NoCollectiveAuthorization';
export const BUILDING_AS_OK_AUTHORIZATION_LIST_REQUIRED =
  'BuildingAsOkAuthorizationListRequired';
export const VISIT_OFF_ALLOWED_HOURS = 'VisitOffAllowedHours';
export const MISSPLACED_KEYS = 'MissplacedKeys';
export const WRONG_PASSWORD = 'WrongPassword';
export const LANDLORD_NO_SHOW = 'LandlordNoShow';
export const SOLD_BY_ANOTHER_COMPANY = 'SoldByAnotherCompany';
export const RENTED_BY_ANOTHER_COMPANY = 'RentedByAnotherCompany';
export const PROPERTY_NOT_FOR_SALE = 'PropertyNotForSale';
export const PROPERTY_NOR_FOR_RENT = 'PropertyNorForRent';
export const OWNER_QUIT_ANNOUNCEMENT = 'OwnerQuitAnnouncement';
export const RESIDENT_TENANT_DIDNT_ALLOW_VISIT =
  'ResidentTenantDidntAllowVisit';
export const NO_SHOW = 'NoShow';

/**
 * Enum of entrance problems with message ids to display in visit card
 */
export const ENTRANCE_PROBLEM = Object.freeze({
  NONE: {
    value: NONE,
    messageId: 'none',
  },
  DIDNT_GET_KEYS: {
    value: DIDNT_GET_KEYS,
    messageId: 'didntGetKeys',
  },
  WRONG_ADDRESS: {
    value: WRONG_ADDRESS,
    messageId: 'wrongAddress',
  },
  RENTED_OR_UNAVAILABLE: {
    value: RENTED_OR_UNAVAILABLE,
    messageId: 'rentedOrUnavailable',
  },
  LOCKBOX_PROBLEMS: {
    value: LOCKBOX_PROBLEMS,
    messageId: 'lockboxProblems',
  },
  NO_ENTRANCE_PERMISSION: {
    value: NO_ENTRANCE_PERMISSION,
    messageId: 'noEntrancePermission',
  },
  TENANT_DELAY: {
    value: TENANT_DELAY,
    messageId: 'tenantDelay',
  },
  KEYS_KEEPER_NOT_PRESENT: {
    value: KEYS_KEEPER_NOT_PRESENT,
    messageId: 'keysKeeperNotPresent',
  },
  DAMAGED_KEYS: {
    value: DAMAGED_KEYS,
    messageId: 'damagedKeys',
  },
  KEYS_NOT_AVAILABLE: {
    value: KEYS_NOT_AVAILABLE,
    messageId: 'keysNotAvailable',
  },
  RENTED: {
    value: RENTED,
    messageId: 'rented',
  },
  RENTED_OR_LANDLORD_QUIT_RENTING: {
    value: RENTED_OR_LANDLORD_QUIT_RENTING,
    messageId: 'rentedOrLandlordQuitRenting',
  },
  TEMPORARILY_UNAVAILABLE: {
    value: TEMPORARILY_UNAVAILABLE,
    messageId: 'temporarilyUnavailable',
  },
  RESIDENT_TENANT_DIDNT_KNOW_VISIT: {
    value: RESIDENT_TENANT_DIDNT_KNOW_VISIT,
    messageId: 'residentTenantDidntKnowVisit',
  },
  NO_LOCKBOX: {
    value: NO_LOCKBOX,
    messageId: 'noLockbox',
  },
  BROKEN_LOCKBOX: {
    value: BROKEN_LOCKBOX,
    messageId: 'brokenLockbox',
  },
  NO_KEYS_LOCKBOX: {
    value: NO_KEYS_LOCKBOX,
    messageId: 'noKeysLockbox',
  },
  NAME_NOT_IN_THE_LIST: {
    value: NAME_NOT_IN_THE_LIST,
    messageId: 'nameNotInTheList',
  },
  NO_INDIVIDUAL_AUTHORIZATION: {
    value: NO_INDIVIDUAL_AUTHORIZATION,
    messageId: 'noIndividualAuthorization',
  },
  NO_COLLECTIVE_AUTHORIZATION: {
    value: NO_COLLECTIVE_AUTHORIZATION,
    messageId: 'noCollectiveAuthorization',
  },
  BUILDING_AS_OK_AUTHORIZATION_LIST_REQUIRED: {
    value: BUILDING_AS_OK_AUTHORIZATION_LIST_REQUIRED,
    messageId: 'buildingAsOkAuthorizationListRequired',
  },
  VISIT_OFF_ALLOWED_HOURS: {
    value: VISIT_OFF_ALLOWED_HOURS,
    messageId: 'visitOffAllowedHours',
  },
  MISSPLACED_KEYS: {
    value: MISSPLACED_KEYS,
    messageId: 'missplacedKeys',
  },
  WRONG_PASSWORD: {
    value: WRONG_PASSWORD,
    messageId: 'wrongPassword',
  },
  LANDLORD_NO_SHOW: {
    value: LANDLORD_NO_SHOW,
    messageId: 'keysKeeperNotPresent',
  },
  SOLD_BY_ANOTHER_COMPANY: {
    value: SOLD_BY_ANOTHER_COMPANY,
    messageId: 'soldByAnotherCompany',
  },
  RENTED_BY_ANOTHER_COMPANY: {
    value: RENTED_BY_ANOTHER_COMPANY,
    messageId: 'rentedByAnotherCompany',
  },
  PROPERTY_NOT_FOR_SALE: {
    value: PROPERTY_NOT_FOR_SALE,
    messageId: 'propertyNotForSale',
  },
  PROPERTY_NOR_FOR_RENT: {
    value: PROPERTY_NOR_FOR_RENT,
    messageId: 'propertyNorForRent',
  },
  OWNER_QUIT_ANNOUNCEMENT: {
    value: OWNER_QUIT_ANNOUNCEMENT,
    messageId: 'ownerQuitAnnouncement',
  },
  RESIDENT_TENANT_DIDNT_ALLOW_VISIT: {
    value: RESIDENT_TENANT_DIDNT_ALLOW_VISIT,
    messageId: 'residentTenantDidntAllowVisit',
  },
  BUILDING_AS_OK_AUTHORIZATION_REQUIRED: {
    problem: 'BuildingAsOkAuthorizationListRequired',
    messageId: 'buildingAsOkAuthorizationListRequired',
  },
});

export default ENTRANCE_PROBLEM;
