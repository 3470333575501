import {
  FIREBASE_LOGIN_START,
  FIREBASE_LOGIN_SUCCESS,
  FIREBASE_LOGIN_FAILURE,
  FIREBASE_LOGOUT,
} from 'containers/Firebase/constants';

export const firebaseLoginStart = (token) => ({
  type: FIREBASE_LOGIN_START,
  payload: {
    token,
  },
});

export const firebaseLoginSuccess = () => ({
  type: FIREBASE_LOGIN_SUCCESS,
});

export const firebaseLoginFailure = (error) => ({
  type: FIREBASE_LOGIN_FAILURE,
  payload: {
    error,
  },
});

export const firebaseLogout = () => ({
  type: FIREBASE_LOGOUT,
});
