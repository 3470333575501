export const ADMINISTRATOR = Object.freeze({
  OWNER: 'OWNER',
  QUINTOANDAR: 'QUINTOANDAR',
  THIRD_PARTY: 'THIRD_PARTY',
});

export const EXTERNAL_RENTAL_ADMINISTRATORS = [
  ADMINISTRATOR.OWNER,
  ADMINISTRATOR.THIRD_PARTY,
];
