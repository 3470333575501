/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import { AGENDA_UNREAD_MESSAGES_SNACKBAR_CHAT_CLICKED_ANALYTICS } from 'containers/Agenda/UnreadMessageSnackbar/constants';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleSnackbarActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case AGENDA_UNREAD_MESSAGES_SNACKBAR_CHAT_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'chatcrpp_access_allchats_clicked',
        immutablePage(PAGE_NAMES.SNACKBAR),
      );
      break;

    default:
      break;
  }
};

export default handleSnackbarActions;
