/*
 *
 * App selectors
 *
 */
import { createSelector } from 'reselect';
import { makeSelectUser } from '@quintoandar/bioma-auth/containers/selectors';
import { agentWorksWithRent, agentWorksWithSales } from 'utils/agents';
import { immutableToImmer } from 'utils';

/**
 * Direct selector to the app state domain
 */
const selectAppDomain = (state) => state.get('app');

/**
 * Direct selector to the app state location
 */
const selectLocation = (state) =>
  state.getIn(['route', 'locationBeforeTransitions', 'pathname']);

/**
 * Selects if agent rating value has been loaded
 */
const selectHasLoadedRating = (state) =>
  selectAppDomain(state).get('hasLoadedRating');

/**
 * Default selector used by App
 */
const makeSelectApp = () =>
  createSelector(selectAppDomain, (substate) => substate.toJS());

const selectAgentData = createSelector(selectAppDomain, (substate) =>
  substate.get('agentData'),
);

/**
 * Agent rating selector
 */
const selectAgentRating = () =>
  createSelector(selectAppDomain, (substate) => {
    if (!substate.getIn(['agentData', 'hasReviews'])) {
      return null;
    }

    return substate.getIn(['agentData', 'rating']);
  });

/**
 * Agent profile refresh
 */
const makeSelectAgentProfileRefreshed = () =>
  createSelector(selectAppDomain, (subState) =>
    subState.get('refreshedProfile'),
  );

/**
 * Agent business context selector
 */
const makeSelectAgentBusinessContexts = () =>
  createSelector(selectAppDomain, (subState) => {
    const businessContext = subState.getIn(['agentData', 'businessContext']);

    if (!businessContext) {
      return [];
    }

    return businessContext.toJS();
  });

const makeSelectIsOptedForOnlineSupport = () =>
  createSelector(selectAgentData, (substate) =>
    substate.get('optedForOnlineSupport'),
  );

const makeSelectAgentWorksWithSales = () =>
  createSelector(makeSelectAgentBusinessContexts(), (businessContext) =>
    agentWorksWithSales(businessContext),
  );

const makeSelectAgentWorksWithRent = () =>
  createSelector(makeSelectAgentBusinessContexts(), (businessContext) =>
    agentWorksWithRent(businessContext),
  );

const makeSelectIsSnackbarVisible = () =>
  createSelector(selectAppDomain, (substate) =>
    substate.getIn(['snackbar', 'isVisible']),
  );

const makeSelectIsPassiveLeadReceiver = () =>
  createSelector(selectAppDomain, (substate) =>
    substate.getIn(['agentData', 'isPassiveLeadReceiver']),
  );

const makeSelectIsProspectChatEnabled = () =>
  createSelector(selectAgentData, (substate) =>
    substate.get('isProspectChatEnabled'),
  );

/**
 * makeSelectLocationState expects a plain JS object for the routing state
 *
 * @returns {function(*)}
 */
const makeSelectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return (state) => {
    const routingState = state.get('route'); // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};

/**
 * makeSelectHasUnreadMessages selects whether there are unread messages for the current user
 *
 * @returns bool
 */
const makeSelectHasUnreadMessages = () =>
  createSelector(selectAppDomain, (substate) =>
    substate.get('hasUnreadMessages'),
  );

/**
 * makeSelectShowAgentKeys selects if the agent keys area should be shown
 *
 * @returns bool
 */
const makeSelectShowAgentKeys = () =>
  createSelector(selectAppDomain, (substate) => substate.get('showAgentKeys'));

const selectAgentId = createSelector(
  immutableToImmer(makeSelectUser()),
  (state) => state.agentId,
);

const selectAgentUserId = createSelector(
  immutableToImmer(makeSelectUser()),
  (state) => state.id,
);

const selectAgentName = createSelector(
  immutableToImmer(makeSelectUser()),
  (state) => state.name,
);

export {
  selectAppDomain,
  makeSelectApp,
  selectHasLoadedRating,
  makeSelectLocationState,
  makeSelectIsSnackbarVisible,
  selectAgentRating,
  makeSelectAgentBusinessContexts,
  makeSelectIsOptedForOnlineSupport,
  makeSelectAgentWorksWithSales,
  makeSelectAgentWorksWithRent,
  makeSelectAgentProfileRefreshed,
  selectLocation,
  makeSelectHasUnreadMessages,
  makeSelectShowAgentKeys,
  selectAgentId,
  selectAgentUserId,
  selectAgentName,
  makeSelectIsPassiveLeadReceiver,
  makeSelectIsProspectChatEnabled,
};
