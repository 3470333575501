import { getLoggedUser } from 'Base/Security/user';
import { useEventDispatcherHandler } from './useEventDispatcherHandler';
import { TrackingServices } from '../constants';
import { defaultEventProperties } from '../defaultEventProperties';

export const useTrackEvent = () => {
  const eventDispatcherHandler = useEventDispatcherHandler();
  const loggedUser = getLoggedUser();

  const trackEvent = ({
    event,
    pageName,
    extraProperties = {},
    trackingServicesList = [TrackingServices.amplitude],
  }) => {
    if (!eventDispatcherHandler) return;

    eventDispatcherHandler.logWithDefaultEventProps(
      event,
      {},
      {
        ...defaultEventProperties({
          pageName,
          loggedUser,
        }),
        ...extraProperties,
      },
      trackingServicesList,
    );
  };

  return trackEvent;
};
