import {
  DEFAULT_CONTRACTS_DATE_PERIOD,
  DEFAULT_DATE_PERIOD,
  DEFAULT_DOCUMENTATION_DATE_PERIOD,
} from 'config';
import { fromJS } from 'immutable';

import moment from 'moment';
import {
  CLEAR_FILTERS,
  RESTORE_PREVIOUS_FILTERS,
  SUBMIT_FILTERS,
  UPDATE_FILTER,
} from 'containers/Filters/constants';
import {
  FILTERS_MAP_CONTRACT_STATUS,
  FILTERS_MAP_DATE,
  FILTERS_MAP_STATUS,
  FILTERS_MAP_SORTING,
  FILTERS_MAP_VISIT_STATUS,
  FILTERS_VISITS_GROUPS,
} from 'containers/Filters/enums';
import { getDaysAgo } from 'utils/date';
import { OPTION_TYPES } from '../../constants';

const dateFormat = 'YYYY-MM-DD';
const currentDay = moment().format(dateFormat);
const contractsLimitDay = moment(
  getDaysAgo(DEFAULT_CONTRACTS_DATE_PERIOD),
).format(dateFormat);
const visitsLimitDay = moment(getDaysAgo(DEFAULT_DATE_PERIOD)).format(
  dateFormat,
);
const documentationLimitDay = moment(
  getDaysAgo(DEFAULT_DOCUMENTATION_DATE_PERIOD),
).format(dateFormat);

export const initialState = fromJS({
  contracts: {
    hasFiltersApplied: false,
    // TODO: Remove `contracts-filters` section as its not being used anymore
    'contracts-filters': {
      default: FILTERS_MAP_DATE.LAST_30_DAYS.name,
      selected: FILTERS_MAP_DATE.LAST_30_DAYS.name,
      type: OPTION_TYPES.RADIO,
    },
    period: {
      end: {
        default: currentDay,
        selected: currentDay,
      },
      start: {
        default: contractsLimitDay,
        selected: contractsLimitDay,
      },
    },
    'contracts-filters-status': {
      default: FILTERS_MAP_CONTRACT_STATUS.ALL.name,
      selected: FILTERS_MAP_CONTRACT_STATUS.ALL.name,
      type: OPTION_TYPES.RADIO,
    },
    'contracts-sorting': {
      default: FILTERS_MAP_SORTING.DESC.name,
      selected: FILTERS_MAP_SORTING.DESC.name,
      type: OPTION_TYPES.RADIO,
    },
  },
  offers: {
    hasFiltersApplied: false,
    'offers-filters': {
      default: FILTERS_MAP_DATE.LAST_30_DAYS.name,
      selected: FILTERS_MAP_DATE.LAST_30_DAYS.name,
      type: OPTION_TYPES.RADIO,
    },
    'offers-filters-status': {
      default: FILTERS_MAP_STATUS.ALL.name,
      selected: FILTERS_MAP_STATUS.ALL.name,
      type: OPTION_TYPES.RADIO,
    },
    'offers-sorting': {
      default: FILTERS_MAP_SORTING.DESC.name,
      selected: FILTERS_MAP_SORTING.DESC.name,
      type: OPTION_TYPES.RADIO,
    },
  },
  visits: {
    hasFiltersApplied: false,
    [FILTERS_VISITS_GROUPS.DATE]: {
      default: FILTERS_MAP_DATE.LAST_25_DAYS.name,
      selected: FILTERS_MAP_DATE.LAST_25_DAYS.name,
      type: OPTION_TYPES.RADIO,
    },
    period: {
      end: {
        default: currentDay,
        selected: currentDay,
      },
      start: {
        default: visitsLimitDay,
        selected: visitsLimitDay,
      },
    },
    [FILTERS_VISITS_GROUPS.SORTING]: {
      default: FILTERS_MAP_SORTING.DESC.name,
      selected: FILTERS_MAP_SORTING.DESC.name,
      type: OPTION_TYPES.RADIO,
    },
    [FILTERS_VISITS_GROUPS.STATUS]: {
      default: FILTERS_MAP_VISIT_STATUS.ALL.name,
      selected: FILTERS_MAP_VISIT_STATUS.ALL.name,
      type: OPTION_TYPES.RADIO,
    },
  },
  documentations: {
    hasFiltersApplied: false,
    'documentations-filters': {
      default: FILTERS_MAP_DATE.LAST_30_DAYS.name,
      selected: FILTERS_MAP_DATE.LAST_30_DAYS.name,
      type: OPTION_TYPES.RADIO,
    },
    'documentations-filters-status': {
      default: FILTERS_MAP_STATUS.ALL.name,
      selected: FILTERS_MAP_STATUS.ALL.name,
      type: OPTION_TYPES.RADIO,
    },
    'documentations-sorting': {
      default: FILTERS_MAP_SORTING.DESC.name,
      selected: FILTERS_MAP_SORTING.DESC.name,
      type: OPTION_TYPES.RADIO,
    },
    period: {
      end: {
        default: currentDay,
        selected: currentDay,
      },
      start: {
        default: documentationLimitDay,
        selected: documentationLimitDay,
      },
    },
  },
  ongoing: {
    hasFiltersApplied: false,
    'ongoing-sorting': {
      default: FILTERS_MAP_SORTING.DESC.name,
      selected: FILTERS_MAP_SORTING.DESC.name,
      type: OPTION_TYPES.RADIO,
    },
  },
  canceled: {
    hasFiltersApplied: false,
    'canceled-sorting': {
      default: FILTERS_MAP_SORTING.DESC.name,
      selected: FILTERS_MAP_SORTING.DESC.name,
      type: OPTION_TYPES.RADIO,
    },
  },
  finished: {
    hasFiltersApplied: false,
    'finished-sorting': {
      default: FILTERS_MAP_SORTING.DESC.name,
      selected: FILTERS_MAP_SORTING.DESC.name,
      type: OPTION_TYPES.RADIO,
    },
  },
});

const handleClearFilters = (state, { screen }) => {
  let updatedState = state;

  if (screen) {
    updatedState = state.setIn([screen], initialState.get(screen));
  }

  return updatedState;
};

const handleRestorePreviousFilters = (state, { previousFilters, screen }) => {
  let updatedState = state;

  updatedState = state.setIn([screen], fromJS(previousFilters));

  return updatedState;
};

const handleUpdateFilter = (state, { filter, group, screen, type }) => {
  let updatedState = state;

  if (type === OPTION_TYPES.CHECKBOX) {
    // Try to get the current filter from the previous state
    // If it exists we set it to false otherwise set it to true
    const hasFilter = state.hasIn([screen, group, filter]);

    updatedState = state
      .setIn([screen, group, filter], !hasFilter)
      .setIn([screen, group, 'type'], type);
  } else if (type === OPTION_TYPES.RADIO) {
    const hasSelectedFilter = state.hasIn([screen, group, 'selected']);

    if (hasSelectedFilter) {
      updatedState = state
        .setIn([screen, group, 'selected'], filter)
        .setIn([screen, group, 'type'], type);
    } else {
      updatedState = state
        .setIn([screen], initialState.get(screen))
        .setIn([screen, group, 'selected'], filter)
        .setIn([screen, group, 'type'], type);
    }
  } else if (type === OPTION_TYPES.DATE) {
    updatedState = state
      .setIn([screen], state.get(screen))
      .setIn([screen, 'period', group, 'selected'], filter);
  }

  return updatedState;
};

function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_FILTERS:
      return handleClearFilters(state, action.payload);
    case RESTORE_PREVIOUS_FILTERS:
      return handleRestorePreviousFilters(state, action.payload);
    case SUBMIT_FILTERS:
      return state.setIn(
        [action.payload.screen, 'hasFiltersApplied'],
        action.payload.isFilterSet,
      );
    case UPDATE_FILTER:
      return handleUpdateFilter(state, action.payload);
    default:
      return state;
  }
}

export default filtersReducer;
