import AppBar, {
  AppBarMobile,
  PageHeader,
} from '@quintoandar/cozy-core/AppBar';
import LogoRede from './logoRede';

const AppHeader = () => (
  <AppBar
    mobile={<AppBarMobile />}
    desktop={<PageHeader logo={<LogoRede />} />}
  />
);

export default AppHeader;
