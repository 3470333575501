import { AGENTS_API_URL, HTTP_METHODS } from 'config';

import { request, postRequest } from '@quintoandar/wat-fetch';

/**
 * Basic configuration for agents api calls
 *
 * @param {string} endpoint - The endpoint that will be called
 * @param {object} [filters] - The filters object for the current screen
 * @param {string} method - The HTTP method that will be used
 * @param {object} [payload] - The data
 * @param {string} [tab] - Current active tab
 *
 * @returns {Promise}
 */
export const agentsApiConfig = async ({
  endpoint,
  method,
  payload,
  queryString,
  extraHeaders,
}) => {
  let config;
  let options;
  switch (method) {
    default:
    case HTTP_METHODS.GET:
    case HTTP_METHODS.PUT:
    case HTTP_METHODS.DELETE:
      options = {
        method,
        headers: extraHeaders,
        body: payload ? JSON.stringify(payload) : undefined,
      };
      if (queryString) {
        config = await request(
          `${AGENTS_API_URL}/${endpoint}?${queryString}`,
          options,
        );
      } else {
        config = await request(`${AGENTS_API_URL}/${endpoint}`, options);
      }
      break;
    case HTTP_METHODS.POST:
      config = await postRequest(
        `${AGENTS_API_URL}/${endpoint}`,
        payload,
        extraHeaders,
      );
      break;
  }
  return config;
};

export default agentsApiConfig;
