import {
  EMPTY,
  COMMON,
  PUBLISHED,
  OWNER_PRESENT,
  LOCKBOX,
  FRONT_DOOR,
  KEYS_LOCKER,
  PASSWORD,
  HOUSE_NOT_AVAILABLE,
  KEYS_WITH_AGENT,
  KEYS_WITH_OWNER,
  KEYS_WITH_OWNER_TO_AGENT,
  KEYS_ON_LOCKBOX,
  KEYS_ON_FRONT_DOOR,
  KEYS_ON_LOCKER,
  PASSWORD_WITH_OWNER,
  PASSWORD_PROVIDED,
  BIOMETRY,
  BIOMETRY_WITH_OWNER,
  UNKNOWN_LABEL,
  OWNER,
  OWNER_IN_HOUSE,
  TENANT_IN_HOUSE,
  TENANT,
  ERROR_LABEL,
} from 'utils/entryInfo/constants';

const entryInfoLabelForCommonType = (authorization) => {
  switch (authorization) {
    case OWNER_PRESENT:
      return KEYS_WITH_OWNER;
    case LOCKBOX:
      return KEYS_ON_LOCKBOX;
    case FRONT_DOOR:
      return KEYS_ON_FRONT_DOOR;
    case KEYS_LOCKER:
      return KEYS_ON_LOCKER;
    default:
      return UNKNOWN_LABEL;
  }
};

const entryInfoLabelForPasswordType = (authorization) => {
  switch (authorization) {
    case OWNER_PRESENT:
      return PASSWORD_WITH_OWNER;
    case PASSWORD:
      return PASSWORD_PROVIDED;
    default:
      return UNKNOWN_LABEL;
  }
};

const entryInfoLabelForBiometryType = (authorization) => {
  if (authorization === OWNER_PRESENT) {
    return BIOMETRY_WITH_OWNER;
  }
  return UNKNOWN_LABEL;
};

export const entryInfoLabel = ({
  accessType,
  listingStatus,
  agentHasKeys,
  keysWithAgent,
}) => {
  if (!accessType) {
    return ERROR_LABEL;
  }
  const { occupant, type, authorization } = accessType;

  if (listingStatus !== PUBLISHED) {
    return HOUSE_NOT_AVAILABLE;
  }

  if (keysWithAgent) {
    return agentHasKeys ? KEYS_WITH_AGENT : KEYS_WITH_OWNER_TO_AGENT;
  }

  if (occupant === EMPTY) {
    if (type === COMMON) {
      return entryInfoLabelForCommonType(authorization);
    }

    if (type === PASSWORD) {
      return entryInfoLabelForPasswordType(authorization);
    }

    if (type === BIOMETRY) {
      return entryInfoLabelForBiometryType(authorization);
    }
  }

  if (occupant === OWNER) {
    return OWNER_IN_HOUSE;
  }

  if (occupant === TENANT) {
    return TENANT_IN_HOUSE;
  }

  return UNKNOWN_LABEL;
};
