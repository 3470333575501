import { ASK_FOR_PUSH } from 'containers/App/constants';

export const handleAppActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case ASK_FOR_PUSH:
      eventDispatcherHandler.setUserProperties({
        askForWebPushToken: true,
      });
      break;

    default:
      break;
  }
};

export default handleAppActions;
