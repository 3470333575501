export const FETCH_ALL_AGENT_CLIENTS =
  'containers/SearchClients/FETCH_ALL_AGENT_CLIENTS';

export const CLIENTS_SCHEDULED_VISIT_SEARCH_TYPED =
  'containers/SearchClients/CLIENTS_SCHEDULED_VISIT_SEARCH_TYPED';
export const CLIENTS_SCHEDULED_VISIT_EXPAND_CARD_CLICKED =
  'containers/SearchClients/CLIENTS_SCHEDULED_VISIT_EXPAND_CARD_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_SEND_MESSAGE_LINK_CLICKED =
  'containers/SearchClients/CLIENTS_SCHEDULED_VISIT_SEND_MESSAGE_LINK_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_VIEW_PROFILE_LINK_CLICKED =
  'containers/SearchClients/CLIENTS_SCHEDULED_VISIT_VIEW_PROFILE_LINK_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_NEXT_PAGE_CLICKED =
  'containers/SearchClients/CLIENTS_SCHEDULED_VISIT_NEXT_PAGE_CLICKED';
export const TTA_CLIENTS_SEARCH_TYPED =
  'containers/SearchClients/TTA_CLIENTS_SEARCH_TYPED';
export const TTA_CLIENTS_EXPAND_CARD_CLICKED =
  'containers/SearchClients/TTA_CLIENTS_EXPAND_CARD_CLICKED';
export const TTA_CLIENTS_SEND_MESSAGE_LINK_CLICKED =
  'containers/SearchClients/TTA_CLIENTS_SEND_MESSAGE_LINK_CLICKED';
export const TTA_CLIENTS_VIEW_PROFILE_LINK_CLICKED =
  'containers/SearchClients/TTA_CLIENTS_VIEW_PROFILE_LINK_CLICKED';
export const TTA_CLIENTS_NEXT_PAGE_CLICKED =
  'containers/SearchClients/TTA_CLIENTS_NEXT_PAGE_CLICKED';
export const CLIENTS_PROPOSAL_STAGE_SEARCH_TYPED =
  'containers/SearchClients/CLIENTS_PROPOSAL_STAGE_SEARCH_TYPED';
export const CLIENTS_PROPOSAL_STAGE_EXPAND_CARD_CLICKED =
  'containers/SearchClients/CLIENTS_PROPOSAL_STAGE_EXPAND_CARD_CLICKED';
export const CLIENTS_PROPOSAL_STAGE_SEND_MESSAGE_LINK_CLICKED =
  'containers/SearchClients/CLIENTS_PROPOSAL_STAGE_SEND_MESSAGE_LINK_CLICKED';
export const CLIENTS_PROPOSAL_STAGE_VIEW_PROFILE_LINK_CLICKED =
  'containers/SearchClients/CLIENTS_PROPOSAL_STAGE_VIEW_PROFILE_LINK_CLICKED';
export const CLIENTS_PROPOSAL_STAGE_NEXT_PAGE_CLICKED =
  'containers/SearchClients/CLIENTS_PROPOSAL_STAGE_NEXT_PAGE_CLICKED';
export const MY_CLIENTS_SEARCH_TYPED =
  'containers/SearchClients/MY_CLIENTS_SEARCH_TYPED';
export const MY_CLIENTS_EXPAND_CARD_CLICKED =
  'containers/SearchClients/MY_CLIENTS_EXPAND_CARD_CLICKED';
export const MY_CLIENTS_SEND_MESSAGE_LINK_CLICKED =
  'containers/SearchClients/MY_CLIENTS_SEND_MESSAGE_LINK_CLICKED';
export const MY_CLIENTS_VIEW_PROFILE_LINK_CLICKED =
  'containers/SearchClients/MY_CLIENTS_VIEW_PROFILE_LINK_CLICKED';
export const MY_CLIENTS_NEXT_PAGE_CLICKED =
  'containers/SearchClients/MY_CLIENTS_NEXT_PAGE_CLICKED';

export const PAGE_SIZE = 10;
export const ORDER_BY = Object.freeze({
  CLIENT_NAME: 'client-name',
});
export const BY_STATUS = Object.freeze({
  IN_PROGRESS_TTA: 'IN_PROGRESS',
  PENDING_TTA: 'PENDING',
  SCHEDULED_VISIT: 'Marcado',
  IN_NEGOTIATION_PROPOSAL: 'EmNegociacao',
});
export const BY_TYPE = Object.freeze({
  TTA: 'TTA',
  VISIT: 'VISIT',
  PROPOSAL: 'PROPOSAL',
});
export const ORIGIN = Object.freeze({
  MY_CLIENTS: 'menu-meus-clientes',
  ONLINE_SUPPORT: 'atendimento-online',
  VISIT_SCHEDULED: 'visitas-agendadas',
  IN_NEGOTIATION: 'em-negociacao',
});

export const UPDATE_ORIGIN = 'containers/SearchClients/UPDATE_ORIGIN';
