import { HTTP_METHODS } from 'config';
import { agentsApiConfig } from 'api/agents';
import {
  FETCH_KEY_TRACKING_BY_HOUSE_ID,
  RETURN_KEY,
  GET_KEY,
  KEY_TRACKING,
  SHOW_AGENT_KEYS,
} from './endpoints';

/**
 * Finish return-keys-form process
 */

export const returnKey = ({ payload }) =>
  agentsApiConfig({
    endpoint: RETURN_KEY,
    method: HTTP_METHODS.POST,
    payload,
  });

/**
 * Finish get-keys-form process
 */
export const getKey = ({ payload }) =>
  agentsApiConfig({
    endpoint: GET_KEY,
    method: HTTP_METHODS.POST,
    payload,
  });

/**
 * Get key tracking by house id
 */
export const fetchKeyTrackingByHouseId = ({ houseId }) =>
  agentsApiConfig({
    endpoint: FETCH_KEY_TRACKING_BY_HOUSE_ID,
    method: HTTP_METHODS.GET,
    queryString: `houseId=${houseId}`,
  });

/**
 * Get key tracking by logged user
 */
export const fetchAgentHousesList = () =>
  agentsApiConfig({
    endpoint: KEY_TRACKING,
    method: HTTP_METHODS.GET,
  });

/**
 * Get if agent keys area should be shown
 */
export const fetchShowAgentKeys = () =>
  agentsApiConfig({
    endpoint: SHOW_AGENT_KEYS,
    method: HTTP_METHODS.GET,
  });
