import {
  CLIENTS_SCHEDULED_VISIT_SEARCH_TYPED,
  CLIENTS_SCHEDULED_VISIT_EXPAND_CARD_CLICKED,
  CLIENTS_SCHEDULED_VISIT_SEND_MESSAGE_LINK_CLICKED,
  CLIENTS_SCHEDULED_VISIT_VIEW_PROFILE_LINK_CLICKED,
  CLIENTS_SCHEDULED_VISIT_NEXT_PAGE_CLICKED,
  TTA_CLIENTS_SEARCH_TYPED,
  TTA_CLIENTS_EXPAND_CARD_CLICKED,
  TTA_CLIENTS_SEND_MESSAGE_LINK_CLICKED,
  TTA_CLIENTS_VIEW_PROFILE_LINK_CLICKED,
  TTA_CLIENTS_NEXT_PAGE_CLICKED,
  CLIENTS_PROPOSAL_STAGE_SEARCH_TYPED,
  CLIENTS_PROPOSAL_STAGE_EXPAND_CARD_CLICKED,
  CLIENTS_PROPOSAL_STAGE_SEND_MESSAGE_LINK_CLICKED,
  CLIENTS_PROPOSAL_STAGE_VIEW_PROFILE_LINK_CLICKED,
  CLIENTS_PROPOSAL_STAGE_NEXT_PAGE_CLICKED,
  MY_CLIENTS_SEARCH_TYPED,
  MY_CLIENTS_EXPAND_CARD_CLICKED,
  MY_CLIENTS_SEND_MESSAGE_LINK_CLICKED,
  MY_CLIENTS_VIEW_PROFILE_LINK_CLICKED,
  MY_CLIENTS_NEXT_PAGE_CLICKED,
} from 'containers/SearchClients/constants';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleSearchClientsActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case CLIENTS_SCHEDULED_VISIT_SEARCH_TYPED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_search_typed',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_EXPAND_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_expand_card_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_SEND_MESSAGE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_send_message_link_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_VIEW_PROFILE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_view_profile_link_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_NEXT_PAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_next_page_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case TTA_CLIENTS_SEARCH_TYPED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_search_typed',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case TTA_CLIENTS_EXPAND_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_expand_card_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
      );
      break;
    case TTA_CLIENTS_SEND_MESSAGE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_send_message_link_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case TTA_CLIENTS_VIEW_PROFILE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_view_profile_link_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
      );
      break;
    case TTA_CLIENTS_NEXT_PAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_next_page_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case CLIENTS_PROPOSAL_STAGE_SEARCH_TYPED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_search_typed',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case CLIENTS_PROPOSAL_STAGE_EXPAND_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_expand_card_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
      );
      break;
    case CLIENTS_PROPOSAL_STAGE_SEND_MESSAGE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_send_message_link_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case CLIENTS_PROPOSAL_STAGE_VIEW_PROFILE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_view_profile_link_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
      );
      break;
    case CLIENTS_PROPOSAL_STAGE_NEXT_PAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_next_page_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case MY_CLIENTS_SEARCH_TYPED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'my_clients_search_typed',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case MY_CLIENTS_EXPAND_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'my_clients_expand_card_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
      );
      break;
    case MY_CLIENTS_SEND_MESSAGE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'my_clients_send_message_link_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    case MY_CLIENTS_VIEW_PROFILE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'my_clients_view_profile_link_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
      );
      break;
    case MY_CLIENTS_NEXT_PAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'my_clients_next_page_clicked',
        immutablePage(PAGE_NAMES.SEARCH_CLIENTS),
        action.extraProperties,
      );
      break;
    default:
      break;
  }
};

export default handleSearchClientsActions;
