import { FIREBASE_AUTH_STATUS } from 'api/firebase/constants';
import ROUTES from 'routes/constants';
import { ERROR_CODES } from '../../constants';
import {
  CONTRACT,
  OFFER,
  VISIT,
  DOCUMENTATION,
} from '../../enums/rentProgressTypes';
import {
  SEARCH_CONTRACT,
  SEARCH_OFFER,
  SEARCH_VISIT,
  SEARCH_DOCUMENTATION,
} from '../../enums/searchTypes';
import {
  visitHouseFetchSuccess,
  visitUserFetchSuccess,
} from '../DoneVisits/actions';
import {
  contractHouseFetchSuccess,
  contractUserFetchSuccess,
} from '../RentProgress/Contracts/actions';
import {
  documentationHouseFetchSuccess,
  documentationUserFetchSuccess,
} from '../RentProgress/Documentations/actions';
import {
  offerHouseFetchSuccess,
  offerUserFetchSuccess,
} from '../RentProgress/Offers/actions';
import {
  searchHouseFetchSuccess,
  searchUserFetchSuccess,
} from '../Search/actions';

/**
 * Checks if the error's cause was Firebase permission related
 * @param {object} error Error object
 */
export const hasPermissionsError = (error) =>
  error.code === ERROR_CODES.FIREBASE_PERMISSION;

export const mapSearchTypeToRentProgressType = (typedKey) => {
  const rentProgressMapper = {
    [SEARCH_CONTRACT]: CONTRACT,
    [SEARCH_OFFER]: OFFER,
    [SEARCH_VISIT]: VISIT,
    [SEARCH_DOCUMENTATION]: DOCUMENTATION,
  };

  if (!([typedKey] in rentProgressMapper)) {
    return typedKey;
  }

  return rentProgressMapper[typedKey];
};

export const parseFetchHouseQuery = (typedKey) => (result) => ({
  [typedKey]: result[typedKey],
  houseId: result.houseId,
});

export const parseFetchUserQuery = (typedKey) => (result) => {
  const userKey = typedKey === 'offerId' ? 'tenantId' : 'visitorId';
  return {
    [typedKey]: result[typedKey],
    tenantId: result[userKey],
  };
};

export const getSuccessHouseActionType = (rentProgressType) => {
  switch (rentProgressType) {
    case CONTRACT:
      return contractHouseFetchSuccess;
    case OFFER:
      return offerHouseFetchSuccess;
    case VISIT:
      return visitHouseFetchSuccess;
    case DOCUMENTATION:
      return documentationHouseFetchSuccess;
    case SEARCH_CONTRACT:
    case SEARCH_OFFER:
    case SEARCH_VISIT:
    case SEARCH_DOCUMENTATION:
      return (payload) =>
        searchHouseFetchSuccess(
          mapSearchTypeToRentProgressType(rentProgressType),
          payload,
        );
    default:
      throw new Error('Invalid type provided');
  }
};

export const getSuccessUserActionType = (rentProgressType) => {
  switch (rentProgressType) {
    case CONTRACT:
      return contractUserFetchSuccess;
    case OFFER:
      return offerUserFetchSuccess;
    case VISIT:
      return visitUserFetchSuccess;
    case DOCUMENTATION:
      return documentationUserFetchSuccess;
    case SEARCH_CONTRACT:
    case SEARCH_OFFER:
    case SEARCH_VISIT:
    case SEARCH_DOCUMENTATION:
      return (payload) =>
        searchUserFetchSuccess(
          mapSearchTypeToRentProgressType(rentProgressType),
          payload,
        );
    default:
      throw new Error('Invalid type provided');
  }
};

export const getIdFromType = (type) => {
  if (!type || !type.length) {
    throw new Error('Missing required type');
  }
  return `${type}Id`;
};

const isLoginRoute = ({ pathname }) => pathname.includes(ROUTES.LOGIN_PATH);

const isFirebaseLogged = ({ firebaseAuthStatus }) =>
  firebaseAuthStatus === FIREBASE_AUTH_STATUS.LOGGED;

export const isUserDataLoaded = (
  {
    agentData,
    checkedAgent,
    firebaseAuthStatus,
    isCheckedAgent,
    unauthorizedAgent,
  },
  user,
  location,
) => {
  const { pathname } = location;
  const { id: userId } = user;

  const isAppReady =
    isFirebaseLogged({ firebaseAuthStatus }) &&
    agentData &&
    isCheckedAgent &&
    checkedAgent;

  return (
    (isAppReady && userId !== 0) ||
    isLoginRoute({ pathname }) ||
    unauthorizedAgent
  );
};
