import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import tokens from '@quintoandar/cozy-theme/tokens/js';
import Divider from '@quintoandar/cozy-core/Divider';
import { MessageDescriptorPropType } from '@quintoandar/cozy-core/proptypes/Intl';
import { media } from '@quintoandar/cozy-core/media/breakpoints';
import Typography from '@quintoandar/cozy-core/Typography';
import List from '@quintoandar/cozy-core/List';
import Grid from '@quintoandar/cozy-core/Grid';
import Dimens from 'utils/dimens';

import { getBrandName } from 'utils/i18n/brand';

import {
  ABOUT_5A_URL,
  getAdvertiseLink,
  getIndicateLink,
  getBecomeAgentLink,
} from 'utils/externalLinks';
import CustomLink from '../CustomLink';

import Wrapper from '../CenteredGrid/CenteredWrapper';
import messages from './messages';

import TitleWrapper from './TitleWrapper';
import LinkItemWrapper from './LinkItemWrapper';
import DescriptionWrapper from './DescriptionWrapper';
import LinkHeaderWrapper from './LinkHeaderWrapper';
import Image from './Image';
import IllustrationWrapper from './IllustrationWrapper';

/**
 *
 * @name
 * ErrorPage
 *
 * @description
 * Default error page
 *
 * @module
 * Page
 *
 * @example
 * <ErrorPage
 *  image="https://www.quintoandar.com.br/f685019f953b1dde2e0b9475ce7a8e15.svg"
 *  header={{id: 'my.id', defaultMessage: 'Eita!'}}
 *  description={{id: 'my.id.sub', defaultMessage: 'Não encontramos a página que você está procurando. Erro 404'}}
 * />
 *
 * @param {*} [icon] centralized circled icon
 * @param {object} [header]
 * @param {object} [description]
 * @param {string} [image]
 */
const CustomWrapper = styled(Wrapper)`
  height: 100vh;
  ${media.smUp`
    display: flex;
    align-items: center;
  `}
`;

/* eslint-disable require-jsdoc */
const defaultLinks = () => (
  <List>
    <LinkItemWrapper button disableRipple>
      <CustomLink linkPath="/alugar/imovel" target="_self">
        <Typography kind="interactionSpecial" level="bodyStrong">
          <FormattedMessage {...messages.search} />
        </Typography>
      </CustomLink>
    </LinkItemWrapper>
    <LinkItemWrapper button disableRipple>
      <CustomLink linkPath={getAdvertiseLink()}>
        <Typography kind="interactionSpecial" level="bodyStrong">
          <FormattedMessage {...messages.announce} />
        </Typography>
      </CustomLink>
    </LinkItemWrapper>
    <LinkItemWrapper button disableRipple>
      <CustomLink linkPath={getIndicateLink()}>
        <Typography kind="interactionSpecial" level="bodyStrong">
          <FormattedMessage {...messages.indicate} />
        </Typography>
      </CustomLink>
    </LinkItemWrapper>
    <LinkItemWrapper button disableRipple>
      <CustomLink linkPath={getBecomeAgentLink()}>
        <Typography kind="interactionSpecial" level="bodyStrong">
          <FormattedMessage {...messages.beBroker} />
        </Typography>
      </CustomLink>
    </LinkItemWrapper>
    <LinkItemWrapper button disableRipple>
      <CustomLink
        linkPath={ABOUT_5A_URL}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Typography kind="interactionSpecial" level="bodyStrong">
          <FormattedMessage
            {...messages.aboutUs}
            values={{ companyName: getBrandName() }}
          />
        </Typography>
      </CustomLink>
    </LinkItemWrapper>
  </List>
);

export const ErrorPage = (props) => (
  <CustomWrapper>
    <Grid container justify="center">
      <Grid item lg={5} md={5} sm={5} xs={12}>
        <IllustrationWrapper>
          <Image src={props.image} />
        </IllustrationWrapper>
      </Grid>
      <Grid item lg={5} md={5} sm={5} xs={12}>
        <TitleWrapper>
          <Typography level="title">
            <FormattedMessage {...props.header} />
          </Typography>
        </TitleWrapper>
        <DescriptionWrapper marginBottom={Dimens.defaultSideSpacing}>
          <Typography level="body">
            <FormattedMessage {...props.description} />
          </Typography>
        </DescriptionWrapper>
        <Divider
          color={tokens.alias.color.surface.interaction.inactive.default}
        />
        <LinkHeaderWrapper>
          <Typography level="bodyStrong">
            <FormattedMessage {...messages.linksHeader} />
          </Typography>
        </LinkHeaderWrapper>
        {props.links}
      </Grid>
    </Grid>
  </CustomWrapper>
);
/* eslint-enable require-jsdoc */

ErrorPage.propTypes = {
  description: MessageDescriptorPropType,
  header: MessageDescriptorPropType,
  image: PropTypes.string,
  links: PropTypes.node,
};

ErrorPage.defaultProps = {
  links: defaultLinks(),
};

export default ErrorPage;
