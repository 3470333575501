import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.container.Forbidden.title',
    defaultMessage: 'Oops! Você não tem permissão para acessar essa página',
  },
  back: {
    id: 'app.container.Forbidden.back',
    defaultMessage: 'Voltar para página inicial',
  },
});
