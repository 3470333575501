import {
  ONGOING_NEGOTIATION_PAGE_VIEWED,
  ONGOING_NEGOTIATION_SORTING_LINK_CLICKED,
  ONGOING_NEGOTIATION_LATEST_PROPOSAL_CLICKED,
  ONGOING_NEGOTIATION_LESS_RECENT_PROPOSAL_CLICKED,
  ONGOING_NEGOTIATION_SORT_BY_LASTEST_CLIENTS_CLICKED,
  ONGOING_NEGOTIATION_SORT_BY_ALPHABETICAL_ORDER_CLICKED,
  ONGOING_NEGOTIATION_EXPAND_CARD_CLICKED,
  ONGOING_NEGOTIATION_SEND_MESSAGE_LINK_CLICKED,
  ONGOING_NEGOTIATION_VIEW_PROFILE_LINK_CLICKED,
  ONGOING_NEGOTIATION_NEXT_PAGE_CLICKED,
} from 'containers/OngoingNegotiation/constants';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleOngoingNegotiationActions = (
  eventDispatcherHandler,
  action,
) => {
  switch (action.type) {
    case ONGOING_NEGOTIATION_PAGE_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_page_viewed',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
      );
      break;
    case ONGOING_NEGOTIATION_SORTING_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_sorting_link_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
      );
      break;
    case ONGOING_NEGOTIATION_LATEST_PROPOSAL_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_sort_by_latest_proposals_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
      );
      break;
    case ONGOING_NEGOTIATION_LESS_RECENT_PROPOSAL_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_sort_by_less_recent_proposal_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
      );
      break;
    case ONGOING_NEGOTIATION_SORT_BY_LASTEST_CLIENTS_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_sort_by_latest_clients_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
      );
      break;
    case ONGOING_NEGOTIATION_SORT_BY_ALPHABETICAL_ORDER_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_sort_by_alphabetical_order_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
      );
      break;
    case ONGOING_NEGOTIATION_EXPAND_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_expand_card_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
      );
      break;
    case ONGOING_NEGOTIATION_SEND_MESSAGE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_send_message_link_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
        action.extraProperties,
      );
      break;
    case ONGOING_NEGOTIATION_VIEW_PROFILE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_view_profile_link_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
      );
      break;
    case ONGOING_NEGOTIATION_NEXT_PAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_next_page_clicked',
        immutablePage(PAGE_NAMES.ONGOING_NEGOTIATION),
        action.extraProperties,
      );
      break;
    default:
      break;
  }
};

export default handleOngoingNegotiationActions;
