import IncogniaWebSdk from '@incognia/web-sdk';
import { parseCookiesToUser } from '@quintoandar/wat-cookie';
import { getFeatureFlagEnabled } from './useFeatureFlag';

export const RISK_ASSESSMENT_ENABLED = 'incognia_integration_enabled';

export const init = async () => {
  const isFeatureEnabled = await getFeatureFlagEnabled(RISK_ASSESSMENT_ENABLED);

  if (!isFeatureEnabled) {
    return Promise.resolve();
  }

  return IncogniaWebSdk.init(process.env.INCOGNIA_APPLICATION_ID);
};

export const setAccountId = async () => {
  const isFeatureEnabled = await getFeatureFlagEnabled(RISK_ASSESSMENT_ENABLED);

  if (!isFeatureEnabled) {
    return Promise.resolve();
  }

  const user = parseCookiesToUser();

  if (user && user.personUUID) {
    return IncogniaWebSdk.setAccountId(user.personUUID);
  }

  return Promise.resolve();
};

export const clearAccountId = async () => {
  const isFeatureEnabled = await getFeatureFlagEnabled(RISK_ASSESSMENT_ENABLED);

  if (!isFeatureEnabled) {
    return Promise.resolve();
  }

  return IncogniaWebSdk.clearAccountId();
};

export default {
  init,
  setAccountId,
  clearAccountId,
};
