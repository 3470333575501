import React from 'react';
import { DOCUMENTATION_STATUS } from 'constants.js';
import { FormattedDate } from 'react-intl';
import visitsMessages from 'containers/DoneVisits/messages';
import contractMessages from 'containers/RentProgress/Contracts/messages';
import documentationMessages from 'containers/RentProgress/Documentations/messages';
import proposalMessages from 'containers/RentProgress/Offers/messages';
import { RENT_FLOW_LABEL, VISIT_LABEL } from 'enums/label';
import { NEGOTIATION_STATUS, STATUS_COLORS } from 'enums/negotiationStatus';
import BUSINESS_CONTEXTS from '../../enums/businessContexts';

const RENT_FLOW_LABEL_DATA = Object.freeze({
  [RENT_FLOW_LABEL.CONTRACT_CANCELLED]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: contractMessages.contractCancelled,
  },
  [RENT_FLOW_LABEL.DOCUMENTATIONS_IN_ANALYSIS]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING5A],
    message: proposalMessages.documentationsInAnalysis,
  },
  [RENT_FLOW_LABEL.DOCUMENTATIONS_REFUSED]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: proposalMessages.documentationsRefused,
  },
  [RENT_FLOW_LABEL.HOUSE_INACTIVE]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: proposalMessages.houseInactive,
  },
  [RENT_FLOW_LABEL.HOUSE_UNPUBLISHED]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: proposalMessages.houseUnpublished,
  },
  [RENT_FLOW_LABEL.OWNER_DOCUMENTATION_IN_ANALYSIS]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING5A],
    message: proposalMessages.ownerDocumentationInAnalysisChip,
  },
  [RENT_FLOW_LABEL.OWNER_DOCUMENTATION_IN_ANALYSIS_CHIP]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING5A],
    message: documentationMessages.ownerDocumentationInAnalysisChip,
  },
  [RENT_FLOW_LABEL.OWNER_REJECTED_OFFER]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: proposalMessages.ownerRejectedOffer,
  },
  [RENT_FLOW_LABEL.RENT_FLOW_CANCELLED]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: proposalMessages.proposalCancelled,
  },
  [RENT_FLOW_LABEL.STALLED_REJECTION]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: proposalMessages.stalledRejection,
  },
  [RENT_FLOW_LABEL.TENANT_DIDNT_SENT_OFFER]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: proposalMessages.tenantDidntSentOffer,
  },
  [RENT_FLOW_LABEL.TENANT_DOCUMENTATION_IN_ANALYSIS]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING5A],
    message: proposalMessages.tenantDocumentationInAnalysisChip,
  },
  [RENT_FLOW_LABEL.TENANT_DOCUMENTATION_IN_ANALYSIS_CHIP]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING5A],
    message: documentationMessages.tenantDocumentationInAnalysisChip,
  },
  [RENT_FLOW_LABEL.TENANT_REJECTED]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: documentationMessages.tenantRejected,
  },
  [RENT_FLOW_LABEL.TENANT_REJECTED_OFFER]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
    message: proposalMessages.tenantRejectedOffer,
  },
  [RENT_FLOW_LABEL.WAITING_CONTRACT_SIGNATURES]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: contractMessages.waitingContractSignatures,
  },
  [RENT_FLOW_LABEL.WAITING_DOCUMENTATIONS]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: documentationMessages.waitingDocumentations,
  },
  [RENT_FLOW_LABEL.WAITING_FOR_TENANT_TO_DECIDE]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: documentationMessages.waitingForTenantToDecide,
  },
  [RENT_FLOW_LABEL.WAITING_OWNER_DOCUMENTATION]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: documentationMessages.waitingOwnerDocumentation,
  },
  [RENT_FLOW_LABEL.WAITING_OWNER_RESEND_DOCUMENTATION]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: documentationMessages.waitingResendDocumentations,
  },
  [RENT_FLOW_LABEL.WAITING_OWNER_RESPOND_COUNTER_OFFER]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: proposalMessages.waitingOwnerRespondCounterOffer,
  },
  [RENT_FLOW_LABEL.WAITING_OWNER_RESPONSE]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: proposalMessages.waitingOwnerResponse,
  },
  [RENT_FLOW_LABEL.WAITING_OWNER_SIGN_CONTRACT]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: contractMessages.waitingOwnerSignContract,
  },
  [RENT_FLOW_LABEL.WAITING_RESEND_DOCUMENTATIONS]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: documentationMessages.waitingResendDocumentations,
  },
  [RENT_FLOW_LABEL.WAITING_TENANT_DOCUMENTATION]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: documentationMessages.waitingTenantDocumentation,
  },
  [RENT_FLOW_LABEL.WAITING_TENANT_RESEND_DOCUMENTATION]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: documentationMessages.waitingResendDocumentations,
  },
  [RENT_FLOW_LABEL.WAITING_TENANT_RESPOND_COUNTER_OFFER]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: proposalMessages.waitingTenantRespondCounterOffer,
  },
  [RENT_FLOW_LABEL.WAITING_TENANT_SIGN_CONTRACT]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: contractMessages.waitingTenantSignContract,
  },
  [RENT_FLOW_LABEL.WAITING_SIGNATURES_VALIDATION]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
    message: contractMessages.waitingSignaturesValidation,
  },
  [RENT_FLOW_LABEL.CONTRACT_SIGNED]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.COMPLETED],
    message: contractMessages.contractSigned,
  },
  [RENT_FLOW_LABEL.WAITING_CONTRACT]: {
    backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING5A],
    message: proposalMessages.waitingContract,
  },
});

const getSignDate = ({ rentProgressContract }) => {
  if (
    rentProgressContract.signedAt !== undefined &&
    rentProgressContract.signedAt !== null
  ) {
    return rentProgressContract.signedAt.toDate();
  }

  return rentProgressContract.contractChangedAt.toDate();
};

export const parseRentFlowLabel = (rentFlow) => {
  let label = RENT_FLOW_LABEL_DATA[rentFlow.label];

  if (rentFlow.label === RENT_FLOW_LABEL.CONTRACT_SIGNED) {
    const signDate = getSignDate({ rentProgressContract: rentFlow });

    label = {
      backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.COMPLETED],
      message: contractMessages.contractSignedAt,
      values: {
        date: <FormattedDate day="2-digit" month="2-digit" value={signDate} />,
      },
    };
  }

  if (
    rentFlow.label === RENT_FLOW_LABEL.WAITING_CONTRACT &&
    rentFlow.documentation &&
    rentFlow.documentation.status === DOCUMENTATION_STATUS.REFUSED
  ) {
    label = {
      backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
      message: proposalMessages.documentationsRefused,
    };
  }

  return label || null;
};

export const parseVisitLabel = ({
  entranceProblem,
  label,
  businessContext,
}) => {
  if (businessContext && businessContext === BUSINESS_CONTEXTS.FOR_SALE) {
    return null;
  }

  if (entranceProblem) {
    return {
      backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
      message: visitsMessages.entranceProblems,
    };
  }

  switch (label) {
    case VISIT_LABEL.PROPOSAL_NOT_SENT:
      return {
        backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.WAITING],
        message: visitsMessages.proposalNotSent,
      };
    case VISIT_LABEL.PROPOSAL_SENT:
      return {
        backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.COMPLETED],
        message: visitsMessages.proposalSent,
      };
    case VISIT_LABEL.ENTRANCE_PROBLEMS:
      return {
        backgroundColor: STATUS_COLORS[NEGOTIATION_STATUS.TERMINATED],
        message: visitsMessages.entranceProblems,
      };
    default:
      return null;
  }
};

export const parseTypeLabel = (document, followUp) => {
  const isFollowUpNegative = followUp?.visitNotHappen;
  if (isFollowUpNegative === true) {
    return null;
  }
  const { visitor, prospectTenant } = document;
  if (visitor && Object.keys(visitor).length > 0) {
    return {
      backgroundColor: 'positive',
      message: visitsMessages.typeVisit,
    };
  }

  if (prospectTenant && Object.keys(prospectTenant).length > 0) {
    return {
      backgroundColor: 'positive',
      message: visitsMessages.typeAgentSupport,
    };
  }

  return null;
};
