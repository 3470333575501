import {
  RENT_PROGRESS_STATUS_PAGE_VIEWED,
  RENT_PROGRESS_STATUS_FILTER_CLICKED,
  RENT_PROGRESS_STATUS_FILTER_SUBMITTED,
  RENT_PROGRESS_STATUS_CARD_OPENED,
  RENT_PROGRESS_CHAT_WITH_OWNER_CLICKED_ANALYTICS,
} from 'containers/RentProgress/constants';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleRentProgressActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case RENT_PROGRESS_STATUS_CARD_OPENED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'rent_progress_status_card_opened',
        immutablePage(PAGE_NAMES.RENT_PROGRESS),
        action.extraProperties,
      );
      break;
    case RENT_PROGRESS_STATUS_PAGE_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'rent_progress_status_page_viewed',
        immutablePage(PAGE_NAMES.RENT_PROGRESS),
        action.extraProperties,
      );
      break;
    case RENT_PROGRESS_STATUS_FILTER_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'rent_progress_status_filter_clicked',
        immutablePage(PAGE_NAMES.RENT_PROGRESS),
        action.extraProperties,
      );
      break;
    case RENT_PROGRESS_STATUS_FILTER_SUBMITTED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'rent_progress_status_filter_submitted',
        immutablePage(PAGE_NAMES.RENT_PROGRESS),
        action.extraProperties,
      );
      break;
    case RENT_PROGRESS_CHAT_WITH_OWNER_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'chatcrpp_access_chat_clicked',
        immutablePage(PAGE_NAMES.RENT_PROGRESS),
      );
      break;

    default:
      break;
  }
};

export default handleRentProgressActions;
