/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import {
  DEFAULT_LOCALE,
  defaultCurrencyFormat,
  DEFAULT_FORMATS,
} from '@quintoandar/js-intl';

const formats = defaultCurrencyFormat();

export class LanguageProvider extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    const { children, locale, messages } = this.props;

    return (
      <IntlProvider
        key={locale}
        defaultFormats={DEFAULT_FORMATS}
        defaultLocale={DEFAULT_LOCALE}
        formats={formats}
        locale={locale}
        messages={messages}
      >
        {React.Children.only(children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string,
  messages: PropTypes.object,
};

export default LanguageProvider;
