import React from 'react';
import { FormattedMessage } from 'react-intl';

import proposalMessages from 'containers/RentProgress/Offers/messages';
import REJECTION_REASON from 'enums/rejectionReason';
import { getBrandName } from 'utils/i18n/brand';

const parseRejectionReason = (rejectionReason) => {
  switch (rejectionReason) {
    case REJECTION_REASON.INACTIVE_HOUSE:
      return <FormattedMessage {...proposalMessages.houseInactive} />;
    case REJECTION_REASON.OWNER_DOESNT_AGREE_WITH_CONDITIONS:
      return (
        <FormattedMessage
          {...proposalMessages.ownerDoesntAgreeWithConditions}
        />
      );
    case REJECTION_REASON.OWNER_DOESNT_AGREE_WITH_VALUE:
      return (
        <FormattedMessage {...proposalMessages.ownerDoesntAgreeWithValue} />
      );
    case REJECTION_REASON.OWNER_GAVE_UP_RENTING:
      return <FormattedMessage {...proposalMessages.ownerGaveUpRenting} />;
    case REJECTION_REASON.OWNER_RENTED_FOR_ANOTHER_TENANT:
      return (
        <FormattedMessage {...proposalMessages.ownerRentedForAnotherTenant5A} />
      );
    case REJECTION_REASON.OWNER_RENTED_OUTSIDE:
      return (
        <FormattedMessage
          {...proposalMessages.ownerRentedForAnotherTenantOutside}
          values={{ companyName: getBrandName() }}
        />
      );
    case REJECTION_REASON.OWNER_SOLD_APARTMENT:
      return <FormattedMessage {...proposalMessages.ownerSoldApartment} />;
    case REJECTION_REASON.OWNER_STALLED:
      return <FormattedMessage {...proposalMessages.ownerStalled} />;
    case REJECTION_REASON.TENANT_DOESNT_AGREE_WITH_CONDITIONS:
      return (
        <FormattedMessage
          {...proposalMessages.tenantDoesntAgreeWithConditions}
        />
      );
    case REJECTION_REASON.TENANT_DOESNT_AGREE_WITH_VALUE:
      return (
        <FormattedMessage {...proposalMessages.tenantDoesntAgreeWithValue} />
      );
    case REJECTION_REASON.TENANT_GAVE_UP_APARTMENT:
      return <FormattedMessage {...proposalMessages.tenantGaveUpApartment} />;
    case REJECTION_REASON.TENANT_GAVE_UP_RENTING:
      return <FormattedMessage {...proposalMessages.tenantGaveUpRenting} />;
    case REJECTION_REASON.TENANT_RENTED_ANOTHER:
      return <FormattedMessage {...proposalMessages.tenantRentedAnother} />;
    case REJECTION_REASON.TENANT_RENTED_OUTSIDE:
      return (
        <FormattedMessage
          {...proposalMessages.tenantRentedAnotherOutside}
          values={{ companyName: getBrandName() }}
        />
      );
    case REJECTION_REASON.TENANT_STALLED:
      return <FormattedMessage {...proposalMessages.tenantStalled} />;
    case REJECTION_REASON.UNPUBLISHED_HOUSE:
      return <FormattedMessage {...proposalMessages.houseUnpublished} />;
    default:
      return (
        <FormattedMessage {...proposalMessages.tenantDoesntAgreeWithValue} />
      );
  }
};

export default parseRejectionReason;
