const clearCookie = (name, domain, path) => {
  document.cookie = `${name}=;expires=Thu,01-Jan-70 00:00:01 GMT;domain=${domain};path=${path}`;
};

export const clearListCookies = (domain) => {
  const cookies = document.cookie.split('; ');
  cookies.forEach((cookie) => {
    clearCookie(cookie.split('=')[0], domain, '/');
  });
};
