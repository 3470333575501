export const FIREBASE_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: `${process.env.FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: `${process.env.FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: `${process.env.FIREBASE_MESSAGING_SENDER_ID}`,
};

/**
 * Ordering directions for Firestore queries
 * @type {{ASCENDING: string, DESCENDING: string}}
 */
export const ORDER_BY_DIRECTION = Object.freeze({
  ASCENDING: 'asc',
  DESCENDING: 'desc',
});

/**
 * Where operators for Firestore queries
 * @type {{LT: string, LTE: string, EQ: string, GTE: string, GT: string}}
 */
export const WHERE_OPS = Object.freeze({
  LT: '<',
  LTE: '<=',
  EQ: '==',
  GTE: '>=',
  GT: '>',
});

/**
 * Fields used in visits
 * @type {{DATE: string}}
 */
export const VISIT_FIELDS = Object.freeze({
  DAY: 'day',
  TIME: 'time',
  IS_POSITIVE_FUP: 'isPositiveFup',
  LABEL: 'label',
  VISIT_LABEL: 'label',
});

/**
 * Fields used in contracts
 * @type {{CHANGED_AT: date}}
 */
export const CONTRACT_FIELDS = Object.freeze({
  CHANGED_AT: 'contractChangedAt',
  CONTRACT_STATUS: 'status',
});

/**
 * Fields used in documentation
 * @type {{DOCUMENTATION_CHANGED_AT: string, DOCUMENTATION_STATUS: 'status', DOCUMENTATION_ACTIVE: 'active'}}
 */
export const DOCUMENTATION_FIELDS = Object.freeze({
  DOCUMENTATION_CHANGED_AT: 'documentationChangedAt',
  DOCUMENTATION_STATUS: 'status',
  DOCUMENTATION_ACTIVE: 'active',
});

/**
 * Fields used in rent flows
 * @type {{OFFER_CHANGED_AT: string, STATUS: string, ACTIVE: string }}
 */
export const RENT_FLOW_FIELDS = Object.freeze({
  OFFER_CHANGED_AT: 'offerChangedAt',
  STATUS: 'status',
  ACTIVE: 'active',
});

export const FIREBASE_AUTH_STATUS = Object.freeze({
  NOT_VERIFIED: 'NOT_VERIFIED',
  LOGGED: 'LOGGED',
  LOGGED_ANONYMOUSLY: 'LOGGED_ANONYMOUSLY',
  NOT_LOGGED: 'NOT_LOGGED',
});
