import { replace } from 'react-router-redux';
import { put, call } from 'redux-saga/effects';

import logout from 'utils/logout';

import { containsLoginPath, containsRedirectingPath } from './redirect';
import ROUTES from '../routes/constants';

export const goToLogin = () => {
  let redirectPath = window.location.pathname + window.location.search;

  if (!containsLoginPath(redirectPath)) {
    redirectPath = `/auth/login?redirect=${redirectPath}`;
  }

  window.location.assign(redirectPath);
};

export function* redirectToLogin() {
  let redirectPath = window.location.pathname + window.location.search;

  if (
    !containsLoginPath(redirectPath) &&
    !containsRedirectingPath(redirectPath)
  ) {
    if (redirectPath.endsWith('/')) {
      redirectPath = '/auth/login';
    } else {
      redirectPath = `/auth/login?redirect=${redirectPath}`;
    }
  } else if (
    redirectPath.endsWith(ROUTES.FORBIDDEN_PATH) ||
    redirectPath.endsWith('/')
  ) {
    redirectPath = '/auth/login';
  }

  yield put(replace(redirectPath));
}

export function* logoutAndRedirect() {
  yield put(logout());
  yield* redirectToLogin();
}

export function* logoutAndRedirectToExternalPath(
  path = ROUTES.AGENTS_MKT_PATH,
) {
  yield call(logout());
  window.location.assign(path);
}

export function* redirectToForbidden() {
  yield put(replace(ROUTES.FORBIDDEN_PATH));
}
