import { getUnreadChatMessages } from 'api/agents/chat';
import { fetchShowAgentKeys as fetchShowAgentKeysApi } from 'api/agents/key';
import {
  HIDE_SNACKBAR,
  SHOW_SNACKBAR,
  OFFER_LINK_FAILURE,
  OFFER_LINK_REQUEST,
  OFFER_LINK_SUCCESS,
  HIDE_LOADING,
  SHOW_LOADING,
  HIDE_DIALOG,
  SHOW_DIALOG,
  CHECKED_AGENT,
  UNAUTHORIZED_AGENT,
  FETCH_AGENT_DATA_SUCCESS,
  FETCH_AGENT_DATA_FAILURE,
  UPDATE_DIALOG_STATUS,
  HIDE_ERROR_DIALOG,
  SHOW_ERROR_DIALOG,
  ASK_FOR_PUSH,
  FETCH_AGENT_RATING,
  FETCH_SHOW_AGENT_KEYS,
  FETCH_HAS_UNREAD_MESSAGES,
} from 'containers/App/constants';

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});

export const showSnackbar = ({ timeout, message, actionTitle, color }) => ({
  type: SHOW_SNACKBAR,
  payload: {
    color,
    timeout,
    message,
    actionTitle,
  },
});

export const hideLoading = () => ({
  type: HIDE_LOADING,
});

export const showLoading = () => ({
  type: SHOW_LOADING,
});

export const hideDialog = () => ({
  type: HIDE_DIALOG,
});

export const showDialog = ({ tab, screen, businessContext }) => ({
  type: SHOW_DIALOG,
  payload: {
    tab,
    screen,
    businessContext,
  },
});

export const hideErrorDialog = () => ({
  type: HIDE_ERROR_DIALOG,
});

export const showErrorDialog = ({ title, body }) => ({
  type: SHOW_ERROR_DIALOG,
  payload: {
    title,
    body,
  },
});

export const sendOfferLinkRequest = (visitorId, houseId) => ({
  type: OFFER_LINK_REQUEST,
  payload: {
    visitorId,
    houseId,
  },
});

export const offerLinkFailure = (message) => ({
  type: OFFER_LINK_FAILURE,
  payload: {
    message,
  },
});

export const offerLinkSuccess = () => ({
  type: OFFER_LINK_SUCCESS,
});

export const checkedAgent = (isActive) => ({
  type: CHECKED_AGENT,
  payload: {
    isActive,
  },
});

export const unauthorizedAgent = () => ({
  type: UNAUTHORIZED_AGENT,
});

export const fetchAgentDataSuccess = ({ agentData, ...rest }) => ({
  type: FETCH_AGENT_DATA_SUCCESS,
  payload: {
    agentData,
    ...rest,
  },
});

export const fetchAgentDataFailure = (message) => ({
  type: FETCH_AGENT_DATA_FAILURE,
  payload: {
    message,
  },
});

export const updateDialogStatus = ({ entered }) => ({
  type: UPDATE_DIALOG_STATUS,
  payload: {
    entered,
  },
});

export const askForPush = () => ({
  type: ASK_FOR_PUSH,
});

export const fetchAgentRating = (businessContext) => ({
  type: FETCH_AGENT_RATING,
  payload: {
    businessContext,
  },
});

export const fetchHasUnreadMessages = () => ({
  type: FETCH_HAS_UNREAD_MESSAGES,
  promise: getUnreadChatMessages(),
});

export const fetchShowAgentKeys = () => ({
  type: FETCH_SHOW_AGENT_KEYS,
  promise: fetchShowAgentKeysApi(),
});
