/**
 * Create the store with dynamic reducers
 */

import Immutable, { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import { createReducer } from 'reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import createSagaMiddleware from 'redux-saga';

import { createReduxEnhancer } from '@quintoandar/fwp-error-monitoring';

import thunk from 'redux-thunk';
import eventTracker from 'middlewares/event-tracker';

import getUnreadMessagesMiddleware from 'middlewares/getUnreadMessages';
import refreshProfileMiddleware from 'middlewares/refreshProfile';
import intercomMiddleware from 'middlewares/intercom';
import unauthorizedRedirector from 'middlewares/unauthorizedRedirector';
import featureFlagMiddleware from 'middlewares/getRelistingFeatureFlag';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    thunk,
    reduxPackMiddleware,
    sagaMiddleware,
    unauthorizedRedirector,
    featureFlagMiddleware,
    routerMiddleware(history),
    intercomMiddleware,
    refreshProfileMiddleware,
    eventTracker,
    getUnreadMessagesMiddleware,
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* istanbul ignore next */
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.ENABLE_REDUX_DEVTOOLS === 'true' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          serialize: {
            immutable: Immutable,
          },
        })
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(applyMiddleware(...middlewares), createReduxEnhancer()),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  store.asyncFirestoreListeners = {}; // Async firestore listeners registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
