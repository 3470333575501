import { handle } from 'redux-pack';
import { ACTION_TYPES, BLOCK_TYPES } from 'containers/TimeBlock/constants';
import {
  TIME_BLOCK_PAGE_VIEWED,
  OPEN_ADD_TIME_BLOCK_DIALOG,
  CONFIRM_FIXED_BLOCK,
  DELETE_FIXED_BLOCK,
  EDIT_FIXED_BLOCK,
  OPEN_EDIT_FIXED_BLOCK_DIALOG,
  OPEN_DELETE_CONFIRM_DIALOG,
  DELETE_TEMPORARY_BLOCK,
  CONFIRM_TEMPORARY_BLOCK,
} from 'containers/TimeBlock/types';
import { CREATE_TIME_BLOCK_REQUEST } from 'containers/CalendarHoursBlock/CreateTimeBlockDialog/types';
import {
  TIME_BLOCK_PAGE_INFO_CLICKED,
  TIME_BLOCK_TAB_CHANGED,
  TIME_BLOCK_DELETE_CLICKED,
  TIME_BLOCK_NEW_BLOCK_CLICKED,
} from 'containers/CalendarHoursBlock/types';
import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleTimeBlockAction = (
  eventDispatcherHandler,
  action,
  state,
) => {
  switch (action.type) {
    case TIME_BLOCK_PAGE_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'time_block_page_viewed',
        immutablePage(PAGE_NAMES.TIME_BLOCK),
        action.extraProperties,
      );
      break;
    case CREATE_TIME_BLOCK_REQUEST:
      eventDispatcherHandler.logWithDefaultEventProps(
        CREATE_TIME_BLOCK_REQUEST,
        immutablePage(PAGE_NAMES.TIME_BLOCK),
        action.extraProperties,
      );
      break;
    case TIME_BLOCK_DELETE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'time_block_delete_clicked',
        immutablePage(PAGE_NAMES.TIME_BLOCK),
        action.extraProperties,
      );
      break;
    case TIME_BLOCK_NEW_BLOCK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        TIME_BLOCK_NEW_BLOCK_CLICKED,
        immutablePage(PAGE_NAMES.TIME_BLOCK),
      );
      break;
    case OPEN_ADD_TIME_BLOCK_DIALOG:
      eventDispatcherHandler.logWithDefaultEventProps(
        'time_block_add_clicked',
        immutablePage(PAGE_NAMES.TIME_BLOCK),
      );
      break;
    case DELETE_FIXED_BLOCK:
    case EDIT_FIXED_BLOCK:
    case CONFIRM_FIXED_BLOCK:
    case DELETE_TEMPORARY_BLOCK:
    case CONFIRM_TEMPORARY_BLOCK:
      handle(state, action, {
        success: (prevState) => {
          eventDispatcherHandler.logWithDefaultEventProps(
            'time_block_confirmed',
            immutablePage(PAGE_NAMES.TIME_BLOCK),
            action.meta.extraProperties,
          );
          return prevState;
        },
      });
      break;
    case OPEN_EDIT_FIXED_BLOCK_DIALOG:
      eventDispatcherHandler.logWithDefaultEventProps(
        'time_block_action_clicked',
        immutablePage(PAGE_NAMES.TIME_BLOCK),
        {
          action: ACTION_TYPES.EDIT,
          type: BLOCK_TYPES.FIXED,
        },
      );
      break;
    case OPEN_DELETE_CONFIRM_DIALOG:
      eventDispatcherHandler.logWithDefaultEventProps(
        'time_block_action_clicked',
        immutablePage(PAGE_NAMES.TIME_BLOCK),
        {
          action: ACTION_TYPES.DELETE,
          type: action.payload.blockType,
        },
      );
      break;
    case TIME_BLOCK_TAB_CHANGED:
      eventDispatcherHandler.logWithDefaultEventProps(
        TIME_BLOCK_TAB_CHANGED,
        immutablePage(PAGE_NAMES.TIME_BLOCK),
      );
      break;
    case TIME_BLOCK_PAGE_INFO_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        TIME_BLOCK_PAGE_INFO_CLICKED,
        immutablePage(PAGE_NAMES.TIME_BLOCK),
      );
      break;
    default:
      break;
  }
};

export default handleTimeBlockAction;
