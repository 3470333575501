export const FETCH_ONGOING_NEGOTIATION =
  'app/containers/OngoingNegotiation/FETCH_ONGOING_NEGOTIATION';
export const FETCH_ONGOING_NEGOTIATION_ORDER_BY =
  'app/containers/OngoingNegotiation/FETCH_ONGOING_NEGOTIATION_ORDER_BY';

export const ONGOING_NEGOTIATION_ORDER_BY_QUERY = Object.freeze({
  LATEST_PROPOSAL: 'latest-proposal',
  NEW_CLIENTS: 'new-clients',
  NAME: 'client-name',
  OLDEST_PROPOSAL: 'older-proposal',
});

export const ONGOING_NEGOTIATION_PAGE_VIEWED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_PAGE_VIEWED';
export const ONGOING_NEGOTIATION_SORTING_LINK_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_SORTING_LINK_CLICKED';
export const ONGOING_NEGOTIATION_LATEST_PROPOSAL_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_LATEST_PROPOSAL_CLICKED';
export const ONGOING_NEGOTIATION_LESS_RECENT_PROPOSAL_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_LESS_RECENT_PROPOSAL_CLICKED';
export const ONGOING_NEGOTIATION_SORT_BY_LASTEST_CLIENTS_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_SORT_BY_LASTEST_CLIENTS_CLICKED';
export const ONGOING_NEGOTIATION_SORT_BY_ALPHABETICAL_ORDER_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_SORT_BY_ALPHABETICAL_ORDER_CLICKED';
export const ONGOING_NEGOTIATION_EXPAND_CARD_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_EXPAND_CARD_CLICKED';
export const ONGOING_NEGOTIATION_SEND_MESSAGE_LINK_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_SEND_MESSAGE_LINK_CLICKED';
export const ONGOING_NEGOTIATION_VIEW_PROFILE_LINK_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_VIEW_PROFILE_LINK_CLICKED';
export const ONGOING_NEGOTIATION_NEXT_PAGE_CLICKED =
  'app/containers/OngoingNegotiation/ONGOING_NEGOTIATION_NEXT_PAGE_CLICKED';

export const ONGOING_NEGOTIATION_BY_TYPE_QUERY = 'PROPOSAL';

export const ONGOING_NEGOTIATION_PER_PAGE_QUERY = 10;

export const ONGOING_NEGOTIATION_STATUS_QUERY = 'EmNegociacao';
