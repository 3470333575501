export const CLIENTS_SCHEDULED_VISIT_PAGE_VIEWED =
  'CLIENTS_SCHEDULED_VISIT_PAGE_VIEWED';
export const CLIENTS_SCHEDULED_VISIT_SEARCH_TYPED =
  'CLIENTS_SCHEDULED_VISIT_SEARCH_TYPED';
export const CLIENTS_SCHEDULED_VISIT_SORTING_LINK_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_SORTING_LINK_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_SORT_BY_NEXT_VISITS_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_SORT_BY_NEXT_VISITS_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_SORT_BY_LATEST_CLIENTS_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_SORT_BY_LATEST_CLIENTS_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_SORT_BY_ALPHABETICAL_ORDER_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_SORT_BY_ALPHABETICAL_ORDER_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_EXPAND_CARD_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_EXPAND_CARD_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_SEND_MESSAGE_LINK_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_SEND_MESSAGE_LINK_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_VIEW_PROFILE_LINK_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_VIEW_PROFILE_LINK_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_NEXT_PAGE_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_NEXT_PAGE_CLICKED';
export const CLIENTS_SCHEDULED_VISIT_PREVIOUS_PAGE_CLICKED =
  'CLIENTS_SCHEDULED_VISIT_PREVIOUS_PAGE_CLICKED';
export const FETCH_SCHEDULED_VISITS = 'FETCH_SCHEDULED_VISITS';
export const FETCH_SCHEDULED_VISITS_ORDERED_BY =
  'FETCH_SCHEDULED_VISITS_ORDERED_BY';

export const SCHEDULED_VISITS_BY_TYPE_QUERY = 'VISIT';

export const SCHEDULED_VISITS_ORDER_BY_QUERY = Object.freeze({
  CLOSER_VISITS: 'closer-visits',
  NEW_CLIENTS: 'new-clients',
  NAME: 'client-name',
});

export const SCHEDULED_VISITS_PER_PAGE_QUERY = 10;

export const SCHEDULED_VISITS_STATUS_QUERY = 'Marcado';
