import { OWNER_CONTACT_TRACKING_EVENTS } from 'Owner/OwnerContact/constants';
import {
  OWNER_CONTACT_CLOSE_CLICKED_ANALYTICS,
  OWNER_CONTACT_OPEN_CLICKED_ANALYTICS,
} from 'utils/entryInfo/constants';
import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleOwnerContactActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case OWNER_CONTACT_CLOSE_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        OWNER_CONTACT_TRACKING_EVENTS.CLOSE_CLICKED,
        immutablePage(PAGE_NAMES.ENTRY_INFO),
        action.extraProperties,
      );
      break;
    case OWNER_CONTACT_OPEN_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        OWNER_CONTACT_TRACKING_EVENTS.OPEN_CLICKED,
        immutablePage(PAGE_NAMES.ENTRY_INFO),
        action.extraProperties,
      );
      break;
    default:
      break;
  }
};

export default handleOwnerContactActions;
