import {
  CLIENTS_SCHEDULED_VISIT_PAGE_VIEWED,
  CLIENTS_SCHEDULED_VISIT_SEARCH_TYPED,
  CLIENTS_SCHEDULED_VISIT_SORTING_LINK_CLICKED,
  CLIENTS_SCHEDULED_VISIT_SORT_BY_NEXT_VISITS_CLICKED,
  CLIENTS_SCHEDULED_VISIT_SORT_BY_LATEST_CLIENTS_CLICKED,
  CLIENTS_SCHEDULED_VISIT_SORT_BY_ALPHABETICAL_ORDER_CLICKED,
  CLIENTS_SCHEDULED_VISIT_EXPAND_CARD_CLICKED,
  CLIENTS_SCHEDULED_VISIT_SEND_MESSAGE_LINK_CLICKED,
  CLIENTS_SCHEDULED_VISIT_VIEW_PROFILE_LINK_CLICKED,
  CLIENTS_SCHEDULED_VISIT_NEXT_PAGE_CLICKED,
  CLIENTS_SCHEDULED_VISIT_PREVIOUS_PAGE_CLICKED,
} from 'containers/VisitScheduled/constants';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleVisitScheduledActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case CLIENTS_SCHEDULED_VISIT_PAGE_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_page_viewed',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_SEARCH_TYPED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_search_typed',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
        action.extraProperties,
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_SORTING_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_sorting_link_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_SORT_BY_NEXT_VISITS_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_sort_by_next_visits_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_SORT_BY_LATEST_CLIENTS_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_sort_by_latest_clients_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_SORT_BY_ALPHABETICAL_ORDER_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_sort_by_alphabetical_order_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_EXPAND_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_expand_card_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_SEND_MESSAGE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_send_message_link_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
        action.extraProperties,
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_VIEW_PROFILE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_view_profile_link_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_NEXT_PAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_next_page_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
        action.extraProperties,
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_PREVIOUS_PAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_previous_page_clicked',
        immutablePage(PAGE_NAMES.VISIT_SCHEDULED),
        action.extraProperties,
      );
      break;
    default:
      break;
  }
};

export default handleVisitScheduledActions;
