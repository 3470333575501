import agentsApiConfig from 'api/agents';
import { HTTP_METHODS } from 'config';

export const fetchRentFlowsRequest = ({
  step,
  startDate,
  endDate,
  sortedBy,
  currentPage,
  pageSize,
}) =>
  agentsApiConfig({
    endpoint:
      `rent-flows?step=${step}&startDate=${startDate}&endDate=${endDate}` +
      `&sortedBy=${sortedBy}&currentPage=${currentPage}&pageSize=${pageSize}`,
    method: HTTP_METHODS.GET,
  });
