/**
 *
 * Asynchronously loads the component for VisitDetailWrapper
 *
 */
import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import(/* webpackChunkName: "VisitDetailWrapper" */ './index'),
  loading: () => null,
});
