import { LISTING_SOURCE } from 'enums/listingSource';

/**
 * Check if the house is third party
 */
export const isThirdParty = (houseDetails) => {
  const { saleDetails, rentDetails } = houseDetails || {};

  return (
    rentDetails?.listingSource === LISTING_SOURCE.THIRD_PARTY ||
    saleDetails?.listingSource === LISTING_SOURCE.THIRD_PARTY
  );
};
