import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CozyButton from '@quintoandar/cozy-core/Button';
import ModalDialog from '@quintoandar/cozy-core/Dialog/ModalDialog';
import DialogActions from '@quintoandar/cozy-core/DialogActions';
import DialogContent from '@quintoandar/cozy-core/DialogContent';
import DialogContentText from '@quintoandar/cozy-core/DialogContentText';
import Typography from '@quintoandar/cozy-core/Typography';
import { MessageDescriptorPropType } from '@quintoandar/cozy-core/proptypes/Intl';

import messages from './messages';

export const MessageDialog = ({
  buttonLabel,
  content,
  isOpen,
  onClose,
  title,
  icon,
}) => {
  const titleMessage = title.defaultMessage ? (
    <FormattedMessage {...title} />
  ) : (
    title
  );
  const contentMessage = content.defaultMessage ? (
    <FormattedMessage {...content} />
  ) : (
    content
  );
  const buttonLabelFormatted = buttonLabel.defaultMessage ? (
    <FormattedMessage {...buttonLabel} />
  ) : (
    buttonLabel
  );

  return (
    <ModalDialog
      fullWidth
      icon={icon}
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      title={titleMessage}
    >
      <DialogContent>
        <DialogContentText>
          <Typography kind="lighter">{contentMessage}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CozyButton fullWidth onClick={onClose} size="small">
          {buttonLabelFormatted}
        </CozyButton>
      </DialogActions>
    </ModalDialog>
  );
};

MessageDialog.propTypes = {
  buttonLabel: PropTypes.oneOfType([
    MessageDescriptorPropType,
    PropTypes.string,
  ]),
  content: PropTypes.oneOfType([MessageDescriptorPropType, PropTypes.string]),
  icon: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([MessageDescriptorPropType, PropTypes.string]),
};

MessageDialog.defaultProps = {
  buttonLabel: messages.defaultButtonLabel,
  content: messages.defaultBody,
  title: messages.defaultTitle,
};

export default MessageDialog;
