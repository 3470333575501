import { HTTP_METHODS } from 'config';
import agentsApiConfig from 'api/agents';
import { FIREBASE_AUTHENTICATE } from 'api/agents/endpoints';

/**
 * Authenticates with Firebase in Gandalf
 */
export const authenticate = () =>
  agentsApiConfig({
    endpoint: FIREBASE_AUTHENTICATE,
    method: HTTP_METHODS.POST,
  });
