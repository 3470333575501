import { createSelector } from 'reselect';
import BUSINESS_CONTEXTS from 'enums/businessContexts';
import { RENTAL_ADMINISTRATOR_TO_GUARANTEE_TYPE_MAP } from 'enums/rentalGuarantee';
import { filterVisit, isManagedByExternalAdministrator } from '../utils';

import { SPECIAL_CONDITION_TYPE } from './HouseInfo/constants';

export const selectVisitDetails = (state) => state.get('visitDetails');
export const selectUserInterfaceOptions = (state) =>
  selectVisitDetails(state).get('userInterfaceOptions');
export const selectVisit = (state) => selectVisitDetails(state).get('visit');
export const selectSuggestions = (state) =>
  selectVisitDetails(state).get('suggestions');
export const selectHouse = (state) => selectVisit(state).get('house');
export const selectLoginDomain = (state) => state.get('login');
export const selectSpecialConditions = (state) =>
  selectHouse(state).get('specialConditions');
export const selectCategories = (state) => selectHouse(state).get('categories');
export const selectRentProgressCount = (state) =>
  selectVisit(state).get('rentProgressCount');
export const selectOfferCount = (state) =>
  selectRentProgressCount(state).get('offers');
export const selectDocumentationCount = (state) =>
  selectRentProgressCount(state).get('documentations');
export const selectVisitCount = (state) =>
  selectRentProgressCount(state).get('visits');
export const selectEntryInfoHouseId = (state) =>
  selectSuggestions(state).get('entryInfoHouseId');
export const selectIsLoadingEntryInfo = (state) =>
  selectSuggestions(state).get('isLoadingEntryInfo');

export const selectSaleHousePerformanceData = (state) =>
  selectVisit(state).get('saleHousePerformanceData');

export const makeSelectHouseIsLoading = () =>
  createSelector(selectHouse, (house) => house.get('isLoading'));

export const makeSelectHouseListings = () =>
  createSelector(selectHouse, (house) => house.get('listings')?.toJS());

export const makeSelectHouseDetailByKey = (key) =>
  createSelector(selectHouse, (house) => house.get(key));

export const makeSelectHouseSuggestions = () =>
  createSelector(selectSuggestions, (suggestions) =>
    suggestions.get('houses').toJS(),
  );

export const makeSelectAdressInfo = () =>
  createSelector(selectHouse, (house) => ({
    houseType: house.get('type'),
    address: house.getIn(['address', 'address']),
    city: house.getIn(['address', 'city']),
    neighborhood: house.getIn(['address', 'neighborhood']),
    number: house.getIn(['address', 'number']),
    complement: house.get('complement'),
  }));

export const makeSelectHouse = () =>
  createSelector(selectHouse, (house) => house.toJS());

export const makeSelectVisitStatus = () =>
  createSelector(selectVisit, (visit) => visit.get('status'));

export const makeSelectVisitBusinessContext = () =>
  createSelector(selectVisit, (visit) => visit.get('businessContext'));

export const makeSelectIsPool = () =>
  createSelector(selectVisit, (visit) => visit.get('isPool'));

export const makeSelectHasError = () =>
  createSelector(selectUserInterfaceOptions, (userInterfaceOptions) =>
    userInterfaceOptions.get('hasError'),
  );

export const makeSelectVisitors = () =>
  createSelector(selectVisit, (visit) => visit.get('visitors').toJS());

export const makeSelectEndTime = () =>
  createSelector(selectVisit, (visit) => visit.get('endTime'));

export const makeSelectStartTime = () =>
  createSelector(selectVisit, (visit) => visit.get('startTime'));

export const makeSelectVisitDate = () =>
  createSelector(selectVisit, (visit) => visit.get('visitDate'));

export const makeSelectVisit = () =>
  createSelector(selectVisitDetails, (visitDetails) => {
    const visit = visitDetails.get('visit');
    const filteredVisit = filterVisit(visit);
    return filteredVisit.toJS();
  });

export const makeSelectHousePriceInfo = () =>
  createSelector(selectHouse, (houseInfo) => ({
    rentPrice: houseInfo.get('rentValue'),
    condoType: houseInfo.get('condominiumType'),
    condoPrice: houseInfo.get('condominiumValue'),
    iptuType: houseInfo.get('iptuType'),
    iptu: houseInfo.get('iptuValue'),
    homeProtection: houseInfo.get('homeownersInsuranceValue'),
    tenantServiceFee: houseInfo.get('tenantServiceFee'),
    totalCost: houseInfo.get('totalCost'),
  }));

export const makeSelectHouseCountryCode = () =>
  createSelector(selectHouse, (houseInfo) =>
    houseInfo.getIn(['address', 'countryCode']),
  );

export const makeSelectHouseOwner = () =>
  createSelector(selectHouse, (house) => ({
    id: house.getIn(['owner', 'id']),
    name: house.getIn(['owner', 'name']),
    cellphone: house.getIn(['owner', 'phoneNumber']),
  }));

export const makeSelectHouseCiq = () =>
  createSelector(selectHouse, (house) => ({
    id: house.getIn(['ciq', 'id']),
    name: house.getIn(['ciq', 'name']),
    phone: house.getIn(['ciq', 'phoneNumber']),
    acceptsWhatsApp: house.getIn(['ciq', 'acceptsWhatsApp']),
  }));

export const makeSelectIsExclusive = () =>
  createSelector(selectSpecialConditions, (specialConditions) =>
    specialConditions
      .map((type) => type.get('specialConditionType'))
      .includes(SPECIAL_CONDITION_TYPE.EXCLUSIVE),
  );

export const makeSelectCategory = () =>
  createSelector(selectCategories, (categories) =>
    categories?.getIn([0, 'name']),
  );

export const makeSelectAmenitiesInfo = () =>
  createSelector(selectHouse, (houseInfo) => ({
    acceptsPets: houseInfo.get('acceptPets'),
    bathrooms: houseInfo.get('bathrooms'),
    bedrooms: houseInfo.get('bedrooms'),
    floor: houseInfo.get('floor'),
    hasFurniture: houseInfo.get('isFurnished'),
    isNearSubway: houseInfo.get('nearSubway'),
    parkingSpaces: houseInfo.get('parkingSlots'),
    suites: houseInfo.get('suites'),
    area: houseInfo.get('totalArea'),
  }));

export const makeSelectHouseId = () =>
  createSelector(selectHouse, (houseInfo) => houseInfo.get('id'));

export const makeSelectVisitReservation = () =>
  createSelector(selectVisit, (visit) => visit.get('reservation').toJS());

export const makeSelectHouseOccupancy = () =>
  createSelector(selectHouse, (house) => house.get('occupancy'));

export const makeSelectSaleHousePerformanceData = () =>
  createSelector(selectSaleHousePerformanceData, (saleHousePerformanceData) =>
    saleHousePerformanceData.toJS(),
  );

export const makeIsManagedByOwner = () =>
  createSelector(selectVisit, (visit) =>
    isManagedByExternalAdministrator(visit.toJS()),
  );

export const makeIsOwnedByThirdParty = () =>
  createSelector(selectHouse, (house) => house.get('isOwnedByThirdParty'));

export const makeSelectOwnership = () =>
  createSelector(
    selectHouse,
    makeSelectVisitBusinessContext(),
    (houseMap, businessContext) => {
      const house = houseMap.toJS();

      const currentListing = house?.listings?.find(
        (listing) => listing.businessContext === businessContext,
      );

      return currentListing?.ownership;
    },
  );

export const makeSelectRentalAdministrator = () =>
  createSelector(
    selectHouse,
    makeSelectVisitBusinessContext(),
    (houseMap, businessContext) => {
      const house = houseMap.toJS();

      const currentListing = house?.listings?.find(
        (listing) => listing.businessContext === businessContext,
      );

      return currentListing?.listingRentModel?.rentalAdministrator;
    },
  );

export const makeSelectRentalGuarantee = () =>
  createSelector(
    makeSelectVisitBusinessContext(),
    makeSelectHouse(),
    makeSelectRentalAdministrator(),
    (businessContext, house, rentalAdministrator) => {
      const type =
        RENTAL_ADMINISTRATOR_TO_GUARANTEE_TYPE_MAP[rentalAdministrator];
      const value = house?.rentalGuarantee?.value;

      if (businessContext === BUSINESS_CONTEXTS.FOR_SALE || !value) {
        return {};
      }

      return {
        type,
        value,
      };
    },
  );

export const selectCancelationReason = (state) =>
  selectVisitDetails(state).get('cancelationReason');

export const makeSelectVisitCheckinStatus = () =>
  createSelector(selectVisitDetails, (state) =>
    state.get('visitCheckinStatus'),
  );

export const makeSelectShowOwnerChat = (state) =>
  selectVisit(state).get('showOwnerChat');

export const makeHiddenChatInformation = (state) =>
  selectVisit(state).get('hiddenChatInformation');

export const selectAgentInfo = (state) => {
  const businessContext = state
    .get('app')
    .get('agentData')
    .get('businessContext');

  const {
    user: { agentId },
  } = state.get('login');

  return {
    id: agentId,
    businessContext,
  };
};
