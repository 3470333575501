import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.container.Forbidden.ThirdPartyForbidden.title',
    defaultMessage:
      'Você ainda não possui cadastro de corretor no {companyName}',
  },
  subtitle: {
    id: 'app.container.Forbidden.ThirdPartyForbidden.subtitle',
    defaultMessage:
      'Procure o administrador da sua imobiliária para que ele possa liberar o seu acesso!',
  },
  benefitsLabel: {
    id: 'app.container.Forbidden.ThirdPartyForbidden.benefitsLabel',
    defaultMessage: 'Confira as vantagens do seu cadastro no {companyName}:',
  },
  benefitPortfolioLabel: {
    id: 'app.container.Forbidden.ThirdPartyForbidden.benefitPortfolioLabel',
    defaultMessage: 'Acesso a carteira de imóveis do {companyName};',
  },
  benefitSchedulingLabel: {
    id: 'app.container.Forbidden.ThirdPartyForbidden.benefitSchedulingLabel',
    defaultMessage: 'Agendar visitas em imóveis do {companyName};',
  },
  benefitVisitManagementLabel: {
    id: 'app.container.Forbidden.ThirdPartyForbidden.benefitVisitManagementLabel',
    defaultMessage: 'Gerenciar suas visitas;',
  },
  benefitAvailabilityLabel: {
    id: 'app.container.Forbidden.ThirdPartyForbidden.benefitAvailabilityLabel',
    defaultMessage:
      'Acompanhar disponibiidade de visitas e informações dos imóveis.',
  },
});
