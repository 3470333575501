import { defineMessages } from 'react-intl';

export default defineMessages({
  linksHeader: {
    id: 'app.components.ErrorPage.linksHeader',
    defaultMessage:
      'Aqui estão alguns links para informações que podem ser úteis:',
  },
  search: {
    id: 'app.components.ErrorPage.search',
    defaultMessage: 'Encontre e alugue sua próxima casa',
  },
  announce: {
    id: 'app.components.ErrorPage.announce',
    defaultMessage: 'Anuncie seu Imóvel',
  },
  indicate: {
    id: 'app.components.ErrorPage.indicate',
    defaultMessage: 'Indique Imóveis para Alugar',
  },
  beBroker: {
    id: 'app.components.ErrorPage.beBroker',
    defaultMessage: 'Seja um Corretor de Imóveis',
  },
  aboutUs: {
    id: 'app.components.ErrorPage.aboutUs',
    defaultMessage: 'Sobre o {companyName}',
  },
});
