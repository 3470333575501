import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useIntl } from 'react-intl';

import PhrasingLoading from '@quintoandar/cozy-core/experimental/PhrasingLoading';

import { useTrackEvent } from 'Base/Tracking/hooks/useTrackEvent';
import { PAGE_NAMES } from 'middlewares/event-tracker/constants';
import { getBrandName } from 'utils/i18n/brand';

import { CRM_CONTEXT, REDIRECTING_EVENTS } from './constants';
import messages from './messages';

type RedirectingProps = RouteComponentProps<any, any>;

type QueryParams = {
  [key: string]: string | null;
};

const queryParams = [
  'house_id',
  'agent_email',
  'agent_phone',
  'partner_3p_supply',
  'supply_origin',
  'click_origin',
  'crm_name',
  'creci',
];

const Redirecting = ({ location }: RedirectingProps): JSX.Element => {
  const intl = useIntl();
  const trackEvent = useTrackEvent();

  const queryParamsObj: QueryParams = {};
  queryParams.forEach((param) => {
    queryParamsObj[param] = new URLSearchParams(location.search).get(param);
  });

  useEffect(() => {
    const crmName = queryParamsObj.crm_name;
    const crmContext = localStorage.getItem(CRM_CONTEXT);
    let redirectingTimeout = 2000;

    if (crmName !== crmContext) {
      redirectingTimeout = 7000;
      localStorage.setItem(CRM_CONTEXT, crmName);
    }

    trackEvent({
      event: REDIRECTING_EVENTS.PAGE_VIEWED,
      pageName: PAGE_NAMES.REDIRECTING,
      extraProperties: queryParamsObj,
    });

    setTimeout(() => {
      const houseId = queryParamsObj.house_id;
      const phoneNumber = queryParamsObj.agent_phone;
      const schedulePath = `/?houseid=${houseId}&flow=schedule&phoneNumber=${phoneNumber}&countryCode=55`;
      window.location.assign(schedulePath);
    }, redirectingTimeout);
  }, []);

  return (
    <PhrasingLoading
      light
      steps={[
        {
          title: intl.formatMessage(messages.phraseLoadingStepOne, {
            companyName: getBrandName(),
          }),
        },
        { title: intl.formatMessage(messages.phraseLoadingStepTwo) },
        { title: intl.formatMessage(messages.phraseLoadingStepThree) },
        { title: intl.formatMessage(messages.phraseLoadingStepFour) },
        { title: intl.formatMessage(messages.phraseLoadingStepFive) },
      ]}
    />
  );
};

export default withRouter(Redirecting);
