import { fromJS } from 'immutable';

import {
  FIREBASE_LOGIN_START,
  FIREBASE_LOGIN_FAILURE,
  FIREBASE_LOGIN_SUCCESS,
  FIREBASE_LOGOUT,
} from 'containers/Firebase/constants';

export const initialState = fromJS({
  isLoggedIn: false,
  isLoggingIn: false,
});

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIREBASE_LOGIN_START:
      return state.set('isLoggingIn', true);
    case FIREBASE_LOGIN_FAILURE:
      return state
        .set('isLoggingIn', false)
        .set('isLoggedIn', false)
        .set('error', action.payload.error);
    case FIREBASE_LOGIN_SUCCESS:
      return state.set('isLoggingIn', false).set('isLoggedIn', true);
    case FIREBASE_LOGOUT:
      return state.clear().merge(initialState);
    default:
      return state;
  }
};

export default loginReducer;
