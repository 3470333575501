import {
  MY_CLIENTS_PAGE_VIEWED,
  TTA_CLIENTS_CARD_CLICKED,
  CLIENTS_SCHEDULED_VISIT_CARD_CLICKED,
  CLIENTS_PROPOSAL_STAGE_CARD_CLICKED,
} from 'containers/MyClientsGrouped/constants';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleMyClientsGroupedActions = (
  eventDispatcherHandler,
  action,
) => {
  switch (action.type) {
    case MY_CLIENTS_PAGE_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'my_clients_page_viewed',
        immutablePage(PAGE_NAMES.MY_CLIENTS_GROUPED),
      );
      break;
    case TTA_CLIENTS_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_card_clicked',
        immutablePage(PAGE_NAMES.MY_CLIENTS_GROUPED),
      );
      break;
    case CLIENTS_SCHEDULED_VISIT_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_scheduled_visit_card_clicked',
        immutablePage(PAGE_NAMES.MY_CLIENTS_GROUPED),
      );
      break;
    case CLIENTS_PROPOSAL_STAGE_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'clients_proposal_stage_card_clicked',
        immutablePage(PAGE_NAMES.MY_CLIENTS_GROUPED),
      );
      break;
    default:
      break;
  }
};

export default handleMyClientsGroupedActions;
