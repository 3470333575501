import React from 'react';
import PropTypes from 'prop-types';

import FullLink from './FullLink';

/**
 * @componentLibraryIgnore
 *
 * @name
 * CustomLink
 *
 * @description
 * A react router link with top-bottom padding and hover with transition
 *
 * @example
 * <CustomLink />
 *
 * @param {string} [linkPath]
 * @param {string} [target='_blank']
 * @param {node} [children]
 */

function CustomLink({ linkPath, target, children, ...props }) {
  return (
    <FullLink target={target} to={linkPath} {...props}>
      {children}
    </FullLink>
  );
}

CustomLink.propTypes = {
  children: PropTypes.any.isRequired,
  linkPath: PropTypes.string.isRequired,
  target: PropTypes.string,
};

CustomLink.defaultProps = {
  target: '_blank',
};

export { CustomLink };
export default CustomLink;
