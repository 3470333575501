import { checkPrefixAllow, getBaseDomain, baseDomainMatch } from 'config';
import ROUTES from 'routes/constants';

import { agentWorksWithRent } from 'utils/agents';
import { normalizePhoneV2 } from 'utils/parsers/parsePhone';

export const SITE_URL =
  checkPrefixAllow() && baseDomainMatch
    ? `${process.env.SITE_PREFIX}.${getBaseDomain()}`
    : `https://${getBaseDomain()}`;

export function buildFieldsQueryParams(fieldName, params) {
  if (!params) {
    return '';
  }

  const paramsArray = Array.isArray(params) ? params : [params];
  return paramsArray.map((field) => `${fieldName}=${field}`).join('&');
}

export function buildQueryParams(fields, values) {
  if (!fields || !values) {
    return '';
  }

  return values
    .filter(
      (v, index) =>
        typeof v !== 'undefined' && typeof fields[index] !== 'undefined',
    )
    .map((value, index) => `${fields[index]}=${value}`)
    .join('&');
}
const buildQueryParamWithName =
  ({ fields }) =>
  (param) =>
    `${fields}=${param}`;

export const buildQueryParamsByValues = (fields, values) =>
  values.map(buildQueryParamWithName({ fields })).join('&');

export const buildQueryParamsFromObject = (params) => {
  const keys = Object.keys(params);
  return keys
    .filter((key) => params[key] !== undefined)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

export function buildHouseListingUrl(houseId, agentBusinessContexts) {
  // eslint-disable-next-line no-console
  console.log('log: siteURL', SITE_URL);
  if (agentWorksWithRent(agentBusinessContexts)) {
    return `${SITE_URL}/imovel/${houseId}`;
  }
  return `${SITE_URL}/imovel/${houseId}/comprar`;
}

export function buildPropertyOwnerAgentChatUrl(
  houseId,
  ownerId,
  agentId,
  source,
) {
  const params = `source=${source}&ref=${encodeURIComponent(
    window.location.href,
  )}`;
  return `/chats/corretor/${agentId}/proprietario/${ownerId}/imovel/${houseId}/?${params}`;
}

export function buildCiqAgentWhatsAppUrl(ciqPhone, formatedMessage, locale) {
  const formatedPhone =
    typeof ciqPhone !== 'undefined'
      ? normalizePhoneV2({ phone: ciqPhone, locale })
      : '';
  return `https://wa.me/${formatedPhone}?text=${formatedMessage}`;
}

export const buildClientProfilePath = (clientId) => {
  const currentPath = encodeURIComponent(window.location.pathname);
  const currentQuery = encodeURIComponent(window.location.search);
  const basePath = ROUTES.CLIENT_PROFILE.replace(':visitorId', clientId);
  const clientProfilePath = currentPath
    ? `${basePath}?backTo=${currentPath}${currentQuery}`
    : basePath;
  return clientProfilePath;
};
