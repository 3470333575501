export const OPEN_RESCHEDULE_TIMEPICKER =
  'agentsPwa/Agenda/RescheduleVisit/OPEN_RESCHEDULE_TIMEPICKER';
export const CLOSE_RESCHEDULE_TIMEPICKER =
  'agentsPwa/Agenda/RescheduleVisit/CLOSE_RESCHEDULE_TIMEPICKER';
export const OPEN_RESCHEDULE_DATEPICKER =
  'agentsPwa/Agenda/RescheduleVisit/OPEN_RESCHEDULE_DATEPICKER';
export const CLOSE_RESCHEDULE_DATEPICKER =
  'agentsPwa/Agenda/RescheduleVisit/CLOSE_RESCHEDULE_DATEPICKER';
export const FETCH_RESCHEDULE_VISIT_AVAILABILITY =
  'agentsPwa/Agenda/RescheduleVisit/FETCH_RESCHEDULE_AVAILABILITY';
export const SET_RESCHEDULE_VISIT_TIME =
  'agentsPwa/Agenda/RescheduleVisit/SET_RESCHEDULE_VISIT_TIME';
export const SET_RESCHEDULE_VISIT_DATE =
  'agentsPwa/Agenda/RescheduleVisit/SET_RESCHEDULE_VISIT_DATE';
export const FINISH_RESCHEDULE =
  'agentsPwa/Agenda/RescheduleVisit/FINISH_RESCHEDULE';
