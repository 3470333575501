import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CenteredWrapper = styled(
  ({
    component,
    /* eslint-disable no-unused-vars */
    autoHeight,
    /* eslint-enable no-unused-vars */
    ...props
  }) => React.createElement(component, props),
)`
  margin: 0 auto;
  max-width: 1032px;
  height: ${({ autoHeight }) => (autoHeight ? 'auto' : '100%')};
  width: 100%;
`;

CenteredWrapper.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
};

CenteredWrapper.defaultProps = {
  component: 'section',
};

export default CenteredWrapper;
