import React, { Fragment } from 'react';

import CircularProgress from '@quintoandar/cozy-core/CircularProgress';

import PropTypes from 'prop-types';
import Box from '@quintoandar/cozy-core/Box';

export const LoadableFragment = ({ children, isLoading, spacingTop }) => (
  <Fragment>
    {isLoading ? (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        pt={spacingTop}
      >
        <CircularProgress />
      </Box>
    ) : (
      children
    )}
  </Fragment>
);

LoadableFragment.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  spacingTop: PropTypes.number,
};

LoadableFragment.defaultProps = {
  children: <div />,
  isLoading: false,
  spacingTop: 11,
};

export default LoadableFragment;
