/* global ineum */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ROUTES from 'routes/constants';

const findPage = (params) =>
  Object.values(ROUTES).find((routePath) => {
    const pathname = Object.entries(params).reduce(
      (path, [name, value]) => path.replace(`:${name}`, value),
      routePath || '',
    );

    return (
      pathname === window.location.pathname ||
      `${pathname}/` === window.location.pathname
    );
  });

/**
 * Track page transitions with Instana.
 * https://www.instana.com/docs/website_monitoring/faq/#what-is-the-difference-between-page-views-loads-and-transitions
 *
 * The correct match.path is only available under each <Route>:
 * https://github.com/ReactTraining/react-router/issues/5870#issuecomment-357401142
 * To be able to do this tracking at the top-most level, without adding a
 * layer between <Router> and the rendered component, we use:
 *   - useLocation to trigger the tracking on each route change
 *   - matchPath to iterate through all application routes and get the first match
 */
export const Instana = ({ location, params }) => {
  React.useEffect(() => {
    const page = findPage(params);

    // Instana might not be initialized in some environments
    if (typeof ineum !== 'undefined' && page) {
      ineum('page', page);
    }
  }, [location]);

  return null;
};

Instana.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Instana);
