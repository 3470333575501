import AccessCodeContainer from '@quintoandar/bioma-auth/containers/AccessCode';
import ConfirmAccountContainer from '@quintoandar/bioma-auth/containers/ActivateAccount';
// import CreateAccountContainer from '@quintoandar/bioma-auth/containers/CreateAccount';
import VerifyEmailContainer from '@quintoandar/bioma-auth/containers/VerifyEmail';

import AgentsLogin from 'containers/AgentsLogin';
import AgentsCreateAccount from 'containers/CreateAccount';
import ROUTES from 'routes/constants';

export default function createRoutes() {
  return [
    {
      path: ROUTES.LOGIN_PATH,
      indexRoute: {
        name: 'login',
        accountKitApiVersion: 'v1.1',
        defaultRedirect: '/',
        component: AgentsLogin,
      },
      childRoutes: [
        {
          path: ROUTES.CREATE_ACCOUNT_PATH,
          name: 'createAccount',
          defaultRedirect: '/',
          component: AgentsCreateAccount,
        },
        {
          path: ROUTES.ACCESS_CODE,
          name: 'accessCode',
          defaultRedirect: '/',
          component: AccessCodeContainer,
        },
        {
          path: ROUTES.VERIFY_EMAIL_PATH,
          name: 'verifyEmail',
          defaultRedirect: '/',
          component: VerifyEmailContainer,
        },
        {
          path: ROUTES.CONFIRM_ACCOUNT_PATH,
          name: 'confirmAccount',
          defaultRedirect: '/',
          component: ConfirmAccountContainer,
        },
      ],
    },
  ];
}
