import { handle } from 'redux-pack';
import {
  selectHouseId,
  makeSelectAgentHasKey,
  makeSelectHouseListChecks,
  makeSelectMultipleHouses,
} from 'containers/Agenda/FollowUp/selectors';
import {
  FINISH_FOLLOW_UP,
  SET_AGENT_HAS_KEY,
  SET_HAS_OTHER_HOUSE_KEY,
  SHOW_KEY_HOLDER_INFO,
} from 'containers/Agenda/FollowUp/types';
import {
  OPEN_FOLLOW_UP_DIALOG,
  CLOSE_FOLLOW_UP_DIALOG,
} from 'containers/Agenda/types';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

const getBusinessContext = (state) =>
  state.getIn(['followUp', 'visitInfo', 'businessContext']);

const hasMultipleHouseSelector = makeSelectMultipleHouses();
const hasOtherKeys = (state) => hasMultipleHouseSelector(state).length > 0;
const selectHouseListChecks = makeSelectHouseListChecks();
const selectAgentHasKey = makeSelectAgentHasKey();

export const handleFollowUpActions = (
  eventDispatcherHandler,
  action,
  state,
) => {
  switch (action.type) {
    case OPEN_FOLLOW_UP_DIALOG: {
      const extraProps = { businessContext: getBusinessContext(state) };
      eventDispatcherHandler.logWithDefaultEventProps(
        'fup_page_viewed',
        immutablePage(PAGE_NAMES.FOLLOW_UP),
        extraProps,
      );
      break;
    }

    case SHOW_KEY_HOLDER_INFO: {
      const extraProps = {
        otherKeys: hasOtherKeys(state),
      };

      eventDispatcherHandler.logWithDefaultEventProps(
        'fup_keys_page_viewed',
        immutablePage(PAGE_NAMES.FOLLOW_UP),
        extraProps,
      );
      break;
    }

    case SET_AGENT_HAS_KEY: {
      const extraProps = {
        houseId: selectHouseId(state),
        hasKeys: action.payload.isChecked,
      };

      eventDispatcherHandler.logWithDefaultEventProps(
        'fup_agent_with_keys_clicked',
        immutablePage(PAGE_NAMES.FOLLOW_UP),
        extraProps,
      );
      break;
    }

    case SET_HAS_OTHER_HOUSE_KEY: {
      const { isChecked, houseId } = action.payload;

      const extraProps = {
        houseId,
        hasKeys: isChecked,
      };

      eventDispatcherHandler.logWithDefaultEventProps(
        'fup_other_keys_clicked',
        immutablePage(PAGE_NAMES.FOLLOW_UP),
        extraProps,
      );
      break;
    }

    case FINISH_FOLLOW_UP: {
      handle(state, action, {
        success: (prevState) => {
          const otherHousesKeys = [...selectHouseListChecks(prevState)];
          const agentHasKeys = selectAgentHasKey(prevState);

          const extraProps = {
            businessContext: getBusinessContext(prevState),
            anyKeysReceived: agentHasKeys || otherHousesKeys?.length > 0,
          };

          eventDispatcherHandler.logWithDefaultEventProps(
            'fup_sent',
            immutablePage(PAGE_NAMES.FOLLOW_UP),
            extraProps,
          );
          return prevState;
        },
        failure: (prevState) => {
          const extraProps = { businessContext: getBusinessContext(prevState) };
          eventDispatcherHandler.logWithDefaultEventProps(
            'fup_sent_error',
            immutablePage(PAGE_NAMES.FOLLOW_UP),
            extraProps,
          );
          return prevState;
        },
      });
      break;
    }

    case CLOSE_FOLLOW_UP_DIALOG: {
      const extraProps = { businessContext: getBusinessContext(state) };
      eventDispatcherHandler.logWithDefaultEventProps(
        'fup_page_closed',
        immutablePage(PAGE_NAMES.FOLLOW_UP),
        extraProps,
      );
      break;
    }

    default:
      break;
  }
};

export default handleFollowUpActions;
