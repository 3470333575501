import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LinearProgress from '@quintoandar/cozy-core/LinearProgress';

import { LoadingWrapper } from 'containers/App/styles';

import { makeSelectPendingRequests } from 'containers/Progress/selectors';

import LoadableFragment from 'components/LoadableFragment';

export const LoadableContent = ({ children, isLoading, pendingRequests }) => {
  const hasPendingRequests = pendingRequests > 0;
  const shouldDisplayTopLinearProgress = isLoading || hasPendingRequests;

  const displayTopLinearProgress = shouldDisplayTopLinearProgress ? (
    <LoadingWrapper>
      <LinearProgress mode="indeterminate" />
    </LoadingWrapper>
  ) : null;

  return (
    <Fragment>
      {displayTopLinearProgress}
      <LoadableFragment isLoading={isLoading}>{children}</LoadableFragment>
    </Fragment>
  );
};

LoadableContent.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  pendingRequests: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  pendingRequests: makeSelectPendingRequests(),
});

export default connect(mapStateToProps)(LoadableContent);
