import {
  AGENDA_VISIT_DETAIL_SEND_MESSAGE_CLICKED,
  AGENDA_VISIT_DETAIL_NEW_VISIT_CLICKED,
  AGENDA_VISIT_DETAIL_ALERT_STATUS_CONFIRMED,
  VISIT_DETAILS_THIRD_PARTY_INFO_CLICKED,
} from 'containers/Agenda/Detail/VisitInfo/types';
import {
  VISIT_DETAILS_UNAVAILABLE_CHAT_INFO_CLICKED,
  VISIT_DETAILS_SECRETARY_WHATSAPP_LINK_CLICKED,
} from 'containers/Agenda/Detail/PropertyResponsible/types';
import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleVisitDetailsActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case AGENDA_VISIT_DETAIL_SEND_MESSAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        AGENDA_VISIT_DETAIL_SEND_MESSAGE_CLICKED,
        immutablePage(PAGE_NAMES.AGENDA_DETALHES),
        action.extraProperties,
      );
      break;
    case AGENDA_VISIT_DETAIL_NEW_VISIT_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        AGENDA_VISIT_DETAIL_NEW_VISIT_CLICKED,
        immutablePage(PAGE_NAMES.AGENDA_DETALHES),
        action.extraProperties,
      );
      break;
    case VISIT_DETAILS_THIRD_PARTY_INFO_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'visit_details_third_party_info_clicked',
        immutablePage(PAGE_NAMES.AGENDA_DETALHES),
        action.extraProperties,
      );
      break;
    case AGENDA_VISIT_DETAIL_ALERT_STATUS_CONFIRMED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agenda_visit_detail_alert_status_confirmed',
        immutablePage(PAGE_NAMES.AGENDA_DETALHES),
        action.extraProperties,
      );
      break;
    case VISIT_DETAILS_UNAVAILABLE_CHAT_INFO_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'visit_details_unavailable_chat_info_clicked',
        immutablePage(PAGE_NAMES.AGENDA_DETALHES),
        action.extraProperties,
      );
      break;
    case VISIT_DETAILS_SECRETARY_WHATSAPP_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'visit_details_secretary_whatsapp_link_clicked',
        immutablePage(PAGE_NAMES.AGENDA_DETALHES),
        action.extraProperties,
      );
      break;
    default:
      break;
  }
};

export default handleVisitDetailsActions;
