import {
  REFRESH_PROFILE,
  LOGOUT,
} from '@quintoandar/bioma-auth/containers/constants';

import {
  INTERCOM_SHUTDOWN,
  INTERCOM_UPDATE,
  INTERCOM_ALIGNMENT,
} from 'components/Intercom/constants';
import { parsePhone } from 'utils/parsers/parsePhone';
import { makeSelectUser } from '@quintoandar/bioma-auth/containers/selectors';
import { immutableToImmer } from 'utils';

const intercomMiddleware = (store) => (next) => async (action) => {
  const intercom = window.Intercom;
  const result = next(action);

  if (intercom) {
    switch (action.type) {
      case REFRESH_PROFILE: // eslint-disable-line no-case-declarations
        const { email, id, name, phone } = immutableToImmer(makeSelectUser())(
          store.getState(),
        );

        if (email && name && phone && id) {
          intercom(INTERCOM_UPDATE, {
            email,
            name,
            phone: parsePhone(phone),
            user_id: id,
            alignment: INTERCOM_ALIGNMENT,
          });
        } else {
          // without this, Intercom would stay initalized with random name
          intercom(INTERCOM_SHUTDOWN);
        }
        break;
      case LOGOUT:
        intercom(INTERCOM_SHUTDOWN);
        break;
      default:
        return result;
    }
  }

  return result;
};

export default intercomMiddleware;
