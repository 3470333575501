import { FormattedMessage } from 'react-intl';

import Box from '@quintoandar/cozy-core/Box';
import Grid from '@quintoandar/cozy-core/Grid';
import Stepper, { Step } from '@quintoandar/cozy-core/Stepper';
import Typography from '@quintoandar/cozy-core/Typography';
import SpotIllustration from '@quintoandar/cozy-core/experimental/SpotIllustration';
import tokens from '@quintoandar/cozy-theme/tokens/js';
import { getBrandName } from 'utils/i18n/brand';

import AppHeader from './PageHeader';
import messages from './messages';

const ThirdPartyForbidden = () => (
  <>
    <AppHeader />
    <Box
      p={tokens.alias.spacing.large}
      bgcolor={tokens.alias.color.surface.highlight.default}
    >
      <Grid container spacing={3}>
        <Grid item md={6} sm={12}>
          <Box
            bgcolor={tokens.alias.color.surface.default}
            p={tokens.alias.spacing.medium}
          >
            <Box mb={tokens.alias.spacing.xsmall}>
              <Typography level="display">
                <FormattedMessage
                  {...messages.title}
                  values={{ companyName: getBrandName() }}
                />
              </Typography>
            </Box>
            <Box mb={tokens.alias.spacing.small}>
              <Typography level="title">
                <FormattedMessage {...messages.subtitle} />
              </Typography>
            </Box>
            <Box mb={tokens.alias.spacing.xsmall}>
              <Typography level="bodyStrong">
                <FormattedMessage
                  {...messages.benefitsLabel}
                  values={{ companyName: getBrandName() }}
                />
              </Typography>
            </Box>

            <Box mb={tokens.alias.spacing.xsmall}>
              <Stepper isStatic noInitialConnector noEndConnector noPadding>
                <Step
                  active
                  label={
                    <Typography kind="lighter">
                      <FormattedMessage
                        {...messages.benefitPortfolioLabel}
                        values={{ companyName: getBrandName() }}
                      />
                    </Typography>
                  }
                />
              </Stepper>
            </Box>
            <Box mb={tokens.alias.spacing.xsmall}>
              <Stepper isStatic noInitialConnector noEndConnector noPadding>
                <Step
                  active
                  label={
                    <Typography kind="lighter">
                      <FormattedMessage
                        {...messages.benefitSchedulingLabel}
                        values={{ companyName: getBrandName() }}
                      />
                    </Typography>
                  }
                />
              </Stepper>
            </Box>
            <Box mb={tokens.alias.spacing.xsmall}>
              <Stepper isStatic noInitialConnector noEndConnector noPadding>
                <Step
                  active
                  label={
                    <Typography kind="lighter">
                      <FormattedMessage
                        {...messages.benefitVisitManagementLabel}
                      />
                    </Typography>
                  }
                />
              </Stepper>
            </Box>
            <Box mb={tokens.alias.spacing.xsmall}>
              <Stepper isStatic noInitialConnector noEndConnector noPadding>
                <Step
                  completed
                  label={
                    <Typography kind="lighter">
                      <FormattedMessage
                        {...messages.benefitAvailabilityLabel}
                      />
                    </Typography>
                  }
                />
              </Stepper>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box display="flex" justifyContent="center">
            <SpotIllustration name="gestures-property-live" size="large" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
);

export default ThirdPartyForbidden;
