import { handle } from 'redux-pack';
import { FINISH_RESCHEDULE } from 'containers/Agenda/NewVisitReschedule/RescheduleVisit/types';
import { OPEN_RESCHEDULE_VISIT_DIALOG } from 'containers/Agenda/types';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleRescheduleVisitAction = (
  eventDispatcherHandler,
  action,
  state,
) => {
  switch (action.type) {
    case OPEN_RESCHEDULE_VISIT_DIALOG:
      eventDispatcherHandler.logWithDefaultEventProps(
        'reschedule_page_viewed',
        immutablePage(PAGE_NAMES.RESCHEDULE),
      );
      break;

    case FINISH_RESCHEDULE:
      handle(state, action, {
        success: (prevState) => {
          const businessContext = prevState.getIn([
            'rescheduleVisit',
            'businessContext',
          ]);
          const extraProps = { businessContext };

          eventDispatcherHandler.logWithDefaultEventProps(
            'confirm_visit_reschedule',
            immutablePage(PAGE_NAMES.RESCHEDULE),
            extraProps,
          );
          return prevState;
        },
      });
      break;

    default:
      break;
  }
};

export default handleRescheduleVisitAction;
