import agentsApiConfig from 'api/agents';
import { buildFieldsQueryParams, buildQueryParamsFromObject } from 'utils/urls';
import {
  AGENT_VISIT_LIST_V3,
  AGENT_VISIT_BY_CODE_V3,
  AGENT_CONFIRM_VISIT_BY_CODE_V3,
  VISIT_AGENT_CHAT_V3,
  AGENT_CREATE_VISIT_V3,
  AGENT_REGISTER_VISIT_V3,
  AGENT_UPDATE_VISIT_V3,
  FOLLOW_UP_V3,
  AGENT_VISIT_CONFIGURATION,
  VISITOR_NEGOTIATION_QUALIFICATION,
  HOUSE_VISIT_CONFIGURATION,
} from 'api/agents/endpoints';
import { HTTP_METHODS } from 'config';
import ACTOR_ROLE from 'enums/actorRolesTypes';

export const getVisitsByAgentId = (
  businessContext,
  currentPage = 1,
  pageSize = 20,
  requestedFields = [],
  {
    startDate,
    endDate,
    status,
    houseIds,
    sortedBy = [],
    accessAuthorization,
    onBehalfOfRole,
    userSource,
    userId,
  } = {},
) => {
  const requestedFieldsQueryParam = buildFieldsQueryParams(
    'requestedFields',
    requestedFields,
  );

  const statusQueryParam = buildFieldsQueryParams('status', status);

  const sortedByQueryParam = buildFieldsQueryParams('sortedBy', sortedBy);

  const queryParam = buildQueryParamsFromObject({
    businessContext,
    currentPage,
    pageSize,
    startDate,
    endDate,
    houseIds,
    accessAuthorization,
    onBehalfOfRole,
    userSource,
    userId,
  });

  return agentsApiConfig({
    endpoint: AGENT_VISIT_LIST_V3,
    method: HTTP_METHODS.GET,
    queryString: `${queryParam}&${requestedFieldsQueryParam}&${statusQueryParam}&${sortedByQueryParam}`,
  });
};

export const getVisitByCode = (payload) =>
  agentsApiConfig({
    endpoint: AGENT_VISIT_BY_CODE_V3.replace(':visitCode', payload.visitCode),
    method: HTTP_METHODS.GET,
    queryString: buildFieldsQueryParams(
      'requestedFields',
      payload.requestedFields,
    ),
  });

export const confirmVisitByCode = ({
  onBehalfOf = ACTOR_ROLE.SUPPLY,
  visitCode,
}) =>
  agentsApiConfig({
    endpoint: AGENT_CONFIRM_VISIT_BY_CODE_V3.replace(':visitCode', visitCode),
    method: HTTP_METHODS.PUT,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload: {
      onBehalfOf,
    },
  });

export const getAgentVisitConfiguration = (userId = '') =>
  agentsApiConfig({
    endpoint: AGENT_VISIT_CONFIGURATION.replace(':userId', userId),
    method: HTTP_METHODS.GET,
  });

export const createVisitChat = ({ visitorId }) =>
  agentsApiConfig({
    endpoint: VISIT_AGENT_CHAT_V3,
    method: HTTP_METHODS.PUT,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload: {
      visitorId,
    },
  });

export const createVisit = ({
  houseId,
  visitorId,
  date,
  slot,
  businessContext,
  confirmAfterCreation,
}) =>
  agentsApiConfig({
    endpoint: AGENT_CREATE_VISIT_V3,
    method: HTTP_METHODS.POST,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload: {
      houseId,
      visitorId,
      date,
      slot,
      businessContext,
      confirmAfterCreation,
    },
  });

export const registerVisit = ({
  houseId,
  visitorId,
  date,
  slot,
  businessContext,
  comment,
  type,
  confirmAfterCreation,
}) =>
  agentsApiConfig({
    endpoint: AGENT_REGISTER_VISIT_V3,
    method: HTTP_METHODS.POST,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload: {
      houseId,
      visitorId,
      date,
      slot,
      businessContext,
      comment,
      type,
      confirmAfterCreation,
    },
  });

export const rescheduleVisit = (
  visitCode,
  { houseId, date, slot, visitorId, businessContext, confirmAfterCreation },
) =>
  agentsApiConfig({
    endpoint: AGENT_UPDATE_VISIT_V3.replace(':visitCode', visitCode),
    method: HTTP_METHODS.PUT,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload: {
      houseId,
      date,
      visitorId,
      slot,
      businessContext,
      confirmAfterCreation,
    },
  });

export const createFollowUp = (visitCode, payload) =>
  agentsApiConfig({
    endpoint: FOLLOW_UP_V3.replace(':visitCode', visitCode),
    method: HTTP_METHODS.POST,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload,
  });

export const getVisitorQualification = ({
  userId,
  businessContext,
  houseId,
}) => {
  const queryParam = buildQueryParamsFromObject({
    businessContext,
    houseId,
  });

  return agentsApiConfig({
    endpoint: VISITOR_NEGOTIATION_QUALIFICATION.replace(':userId', userId),
    method: HTTP_METHODS.GET,
    queryString: queryParam,
  });
};

export const getHouseVisitConfiguration = (houseId) =>
  agentsApiConfig({
    endpoint: HOUSE_VISIT_CONFIGURATION.replace(':houseId', houseId),
    method: HTTP_METHODS.GET,
  });
