import { HTTP_METHODS } from 'config';
import createHmac from 'create-hmac';

import agentsApiConfig from 'api/agents';
import {
  HOUSES,
  HOUSES_V2,
  SALE_HOUSES_PERFORMANCE,
  HOUSES_USERS_INFO,
  SIMILAR_HOUSES,
  HOUSE_SUGGESTIONS_V2,
  HOUSE_INFO,
  HOUSE_CHARACTERISTICS,
  AGENT_LISTING_REPORT,
  UPDATE_HOUSE_KEY_LOCATION,
  GET_HOUSE_KEY_LOCATION,
} from 'api/agents/endpoints';
import { VISIT_STATUS } from 'containers/Agenda/Detail/constants';

import {
  buildFieldsQueryParams,
  buildQueryParams,
  buildQueryParamsByValues,
} from '../../utils/urls';

/**
 * Get house details from agents api proxy
 *
 * @returns {object} - house details
 */

export const hashForHouseInfo = ({ agentId, houseId }) =>
  createHmac('sha256', `${agentId}`).update(`${houseId}`).digest('hex');

export const fetchHouseDetails = ({
  agentId = null,
  houseId = null,
  queryString = '',
}) => {
  if (!houseId || !agentId) {
    return Promise.resolve({});
  }

  return agentsApiConfig({
    endpoint: `${HOUSES_V2}/${houseId}`,
    method: HTTP_METHODS.GET,
    queryString,
    extraHeaders: {
      Hash: hashForHouseInfo({ agentId, houseId }),
    },
  });
};

export const fetchHouseEntryInformationDetails = ({
  agentId = null,
  houseId = null,
  fields,
}) => {
  if (!houseId || !agentId) {
    return Promise.resolve({});
  }

  return agentsApiConfig({
    endpoint: `${HOUSES}/${houseId}/entryinfo`,
    method: HTTP_METHODS.GET,
    queryString: buildFieldsQueryParams('field', fields),
    extraHeaders: {
      Hash: hashForHouseInfo({ agentId, houseId }),
    },
  });
};

export const fetchHouseRentProgressCount = ({ houseId = null }) => {
  if (!houseId) {
    return Promise.resolve({});
  }

  return agentsApiConfig({
    endpoint: `${HOUSES_V2}/${houseId}/rent-progress/count`,
    method: HTTP_METHODS.GET,
  });
};

export const fetchSaleHousePerformanceData = (houseId) =>
  agentsApiConfig({
    endpoint: SALE_HOUSES_PERFORMANCE.replace(':id', houseId),
    method: HTTP_METHODS.GET,
  });

export const fetchHouseEntranceConditionsHistory = ({
  houseId,
  page = 1,
  pageSize = 10,
}) => {
  const params = new URLSearchParams();

  params.set('page', page);
  params.set('pageSize', pageSize);

  return agentsApiConfig({
    endpoint: `${HOUSES}/${houseId}/entrance-conditions-record`,
    method: HTTP_METHODS.GET,
    queryString: params.toString(),
  });
};

/**
 * Get house details and their owner info
 */
export const fetchHouseUserDetails = (params) => {
  const propertiesIdsQuery = buildQueryParamsByValues(
    'houseIds',
    params.houseIds,
  );
  const businessContext = params.businessContext || 'RENT';
  const queryString = `businessContext=${businessContext}&${propertiesIdsQuery}`;
  return agentsApiConfig({
    endpoint: HOUSES_USERS_INFO,
    method: HTTP_METHODS.GET,
    queryString,
  });
};

/**
 * Get similar houses based on the parameters
 */
export const fetchSimilarHouses = (houseIds, businessContext) => {
  const params = [
    'houseIds',
    'max_distance',
    'size',
    'visitStatus',
    'visitStatus',
    'businessContext',
  ];
  const values = [
    houseIds,
    1000,
    10,
    VISIT_STATUS.ACCEPT_NEW,
    VISIT_STATUS.ACCEPT_ALWAYS,
    businessContext,
  ];
  const queryString = buildQueryParams(params, values);

  return agentsApiConfig({
    endpoint: SIMILAR_HOUSES,
    queryString,
    method: HTTP_METHODS.GET,
  });
};

export const fetchHouseSuggestionsV2 = (
  userId,
  businessContext = 'SALE',
  recommendationType = ['region', 'price'],
) => {
  const payload = {
    userId,
    size: 10,
    businessContext,
    recommendationType,
  };

  return agentsApiConfig({
    endpoint: HOUSE_SUGGESTIONS_V2,
    method: HTTP_METHODS.POST,
    payload,
  });
};

export const fetchHouseMatch = ({ houseId, businessContext }) => {
  const queryString = !!businessContext && `businessContext=${businessContext}`;

  return agentsApiConfig({
    endpoint: `${SIMILAR_HOUSES}/${houseId}/match`,
    method: HTTP_METHODS.GET,
    queryString,
  });
};

export const unpublishListing = (houseId, reason, businessContext) =>
  agentsApiConfig({
    endpoint: `house-agents/${houseId}/unpublish/v2`,
    method: HTTP_METHODS.PUT,
    queryString: `reason=${reason}&businessContext=${businessContext}`,
  });

export const getPropertyInfo = (propertyId) =>
  agentsApiConfig({
    endpoint: HOUSE_INFO.replace(':houseId', propertyId),
    method: HTTP_METHODS.GET,
  });

export const putPropertyInfo = ({ houseId, payload }) =>
  agentsApiConfig({
    endpoint: HOUSE_CHARACTERISTICS.replace(':houseId', houseId),
    method: HTTP_METHODS.PUT,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload,
  });

export const postPropertyInfo = (payload) =>
  agentsApiConfig({
    endpoint: AGENT_LISTING_REPORT,
    method: HTTP_METHODS.POST,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload,
  });

export const updateHouseKeyLocation = (
  houseId,
  journey,
  performerRole,
  payload,
) =>
  agentsApiConfig({
    endpoint: UPDATE_HOUSE_KEY_LOCATION.replace(':houseId', houseId)
      .replace(':journey', journey)
      .replace(':performerRole', performerRole),
    method: HTTP_METHODS.PUT,
    extraHeaders: { 'Content-Type': 'application/json' },
    payload,
  });

export const getHouseKeyLocation = (houseId, journey, performerRole) =>
  agentsApiConfig({
    endpoint: GET_HOUSE_KEY_LOCATION.replace(':houseId', houseId)
      .replace(':journey', journey)
      .replace(':performerRole', performerRole),
    method: HTTP_METHODS.GET,
    extraHeaders: { 'Content-Type': 'application/json' },
  });
