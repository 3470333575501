export const FEATURES = Object.freeze({
  RESERVATION_ALLOWED: 'RESERVATION_ALLOWED',
  PP_CR_CHAT: 'PP_CR_CHAT',
  TRAZ_QUEM_COMPRA: 'TRAZ_QUEM_COMPRA',
  DIRECT_REFERRAL: 'DIRECT_REFERRAL',
  TENANT_SERVICE_FEE: 'TENANT_SERVICE_FEE',
  HOME_OWNER_INSURANCE: 'HOME_OWNER_INSURANCE',
  SELF_SERVICE_TAXES: 'SELF_SERVICE_TAXES',
  INTERCOM: 'INTERCOM',
});
