import { defineMessages } from 'react-intl';

export default defineMessages({
  appBarTitle: {
    id: 'app.container.TimeBlock.appBarTitle',
    defaultMessage: 'Bloqueio de horários',
  },
  emptyStateFooterMessage: {
    id: 'app.container.TimeBlock.emptyStateFooterMessage',
    defaultMessage:
      'Caso não possa realizar visitas em algum desses horários, adicione um bloqueio.',
  },
  blockTimeButtonLabel: {
    id: 'app.container.TimeBlock.blockTimeButtonLabel',
    defaultMessage: 'Bloquear horários',
  },
  createTemporaryBlockButtonLabel: {
    id: 'app.container.TimeBlock.createTemporaryBlockButtonLabel',
    defaultMessage: 'Criar bloqueio temporário',
  },
  suspendedAgenda: {
    id: 'app.container.TimeBlock.suspendedAgenda',
    defaultMessage:
      'Você não possui horários disponíveis para realizar novos bloqueios',
  },
  suspendedAgendaLearnMore: {
    id: 'app.container.TimeBlock.suspendedAgendaLearnMore',
    defaultMessage: 'Para saber mais, entre em contato com o nosso suporte',
  },
  errorDialogTitle: {
    id: 'app.container.TimeBlock.errorDialogTitle',
    defaultMessage: 'Erro ao carregar informações',
  },
  errorDialogBody: {
    id: 'app.container.TimeBlock.errorDialogBody',
    defaultMessage:
      'Ocorreu um erro ao carregar as suas informações de bloqueio de horários',
  },
  goBack: {
    id: 'app.container.TimeBlock.goBack',
    defaultMessage: 'Voltar ao início',
  },
  editTimeBlockDisabled: {
    id: 'app.container.TimeBlock.editTimeBlockDisabled',
    defaultMessage:
      'Para alterar seus bloqueios de horários, fale com a central de atendimento. ' +
      'Em breve você vai poder fazer seu controle por aqui.',
  },
  monday: {
    id: 'app.container.TimeBlock.monday',
    defaultMessage: 'Segundas-feiras',
  },
  tuesday: {
    id: 'app.container.TimeBlock.tuesday',
    defaultMessage: 'Terças-feiras',
  },
  wednesday: {
    id: 'app.container.TimeBlock.wednesday',
    defaultMessage: 'Quartas-feiras',
  },
  thursday: {
    id: 'app.container.TimeBlock.thursday',
    defaultMessage: 'Quintas-feiras',
  },
  friday: {
    id: 'app.container.TimeBlock.friday',
    defaultMessage: 'Sextas-feiras',
  },
  saturday: {
    id: 'app.container.TimeBlock.saturday',
    defaultMessage: 'Sábados',
  },
  sunday: {
    id: 'app.container.TimeBlock.sunday',
    defaultMessage: 'Domingos',
  },
  entireDay: {
    id: 'app.container.TimeBlock.entireDay',
    defaultMessage: 'Dia inteiro',
  },
  createRequestSuccess: {
    id: 'app.container.TimeBlock.createRequestSuccess',
    defaultMessage: 'Bloqueio adicionado com sucesso!',
  },
  deleteRequestSuccess: {
    id: 'app.container.TimeBlock.deleteRequestSuccess',
    defaultMessage: 'Bloqueio excluído com sucesso!',
  },
  editRequestSuccess: {
    id: 'app.container.TimeBlock.editRequestSuccess',
    defaultMessage: 'Bloqueio editado com sucesso!',
  },
  updateRequestError: {
    id: 'app.container.TimeBlock.updateRequestError',
    defaultMessage: 'Erro ao bloquear horário',
  },
  deleteRequestError: {
    id: 'app.container.TimeBlock.deleteRequestError',
    defaultMessage: 'Erro ao excluir horário',
  },
  blockedDatesRange: {
    id: 'app.container.TimeBlock.datesRange',
    defaultMessage: `{isMultipleDates, select,
      true {{startDate} até {endDate}}
      other {{startDate}}
    }`,
  },
  timeBlockExplanation: {
    id: 'app.container.TimeBlock.timeBlockExplanation',
    defaultMessage:
      'Abaixo estão os dias e horários nos quais você <b>não receberá visitas</b>',
  },
});
