import {
  TALK_TO_TENANT,
  TALK_TO_TENANT_DIALOG,
} from 'components/HouseWithResident/constants';
import { makeSelectHouseId } from 'containers/Agenda/Detail/selectors';
import { makeSelectAgentId } from 'containers/Agenda/selectors';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const extraProps = (state, action) => ({
  agentId: makeSelectAgentId()(state),
  houseId: makeSelectHouseId()(state),
  tenantId: action.meta.tenantId,
});

export const handleWhatsAppLinkActions = (
  eventDispatcherHandler,
  action,
  state,
) => {
  switch (action.type) {
    case TALK_TO_TENANT: {
      eventDispatcherHandler.logWithDefaultEventProps(
        'saleagent_visitdetails_talktotenant_button_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        extraProps(state, action),
      );
      break;
    }

    case TALK_TO_TENANT_DIALOG: {
      eventDispatcherHandler.logWithDefaultEventProps(
        'saleagent_visitdetails_tips_talktotenant_button_clicked',
        immutablePage(PAGE_NAMES.AGENDA),
        extraProps(state, action),
      );
      break;
    }

    default:
      break;
  }
};

export default handleWhatsAppLinkActions;
