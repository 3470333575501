export const FEATURE_FLAGS = Object.freeze({
  MIGRATION_TO_API_V3: 'MIGRATION_TO_API_V3',
  VISIT_DONE_V3: 'VISIT_DONE_V3',
  REGISTER_VISIT_V3: 'REGISTER_VISIT_V3',
  VISIT_CONFIRMATION_BY_AGENT: 'VISIT_CONFIRMATION_BY_AGENT',
  VISIT_DETAIL_STATUS_COMPONENT_V2: 'visit_detail_status_component_v2',
  VISIT_STATUS_UNAVAILABLE_HOURS: 'visit_status_unavailable_hours',
  OFFER_STATUS_LIST: 'OFFER_STATUS_LIST',
  AGENTS_EARLY_DEMAND: 'AGENTS_EARLY_DEMAND',
  VISIT_AUTOMATIC_CANCELLATION: 'visit_automatic_cancellation',
  LEAD_GEN_BROKERS: 'lead_gen_brokers',
  DISABLED_CRM_OFFER_FILTERS: 'disable_crm_offer_filters',
  ENABLE_PORTFOLIO_LOGIN: 'enablePortfolioLogin',
  ENABLE_PASSIVE_LEAD_RECEIVER: 'passive_lead_receiver_flag',
  FIRESTORE_DEPRECATION_RENT_OFFERS: 'firestore_deprecation_rent_offers',
  BUSINESS_MODEL_CONTROL: 'business_model_flag',
});

export const FEATURE_FLAGS_KEYS = Object.freeze({
  [FEATURE_FLAGS.MIGRATION_TO_API_V3]: 'MIGRATION_TO_API_V3',
  [FEATURE_FLAGS.VISIT_DONE_V3]: 'VISIT_DONE_V3',
  [FEATURE_FLAGS.REGISTER_VISIT_V3]: 'REGISTER_VISIT_V3',
  [FEATURE_FLAGS.VISIT_CONFIRMATION_BY_AGENT]: 'VISIT_CONFIRMATION_BY_AGENT',
  [FEATURE_FLAGS.VISIT_DETAIL_STATUS_COMPONENT_V2]:
    'visit_detail_status_component_v2',
  [FEATURE_FLAGS.VISIT_STATUS_UNAVAILABLE_HOURS]:
    'visit_status_unavailable_hours',
  [FEATURE_FLAGS.OFFER_STATUS_LIST]: 'offer_status_list',
  [FEATURE_FLAGS.AGENTS_EARLY_DEMAND]: 'agents_early_demand',
  [FEATURE_FLAGS.VISIT_AUTOMATIC_CANCELLATION]: 'visit_automatic_cancellation',
  [FEATURE_FLAGS.LEAD_GEN_BROKERS]: 'lead_gen_brokers',
  [FEATURE_FLAGS.ENABLE_PORTFOLIO_LOGIN]: 'enablePortfolioLogin',
  [FEATURE_FLAGS.ENABLE_PASSIVE_LEAD_RECEIVER]: 'passive_lead_receiver_flag',
  [FEATURE_FLAGS.BUSINESS_MODEL_CONTROL]: 'business_model_flag',
});

export const FEATURE_FLAGS_ENABLED = Object.freeze({
  [FEATURE_FLAGS.MIGRATION_TO_API_V3]: 'isMigrationToApiV3Enabled',
  [FEATURE_FLAGS.VISIT_DONE_V3]: 'isVisitDoneV3Enabled',
  [FEATURE_FLAGS.REGISTER_VISIT_V3]: 'isRegisterVisitV3Enabled',
  [FEATURE_FLAGS.VISIT_CONFIRMATION_BY_AGENT]:
    'isVisitConfirmationByAgentEnabled',
  [FEATURE_FLAGS.VISIT_DETAIL_STATUS_COMPONENT_V2]:
    'isVisitDetailStatusComponentV2Enabled',
  [FEATURE_FLAGS.VISIT_STATUS_UNAVAILABLE_HOURS]:
    'isVisitStatusUnavailableHoursEnabled',
  [FEATURE_FLAGS.AGENTS_EARLY_DEMAND]: 'isAgentsEarlyDemandEnabled',
  [FEATURE_FLAGS.VISIT_AUTOMATIC_CANCELLATION]:
    'isVisitAutomaticCancellationEnabled',
  [FEATURE_FLAGS.LEAD_GEN_BROKERS]: 'isLeadGenBrokersEnabled',
  [FEATURE_FLAGS.ENABLE_PORTFOLIO_LOGIN]: 'isPortfolioLoginEnabled',
});
