import { replace } from 'react-router-redux';

import ROUTES from 'routes/constants';

export const containsLoginPath = (path) =>
  path && path.includes(ROUTES.LOGIN_PATH);

export const containsRedirectingPath = (path) =>
  path && path.includes(ROUTES.REDIRECTING);

export const redirectToLoginPage = (redirectBack) => {
  const path = redirectBack
    ? `${ROUTES.LOGIN_PATH}?redirect=${redirectBack}`
    : ROUTES.LOGIN_PATH;
  return replace(path);
};

export const getLoginPath = () => {
  const redirectPath = window.location.pathname + window.location.search;

  if (!containsLoginPath(redirectPath)) {
    return `/auth/login?redirect=${redirectPath}`;
  }

  return redirectPath;
};
