import { LOGIN_SUCCESS } from '@quintoandar/bioma-auth/containers/constants';

import { USERS_ME_URL } from 'containers/App/constants';
import { refreshProfile } from '@quintoandar/bioma-auth/containers/actions';

const refreshProfileMiddleware = (store) => (next) => (action) => {
  if (action.type === LOGIN_SUCCESS) {
    store.dispatch(refreshProfile(USERS_ME_URL));
  }
  return next(action);
};

export default refreshProfileMiddleware;
