/**
 * Enum of owner summary
 * @type {Readonly<{*}>}
 */
export const OWNER_SUMMARY = Object.freeze({
  HOUSE_INACTIVE: 'HouseInactive',
  HOUSE_UNPUBLISHED: 'HouseUnpublished',
  OWNER_APPROVED_DOCUMENTATION: 'OwnerApprovedDocumentation',
  OWNER_DEADLINE_DOCUMENTATION: 'OwnerDeadlineDocumentation',
  OWNER_DEADLINE_RESEND_DOCUMENTATION: 'OwnerDeadlineResendDocumentation',
  OWNER_DOCUMENTATION_IN_ANALYSIS: 'OwnerDocumentationInAnalysis',
  OWNER_REJECTED_OFFER: 'OwnerRejectedOffer',
  OWNER_SIGNED_CONTRACT: 'OwnerSignedContract',
  STALLED_REJECTION: 'StalledRejection',
  WAITING_OWNER_RESPOND_COUNTER_OFFER: 'WaitingOwnerRespondCounterOffer',
  WAITING_OWNER_RESPONSE: 'WaitingOwnerResponse',
  WAITING_OWNER_SIGN_CONTRACT: 'WaitingOwnerSignContract',
  WAITING_TENANT_RESPOND_COUNTER_OFFER: 'WaitingTenantRespondCounterOffer',
});

/**
 * Enum of tenant summary
 * @type {Readonly<{*}>}
 */
export const TENANT_SUMMARY = Object.freeze({
  STALLED_REJECTION: 'StalledRejection',
  TENANT_APPROVED_DOCUMENTATION: 'TenantApprovedDocumentation',
  TENANT_DEADLINE_DOCUMENTATION: 'TenantDeadlineDocumentation',
  TENANT_DEADLINE_RESEND_DOCUMENTATION: 'TenantDeadlineResendDocumentation',
  TENANT_DIDNT_SENT_OFFER: 'TenantDidntSentOffer',
  TENANT_DOCUMENTATION_IN_ANALYSIS: 'TenantDocumentationInAnalysis',
  TENANT_REJECTED: 'TenantRejected',
  TENANT_REJECTED_OFFER: 'TenantRejectedOffer',
  TENANT_SIGNED_CONTRACT: 'TenantSignedContract',
  TENANT_WITH_MULTIPLE_DOCS: 'TenantWithMultipleDocs',
  WAITING_OWNER_RESPOND_COUNTER_OFFER: 'WaitingOwnerRespondCounterOffer',
  WAITING_OWNER_RESPONSE: 'WaitingOwnerResponse',
  WAITING_TENANT_RESPOND_COUNTER_OFFER: 'WaitingTenantRespondCounterOffer',
  WAITING_TENANT_SIGN_CONTRACT: 'WaitingTenantSignContract',
});
