import {
  TTA_CLIENTS_EXPAND_CARD_CLICKED,
  TTA_CLIENTS_NEXT_PAGE_CLICKED,
  TTA_CLIENTS_PAGE_VIEWED,
  TTA_CLIENTS_SEND_MESSAGE_LINK_CLICKED,
  TTA_CLIENTS_SORT_BY_ALPHABETICAL_ORDER_CLICKED,
  TTA_CLIENTS_SORT_BY_RECENT_CLIENTS_CLICKED,
  TTA_CLIENTS_SORTING_LINK_CLICKED,
  TTA_CLIENTS_VIEW_PROFILE_LINK_CLICKED,
  TTA_START_SERVICE_CLICKED,
} from 'containers/OnlineSupport/constants';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleOnlineSupportActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case TTA_CLIENTS_PAGE_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_page_viewed',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
      );
      break;
    case TTA_START_SERVICE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_start_service_cliked',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
      );
      break;
    case TTA_CLIENTS_EXPAND_CARD_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_expand_card_clicked',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
      );
      break;
    case TTA_CLIENTS_SEND_MESSAGE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_send_message_link_clicked',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
        action.extraProperties,
      );
      break;
    case TTA_CLIENTS_VIEW_PROFILE_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_view_profile_link_clicked',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
      );
      break;
    case TTA_CLIENTS_SORTING_LINK_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_sorting_link_clicked',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
      );
      break;
    case TTA_CLIENTS_SORT_BY_RECENT_CLIENTS_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_sort_by_recent_clients_clicked',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
      );
      break;
    case TTA_CLIENTS_SORT_BY_ALPHABETICAL_ORDER_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_sort_by_alphabetical_order_clicked',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
      );
      break;
    case TTA_CLIENTS_NEXT_PAGE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_clients_next_page_clicked',
        immutablePage(PAGE_NAMES.ONLINE_SUPPORT),
        action.extraProperties,
      );
      break;
    default:
      break;
  }
};

export default handleOnlineSupportActions;
