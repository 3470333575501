/**
 * Enum of rejection reasons
 * @type {Readonly<{*}>}
 */
const REJECTION_REASON = Object.freeze({
  INACTIVE_HOUSE: 'InactiveHouse',
  OWNER_DOESNT_AGREE_WITH_CONDITIONS: 'OwnerDoesntAgreeWithConditions',
  OWNER_DOESNT_AGREE_WITH_VALUE: 'OwnerDoesntAgreeWithValue',
  OWNER_GAVE_UP_RENTING: 'OwnerGaveUpRenting',
  OWNER_RENTED_FOR_ANOTHER_TENANT: 'OwnerRentedForAnotherTenant5A',
  OWNER_RENTED_OUTSIDE: 'OwnerRentedForAnotherTenantOutside',
  OWNER_SOLD_APARTMENT: 'OwnerSoldApartment',
  OWNER_STALLED: 'OwnerStalled',
  TENANT_DOESNT_AGREE_WITH_CONDITIONS: 'TenantDoesntAgreeWithConditions',
  TENANT_DOESNT_AGREE_WITH_VALUE: 'TenantDoesntAgreeWithValue',
  TENANT_GAVE_UP_APARTMENT: 'TenantGaveUpApartment',
  TENANT_GAVE_UP_RENTING: 'TenantGaveUpRenting',
  TENANT_RENTED_ANOTHER: 'TenantRentedAnother5A',
  TENANT_RENTED_OUTSIDE: 'TenantRentedAnotherOutside',
  TENANT_STALLED: 'TenantStalled',
  UNPUBLISHED_HOUSE: 'UnpublishedHouse',
});

export default REJECTION_REASON;
