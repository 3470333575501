import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.container.AgentsLogin.title',
    defaultMessage: 'Acesse sua conta',
  },
  subtitle: {
    id: 'app.container.AgentsLogin.subtitle',
    defaultMessage:
      'Digite seu celular para entrar. Seus dados estão seguros e você não precisa de senha.',
  },
  alertTitle: {
    id: 'app.container.AgentsLogin.alertTitle',
    defaultMessage: 'É corretor de Rede {brand}?',
  },
  alertDescription: {
    id: 'app.container.AgentsLogin.alertDescription',
    defaultMessage:
      'Clique no link abaixo e seja direcionado para o acesso a sua conta de corretor Rede {brand}',
  },
  portfolioLink: {
    id: 'app.container.AgentsLogin.portfolioLink',
    defaultMessage: 'Acesse aqui',
  },
});
