import React, { PureComponent } from 'react';
import ErrorPage from 'components/ErrorPage';
import { ERROR_PAGE_IMAGE_URL } from 'config';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { refreshProfile } from '@quintoandar/bioma-auth/containers/actions';
import { makeSelectUser } from '@quintoandar/bioma-auth/containers/selectors';
import { hasAuthCookie } from '@quintoandar/wat-cookie';

import ErrorBoundary from 'components/ErrorBoundary/Loadable';
import messages from 'containers/App/messages';
import saga from 'containers/App/saga';
import { makeSelectApp } from 'containers/App/selectors';
import Head from 'containers/App/Head';
import Instana from 'containers/Instana';
import injectSaga from 'utils/injectSaga';
import { immutableToImmer } from 'utils';

import LoadableContent from './LoadableContent';
import SharedContent from './SharedContent';
import { isUserDataLoaded } from './helpers';
import { PAGES_THAT_CAN_LOAD_ASYNC, USERS_ME_URL } from './constants';
export class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasDataLoaded: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { app, user, location } = nextProps;

    const hasDataLoaded = isUserDataLoaded(app, user, location);

    return {
      hasDataLoaded,
    };
  }

  async componentDidMount() {
    const { handleRefreshProfile } = this.props;

    if (hasAuthCookie()) {
      handleRefreshProfile(USERS_ME_URL);
    }
  }

  render() {
    const { app, children, location, user } = this.props;

    const sentryExtraData = { app, location, user };
    const currentRoute = location.pathname;
    const { hasDataLoaded } = this.state;

    const shouldDisplayLoader =
      !hasDataLoaded && !PAGES_THAT_CAN_LOAD_ASYNC.includes(currentRoute);

    const fallBackErrorPage = (
      <ErrorPage
        description={messages.errorDescription}
        header={messages.errorHeader}
        image={ERROR_PAGE_IMAGE_URL}
      />
    );

    return (
      <ErrorBoundary
        currentState={sentryExtraData}
        fallbackUI={fallBackErrorPage}
      >
        <LoadableContent isLoading={shouldDisplayLoader}>
          {React.Children.toArray(children)}
          <Head />
          <SharedContent />
          <Instana />
        </LoadableContent>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  app: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  handleRefreshProfile: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  app: makeSelectApp(),
  user: immutableToImmer(makeSelectUser()),
});

export const mapDispatchToProps = (dispatch) => ({
  handleRefreshProfile: (endpoint) => dispatch(refreshProfile(endpoint)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'app', saga });

export default compose(withConnect, withSaga)(App);
