import auth from '@quintoandar/firebase-client/auth';
import getFirebaseApp from '@quintoandar/firebase-client/client';
import { initFirestore } from '@quintoandar/firebase-client/firestore';

import { handleAuthStateChanged } from './auth';
import { FIREBASE_CONFIG } from './constants';

let firestoreInstance;
let unsubscribe;

const disconnect = () => {
  if (unsubscribe) {
    unsubscribe();
  }
};

const setupFirebaseAndServices = () => {
  getFirebaseApp(FIREBASE_CONFIG);

  initFirestore().then((firestore) => {
    firestoreInstance = firestore;
  });

  disconnect();

  unsubscribe = auth().onAuthStateChanged(handleAuthStateChanged);
};

const getFirestoreInstance = () => firestoreInstance;

export { setupFirebaseAndServices, disconnect, getFirestoreInstance };
