import VISIT_STATUS, { VISIT_STATUS_V3 } from 'enums/visitStatus';

export const mapStatusFromV3 = (status) => {
  switch (status) {
    case VISIT_STATUS_V3.DONE:
      return VISIT_STATUS.DONE;
    case VISIT_STATUS_V3.CANCELED:
      return VISIT_STATUS.CANCELED;
    case VISIT_STATUS_V3.CONFIRMED:
      return VISIT_STATUS.SCHEDULED;
    case VISIT_STATUS_V3.REQUEST_CANCELED:
      return VISIT_STATUS.REQUEST_CANCELED;
    case VISIT_STATUS_V3.UNSUCCESSFUL:
      return VISIT_STATUS.UNSUCCESSFUL;
    case VISIT_STATUS_V3.STALLED:
      return VISIT_STATUS_V3.STALLED;
    case VISIT_STATUS.REQUESTED:
    default:
      return VISIT_STATUS.WAITING_CONFIRMATION;
  }
};
