import { handle } from 'redux-pack';
import {
  FINISH_SCHEDULE,
  FINISH_REGISTER,
  NEW_VISIT_SCHEDULED_ANALYTICS,
  NEW_VISIT_NO_KEYS_VIEWED,
  NEW_VISIT_URL_COPIED_ANALYTICS,
  NEW_VISIT_REGISTER_DATE_YESTERDAY_ANALYTICS,
} from 'containers/Agenda/NewVisitReschedule/NewVisit/types';
import { OPEN_NEW_VISIT_DIALOG } from 'containers/Agenda/types';
import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleScheduleVisitAction = (
  eventDispatcherHandler,
  action,
  state,
) => {
  switch (action.type) {
    case OPEN_NEW_VISIT_DIALOG:
      eventDispatcherHandler.logWithDefaultEventProps(
        'new_visit_page_viewed',
        immutablePage(PAGE_NAMES.NEW_VISIT),
      );
      break;

    case FINISH_REGISTER:
      eventDispatcherHandler.logWithDefaultEventProps(
        'confirm_new_visit_register',
        immutablePage(PAGE_NAMES.NEW_VISIT),
      );
      break;

    case FINISH_SCHEDULE:
      handle(state, action, {
        success: (prevState) => {
          const businessContext = prevState.getIn([
            'newVisit',
            'businessContext',
          ]);
          const extraProps = { businessContext };

          eventDispatcherHandler.logWithDefaultEventProps(
            'confirm_new_visit_schedule',
            immutablePage(PAGE_NAMES.NEW_VISIT),
            extraProps,
          );
          return prevState;
        },
      });
      break;

    case NEW_VISIT_NO_KEYS_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'new_visit_no_keys_viewed',
        immutablePage(PAGE_NAMES.NEW_VISIT),
        { imovel_id: action.meta?.houseId },
      );
      break;

    case NEW_VISIT_URL_COPIED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'new_visit_url_copied',
        immutablePage(PAGE_NAMES.NEW_VISIT),
      );
      break;

    case NEW_VISIT_REGISTER_DATE_YESTERDAY_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'new_visit_register_date_yesterday',
        immutablePage(PAGE_NAMES.NEW_VISIT),
      );
      break;
    case NEW_VISIT_SCHEDULED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'new_visit_schedule_time',
        immutablePage(PAGE_NAMES.NEW_VISIT),
        action.extraProperties,
      );
      break;

    default:
      break;
  }
};

export default handleScheduleVisitAction;
