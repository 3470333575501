import { HTTP_METHODS } from 'config';
import agentsApiConfig from 'api/agents';
import { OFFER_SEND_PROPOSAL_LINK } from 'api/agents/endpoints';

/**
 * Send the proposal link to the visit
 * @param {{ houseId: number, visitorId: number }} payload object
 */
export const sendOfferLink = ({ payload }) =>
  agentsApiConfig({
    endpoint: OFFER_SEND_PROPOSAL_LINK,
    method: HTTP_METHODS.POST,
    payload,
  });
