import styled from 'styled-components';

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1110;
`;

export { LoadingWrapper };
