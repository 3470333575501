import { defineMessages } from 'react-intl';

export default defineMessages({
  resendProposalLinkSuccess: {
    id: 'app.containers.App.resendProposalLinkSuccess',
    defaultMessage: 'Link enviado por e-mail',
  },
  resendProposalLinkError: {
    id: 'app.containers.App.resendProposalLinkError',
    defaultMessage: 'Erro ao enviar link',
  },
  errorHeader: {
    id: 'app.components.ErrorBoundary.ErrorPage.errorHeader',
    defaultMessage: 'Oops, ocorreu um erro',
  },
  errorDescription: {
    id: 'app.components.ErrorBoundary.ErrorPage.errorDescription',
    defaultMessage: 'Entre em contato conosco via chat',
  },
});
