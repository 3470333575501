import { FETCH_HOUSE_INFO } from 'containers/Agenda/NewVisitReschedule/NewVisit/types';
import { captureException } from '@quintoandar/fwp-error-monitoring';
import { getRelistingEnabledFlag } from 'utils/useFeatureFlag';

export const featureFlagMiddleware = () => (next) => async (action) => {
  const result = next(action);

  if (action?.type !== FETCH_HOUSE_INFO) {
    return result;
  }

  try {
    const isRelistingFlagEnabled = await getRelistingEnabledFlag();
    return next({
      ...action,
      isRelistingFlagEnabled,
    });
  } catch (ex) {
    captureException(ex);
  }

  return result;
};

export default featureFlagMiddleware;
