export const ICON_LINKS = {
  'pt-BR': [
    {
      rel: 'apple-touch-icon',
      href: 'https://cozy-assets.quintoandar.com.br/logo/v16/launcher-icons/ic_launcher_ios_120x120.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: 'https://cozy-assets.quintoandar.com.br/logo/v16/launcher-icons/ic_launcher_ios_152x152.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '167x167',
      href: 'https://cozy-assets.quintoandar.com.br/logo/v16/launcher-icons/ic_launcher_ios_167x167.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: 'https://cozy-assets.quintoandar.com.br/logo/v16/launcher-icons/ic_launcher_ios_180x180.png',
    },
    {
      rel: 'icon',
      href: 'https://cozy-assets.quintoandar.com.br/cozy-static/v1/latest/favicon/favicon.ico',
    },
  ],
  'es-MX': [
    {
      rel: 'apple-touch-icon',
      href: 'https://cozy-assets.quintoandar.com.br/logo/v16/launcher-icons/ic_launcher_ios_120x120.es-MX.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: 'https://cozy-assets.quintoandar.com.br/logo/v16/launcher-icons/ic_launcher_ios_152x152.es-MX.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '167x167',
      href: 'https://cozy-assets.quintoandar.com.br/logo/v16/launcher-icons/ic_launcher_ios_167x167.es-MX.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: 'https://cozy-assets.quintoandar.com.br/logo/v16/launcher-icons/ic_launcher_ios_180x180.es-MX.png',
    },
    {
      rel: 'icon',
      href: 'https://cozy-assets.quintoandar.com.br/cozy-static/v1/latest/favicon/favicon.es-MX.ico',
    },
  ],
};
