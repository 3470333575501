import { detectLocale } from '@quintoandar/js-intl';
import { parseCookiesToUser } from '@quintoandar/wat-cookie';

export const getLoggedUser = () => {
  let locale = '';
  let country = '';

  if (typeof window !== 'undefined') {
    locale = detectLocale();
    country = locale.split('-').pop() || '';
  }

  const userFromCookie = parseCookiesToUser();

  if (userFromCookie) {
    return {
      login_status: true,
      user_id: userFromCookie.id,
      user_email: userFromCookie.email,
      phone_number: userFromCookie.telefone,
      first_name: userFromCookie.firstname,
      last_name:
        userFromCookie.name &&
        userFromCookie.name.split(' ').splice(1).join(' '),
      locale,
      country,
    };
  }

  return {
    login_status: false,
    locale,
    country,
  };
};
