import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultButtonLabel: {
    id: 'app.components.MessageDialog.defaultButtonLabel',
    defaultMessage: 'Ok, entendi',
  },
  defaultTitle: {
    id: 'app.components.MessageDialog.defaultTitle',
    defaultMessage: 'Erro',
  },
  defaultBody: {
    id: 'app.components.MessageDialog.defaultBody',
    defaultMessage: 'Por favor, tente novamente mais tarde.',
  },
});
