import styled from 'styled-components';
import Link from 'react-router/lib/Link';
import tokens from '@quintoandar/cozy-theme/tokens/js';

export const FullLink = styled(Link)`
  width: 100%;
  height: 100%;
  padding: '${tokens.alias.spacing.xxsmall}  0px';
  color: ${({ color }) => color || 'inherit'};
  text-decoration: none;
  align-self: flex-end;
  &:hover {
    color: ${tokens.alias.color.surface.interaction.primary.hovered};
    transition: all 0.4s ease;
  }
`;

export default FullLink;
