export const ONLINE_SUPPORT_BY_TYPE_TTA = 'TTA';
export const ONLINE_SUPPORT_CLIENTS_ORDER_BY = Object.freeze({
  NEW_CLIENTS: 'latest-tta',
  OLD_CLIENTS: 'older-tta',
  NAME: 'client-name',
});
export const PENDING_SUPPORT_CLIENTS_PER_PAGE_QUERY = 100;
export const STARTED_SUPPORT_CLIENTS_PER_PAGE_QUERY = 10;
export const ONLINE_SUPPORT_STATUS_QUERY = Object.freeze({
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING: 'PENDING',
});

export const FETCH_PENDING_SUPPORT_CLIENTS =
  'app/containers/OnlineSupport/FETCH_PENDING_SUPPORT_CLIENTS';
export const FETCH_STARTED_SUPPORT_CLIENTS =
  'app/containers/OnlineSupport/FETCH_STARTED_SUPPORT_CLIENTS';
export const FETCH_STARTED_SUPPORT_CLIENTS_ORDERED_BY =
  'app/containers/OnlineSupport/FETCH_STARTED_SUPPORT_CLIENTS_ORDERED_BY';

export const TTA_CLIENTS_EXPAND_CARD_CLICKED =
  'app/containers/OnlineSupport/TTA_CLIENTS_EXPAND_CARD_CLICKED';
export const TTA_CLIENTS_NEXT_PAGE_CLICKED =
  'app/containers/OnlineSupport/TTA_CLIENTS_NEXT_PAGE_CLICKED';
export const TTA_CLIENTS_PAGE_VIEWED =
  'app/containers/OnlineSupport/TTA_CLIENTS_PAGE_VIEWED';
export const TTA_CLIENTS_SEND_MESSAGE_LINK_CLICKED =
  'app/containers/OnlineSupport/TTA_CLIENTS_SEND_MESSAGE_LINK_CLICKED';
export const TTA_CLIENTS_SORT_BY_ALPHABETICAL_ORDER_CLICKED =
  'app/containers/OnlineSupport/TTA_CLIENTS_SORT_BY_ALPHABETICAL_ORDER_CLICKED';
export const TTA_CLIENTS_SORT_BY_RECENT_CLIENTS_CLICKED =
  'app/containers/OnlineSupport/TTA_CLIENTS_SORT_BY_RECENT_CLIENTS_CLICKED';
export const TTA_CLIENTS_SORTING_LINK_CLICKED =
  'app/containers/OnlineSupport/TTA_CLIENTS_SORTING_LINK_CLICKED';
export const TTA_CLIENTS_VIEW_PROFILE_LINK_CLICKED =
  'app/containers/OnlineSupport/TTA_CLIENTS_VIEW_PROFILE_LINK_CLICKED';
export const TTA_START_SERVICE_CLICKED =
  'app/containers/OnlineSupport/TTA_START_SERVICE_CLICKED';
