import { browserHistory } from 'react-router';

let configStore;

/* istanbul ignore next */
if (process.env.NODE_ENV === 'production') {
  configStore = require('./configureStore.prod').default; // eslint-disable-line global-require
} else if (process.env.NODE_ENV === 'test') {
  configStore = require('./configureStore.testing').default; // eslint-disable-line global-require
} else {
  configStore = require('./configureStore.dev').default; // eslint-disable-line global-require
}

const store = configStore({}, browserHistory);

export default store;
