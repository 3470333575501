/* eslint-disable no-case-declarations */
import { LOCATION_CHANGE } from 'react-router-redux';
import { REFRESH_PROFILE } from '@quintoandar/bioma-auth/containers/constants';

import { fetchHasUnreadMessages } from 'containers/App/actions';
import { makeSelectUser } from '@quintoandar/bioma-auth/containers/selectors';
import { immutableToImmer } from 'utils';

const getUnreadMessagesMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  const user = immutableToImmer(makeSelectUser())(state);

  switch (action.type) {
    case LOCATION_CHANGE:
      const currentPathname = state.getIn([
        'route',
        'locationBeforeTransitions',
        'pathname',
      ]);
      const { pathname } = action.payload;
      if (user.id && pathname !== currentPathname) {
        store.dispatch(fetchHasUnreadMessages());
      }
      return next(action);
    case REFRESH_PROFILE:
      const { id } = action.payload || {};
      if (id && id !== user.id) {
        store.dispatch(fetchHasUnreadMessages());
      }
      return next(action);
    default:
      return next(action);
  }
};

export default getUnreadMessagesMiddleware;
