import { DEFAULT_LOCALE } from '@quintoandar/js-intl';

export async function polyfillIntl(locale = DEFAULT_LOCALE) {
  switch (locale) {
    case 'es-MX':
      if (!window.Intl) {
        await import('intl');
        await import('intl/locale-data/jsonp/es-MX');
      }
      if (!window.Intl?.PluralRules) {
        await import(
          /* webpackChunkName: "intl-pluralrules" */
          '@formatjs/intl-pluralrules/polyfill'
        );
        await import(
          /* webpackChunkName: "intl-pluralrules-pt" */
          '@formatjs/intl-pluralrules/locale-data/es'
        );
      }
      if (!window.Intl?.RelativeTimeFormat) {
        await import(
          /* webpackChunkName: "intl-relativetimeformat" */
          '@formatjs/intl-relativetimeformat/polyfill'
        );
        await import(
          /* webpackChunkName: "intl-relativetimeformat-pt" */
          '@formatjs/intl-relativetimeformat/locale-data/es'
        );
      }
      break;
    case 'pt-BR':
    default:
      if (!window.Intl) {
        await import('intl');
        await import('intl/locale-data/jsonp/pt-BR');
      }
      if (!window.Intl?.PluralRules) {
        await import(
          /* webpackChunkName: "intl-pluralrules" */
          '@formatjs/intl-pluralrules/polyfill'
        );
        await import(
          /* webpackChunkName: "intl-pluralrules-pt" */
          '@formatjs/intl-pluralrules/locale-data/pt'
        );
      }
      if (!window.Intl?.RelativeTimeFormat) {
        await import(
          /* webpackChunkName: "intl-relativetimeformat" */
          '@formatjs/intl-relativetimeformat/polyfill'
        );
        await import(
          /* webpackChunkName: "intl-relativetimeformat-pt" */
          '@formatjs/intl-relativetimeformat/locale-data/pt'
        );
      }
  }
}
