export const VISIT_TIMES = [
  {
    beginSlot: 0,
    endSlot: 2,
    beginHour: '8:00',
    endHour: '8:30',
  },
  {
    beginSlot: 1,
    endSlot: 3,
    beginHour: '8:15',
    endHour: '8:45',
  },
  {
    beginSlot: 2,
    endSlot: 4,
    beginHour: '8:30',
    endHour: '9:00',
  },
  {
    beginSlot: 3,
    endSlot: 5,
    beginHour: '8:45',
    endHour: '9:15',
  },
  {
    beginSlot: 4,
    endSlot: 6,
    beginHour: '9:00',
    endHour: '9:30',
  },
  {
    beginSlot: 5,
    endSlot: 7,
    beginHour: '9:15',
    endHour: '9:45',
  },
  {
    beginSlot: 6,
    endSlot: 8,
    beginHour: '9:30',
    endHour: '10:00',
  },
  {
    beginSlot: 7,
    endSlot: 9,
    beginHour: '9:45',
    endHour: '10:15',
  },
  {
    beginSlot: 8,
    endSlot: 10,
    beginHour: '10:00',
    endHour: '10:30',
  },
  {
    beginSlot: 9,
    endSlot: 11,
    beginHour: '10:15',
    endHour: '10:45',
  },
  {
    beginSlot: 10,
    endSlot: 12,
    beginHour: '10:30',
    endHour: '11:00',
  },
  {
    beginSlot: 11,
    endSlot: 13,
    beginHour: '10:45',
    endHour: '11:15',
  },
  {
    beginSlot: 12,
    endSlot: 14,
    beginHour: '11:00',
    endHour: '11:30',
  },
  {
    beginSlot: 13,
    endSlot: 15,
    beginHour: '11:15',
    endHour: '11:45',
  },
  {
    beginSlot: 14,
    endSlot: 16,
    beginHour: '11:30',
    endHour: '12:00',
  },
  {
    beginSlot: 15,
    endSlot: 17,
    beginHour: '11:45',
    endHour: '12:15',
  },
  {
    beginSlot: 16,
    endSlot: 18,
    beginHour: '12:00',
    endHour: '12:30',
  },
  {
    beginSlot: 17,
    endSlot: 19,
    beginHour: '12:15',
    endHour: '12:45',
  },
  {
    beginSlot: 18,
    endSlot: 20,
    beginHour: '12:30',
    endHour: '13:00',
  },
  {
    beginSlot: 19,
    endSlot: 21,
    beginHour: '12:45',
    endHour: '13:15',
  },
  {
    beginSlot: 20,
    endSlot: 22,
    beginHour: '13:00',
    endHour: '13:30',
  },
  {
    beginSlot: 21,
    endSlot: 23,
    beginHour: '13:15',
    endHour: '13:45',
  },
  {
    beginSlot: 22,
    endSlot: 24,
    beginHour: '13:30',
    endHour: '14:00',
  },
  {
    beginSlot: 23,
    endSlot: 25,
    beginHour: '13:45',
    endHour: '14:15',
  },
  {
    beginSlot: 24,
    endSlot: 26,
    beginHour: '14:00',
    endHour: '14:30',
  },
  {
    beginSlot: 25,
    endSlot: 27,
    beginHour: '14:15',
    endHour: '14:45',
  },
  {
    beginSlot: 26,
    endSlot: 28,
    beginHour: '14:30',
    endHour: '15:00',
  },
  {
    beginSlot: 27,
    endSlot: 29,
    beginHour: '14:45',
    endHour: '15:15',
  },
  {
    beginSlot: 28,
    endSlot: 30,
    beginHour: '15:00',
    endHour: '15:30',
  },
  {
    beginSlot: 29,
    endSlot: 31,
    beginHour: '15:15',
    endHour: '15:45',
  },
  {
    beginSlot: 30,
    endSlot: 32,
    beginHour: '15:30',
    endHour: '16:00',
  },
  {
    beginSlot: 31,
    endSlot: 33,
    beginHour: '15:45',
    endHour: '16:15',
  },
  {
    beginSlot: 32,
    endSlot: 34,
    beginHour: '16:00',
    endHour: '16:30',
  },
  {
    beginSlot: 33,
    endSlot: 35,
    beginHour: '16:15',
    endHour: '16:45',
  },
  {
    beginSlot: 34,
    endSlot: 36,
    beginHour: '16:30',
    endHour: '17:00',
  },
  {
    beginSlot: 35,
    endSlot: 37,
    beginHour: '16:45',
    endHour: '17:15',
  },
  {
    beginSlot: 36,
    endSlot: 38,
    beginHour: '17:00',
    endHour: '17:30',
  },
  {
    beginSlot: 37,
    endSlot: 39,
    beginHour: '17:15',
    endHour: '17:45',
  },
  {
    beginSlot: 38,
    endSlot: 40,
    beginHour: '17:30',
    endHour: '18:00',
  },
  {
    beginSlot: 39,
    endSlot: 41,
    beginHour: '17:45',
    endHour: '18:15',
  },
  {
    beginSlot: 40,
    endSlot: 42,
    beginHour: '18:00',
    endHour: '18:30',
  },
  {
    beginSlot: 41,
    endSlot: 43,
    beginHour: '18:15',
    endHour: '18:45',
  },
  {
    beginSlot: 42,
    endSlot: 44,
    beginHour: '18:30',
    endHour: '19:00',
  },
  {
    beginSlot: 43,
    endSlot: 45,
    beginHour: '18:45',
    endHour: '19:15',
  },
  {
    beginSlot: 44,
    endSlot: 46,
    beginHour: '19:00',
    endHour: '19:30',
  },
  {
    beginSlot: 45,
    endSlot: 47,
    beginHour: '19:15',
    endHour: '19:45',
  },
  {
    beginSlot: 46,
    endSlot: 48,
    beginHour: '19:30',
    endHour: '20:00',
  },
];
