/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { getTranslationsMessages, DEFAULT_LOCALE } from '@quintoandar/js-intl';
import ptTranslationMessages from './translations/pt-BR.json';

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, ptTranslationMessages)
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

export const translationMessages = {
  pt: formatTranslationMessages('pt-BR', ptTranslationMessages),
};

export const getLibsTranslations = async (locale) => {
  const libsMessages = await getTranslationsMessages(locale, [
    'cozy-core',
    'bioma-auth',
    'bioma-pickers',
    'visit-cancellation-dialog',
    'listing-review-dialog',
  ]).catch(() => ({}));

  return {
    ...libsMessages,
  };
};

export const getMessages = async (locale) => ({
  ...(await getLibsTranslations(locale)),
  ...(await import(`./translations/${locale}.json`)).default,
});

export const isMxLocale = (locale = DEFAULT_LOCALE) => locale === 'es-MX';

export const getCozyThemeName = (locale = DEFAULT_LOCALE) =>
  isMxLocale(locale) ? 'INMUEBLES24' : 'DEFAULT';

export const getCozyThemeClassName = (locale = DEFAULT_LOCALE) =>
  isMxLocale(locale) ? 'cozy__theme--inmuebles24' : '';

export const getAssetsProductName = (locale = DEFAULT_LOCALE) =>
  isMxLocale(locale) ? 'inmuebles24' : 'QuintoAndar';
