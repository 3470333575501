/**
 * Asynchronously loads the ClientsCrmContainer
 */
import Loadable from 'react-loadable';

export default Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ClientsCrmContainer" */ './ClientsCrmContainer'
    ),
  loading: () => null,
});
