export const DAYS_TO_BLOCK = Object.freeze({
  ONE_DAY: 'ONE_DAY',
  MANY_DAYS: 'MANY_DAYS',
});

export const BLOCK_TYPES = Object.freeze({
  FIXED: 'FIXED_BLOCK',
  TEMPORARY: 'TEMPORARY_BLOCK',
});

export const STATES = Object.freeze({
  AGENDA_SUSPENDED: 'agendaSuspended',
  EMPTY: 'empty',
  ERROR: 'error',
  HAS_BLOCKED_TIME: 'hasBlockedTime',
  NOT_DEFINED: 'notDefined',
});

export const AGENDA_SUSPENDED_CONTRACT_NAME = 'Agenda Bloqueada';

export const WEEKDAYS = Object.freeze({
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
});

export const WEEKEND = Object.freeze({
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
});

export const DAYS_OF_THE_WEEK = Object.freeze({
  ...WEEKDAYS,
  ...WEEKEND,
});

export const ACTION_TYPES = Object.freeze({
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  EDIT_BY_CREATE: 'EDIT_BY_CREATE',
});

export const FIXED_BLOCK_ANALYTICS = Object.freeze({
  CREATE: 'NEW_FIXED',
  EDIT: 'EDIT_FIXED',
  DELETE: 'DELETE_FIXED',
});

export const TEMPORARY_BLOCK_ANALYTICS = Object.freeze({
  DELETE: 'DELETE_TEMPORARY',
  CREATE: 'NEW_TEMPORARY',
});

export const EXTRA_SMALL_WIDTH = 370;

export const WEEKDAY_LABEL_MAP = Object.freeze({
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
});
