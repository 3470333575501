import {
  ENTRANCE_PROBLEM as ENTRANCE_PROBLEM_ENUM,
  MISSPLACED_KEYS,
  OWNER_QUIT_ANNOUNCEMENT,
  PROPERTY_NOT_FOR_SALE,
  SOLD_BY_ANOTHER_COMPANY,
} from 'enums/entranceProblem';

export const ENTRANCE_PROBLEM_REASONS_ANCHOR =
  'entrance_problem_reasons_anchor';

export const PROBLEM_WITH_KEYS = 'ProblemWithKeys';

export const ENTRANCE_PROBLEM = Object.freeze({
  WRONG_ENTRANCE_INFORMATION: {
    value: MISSPLACED_KEYS,
    messageId: 'wrongEntranceInformation',
  },
  PROBLEM_WITH_KEYS: {
    value: PROBLEM_WITH_KEYS,
    messageId: 'problemWithKeys',
  },
  NO_ENTRANCE_PERMISSION: ENTRANCE_PROBLEM_ENUM.NO_ENTRANCE_PERMISSION,
  SOLD_BY_ANOTHER_COMPANY: ENTRANCE_PROBLEM_ENUM.SOLD_BY_ANOTHER_COMPANY,
  RENTED_BY_ANOTHER_COMPANY: ENTRANCE_PROBLEM_ENUM.RENTED_BY_ANOTHER_COMPANY,
  PROPERTY_NOT_FOR_SALE: ENTRANCE_PROBLEM_ENUM.PROPERTY_NOT_FOR_SALE,
  PROPERTY_NOR_FOR_RENT: ENTRANCE_PROBLEM_ENUM.PROPERTY_NOR_FOR_RENT,
  OWNER_QUIT_ANNOUNCEMENT: ENTRANCE_PROBLEM_ENUM.OWNER_QUIT_ANNOUNCEMENT,
  VISIT_OFF_ALLOWED_HOURS: ENTRANCE_PROBLEM_ENUM.VISIT_OFF_ALLOWED_HOURS,
  TEMPORARILY_UNAVAILABLE: ENTRANCE_PROBLEM_ENUM.TEMPORARILY_UNAVAILABLE,
  RESIDENT_TENANT_DIDNT_ALLOW_VISIT:
    ENTRANCE_PROBLEM_ENUM.RESIDENT_TENANT_DIDNT_ALLOW_VISIT,
});

export const UNPUBLISH_LISTING_ENTRANCE_PROBLEM = [
  SOLD_BY_ANOTHER_COMPANY,
  OWNER_QUIT_ANNOUNCEMENT,
  PROPERTY_NOT_FOR_SALE,
];
