import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { combineReducers } from 'redux-immutable';

import { captureMessage } from '@quintoandar/fwp-error-monitoring';

import { LOGOUT } from '@quintoandar/bioma-auth/containers/constants';
import loginReducer from '@quintoandar/bioma-auth/containers/reducer';
import captchaReducer from '@quintoandar/bioma-auth/containers/Captcha/withCaptcha/reducer';
import { UPDATE_ROUTE } from 'containers/App/constants';
import appReducer from 'containers/App/reducer';

import filtersReducer from 'containers/Filters/reducer';

import firebaseReducer from 'containers/Firebase/reducer';
import searchReducer from 'containers/Search/reducer';
import progressReducer from 'containers/Progress/reducer';

export const PAGE_NOT_FOUND = 'app/NotFoundPage/PAGE_NOT_FOUND';

/**
 * Log notFound errors to Sentry
 */
function logNotFound(state) {
  captureMessage(`Page ${window.location.href} not found`);

  return state;
}

// Initial routing state
export const routeInitialState = fromJS({
  locationBeforeTransitions: null,
  topLevelRouteName: '',
});

/**
 * Merge route into the global application state
 */
export function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
      });
    case UPDATE_ROUTE:
      return state.merge({
        topLevelRouteName: action.topLevelRouteName,
      });
    case PAGE_NOT_FOUND:
      return logNotFound(state);
    default:
      return state;
  }
}

/**
 * Reducer for intercepting actions and handling them in a global manner
 */
function globalActionsHandlerReducer(state, action, previousReducer) {
  switch (action.type) {
    case LOGOUT:
      return previousReducer(undefined, action);
    default:
      return previousReducer(state, action);
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export function createReducer(injectedReducers) {
  const combinedReducers = combineReducers({
    app: appReducer,
    route: routeReducer,
    progress: progressReducer,
    login: loginReducer,
    captcha: captchaReducer,
    firebase: firebaseReducer,
    filters: filtersReducer,
    search: searchReducer,
    ...injectedReducers,
  });

  return (state, action) =>
    globalActionsHandlerReducer(state, action, combinedReducers);
}
