import { parseCookiesToUser } from '@quintoandar/wat-cookie';
import { getLastName } from '../utils/stringUtils';

const getUserData = () => parseCookiesToUser();

const parseUser = (user) => {
  const parsedUser = {
    login_status: false,
  };

  if (!user) {
    return parsedUser;
  }

  parsedUser.login_status = true;
  parsedUser.user_id = user.id;
  parsedUser.user_email = user.email;
  parsedUser.phone_number = user.telefone;
  parsedUser.first_name = user.firstname;
  parsedUser.last_name = getLastName(user.name);
  return parsedUser;
};

const userFetcher = () => {
  const user = getUserData();
  return parseUser(user);
};

export { userFetcher };
