import ROUTES from 'routes/constants';
import { AGENTS_API_URL } from 'config';

export const AUTH_FLOW_FAILURE = 'agentsPwa/App/AUTH_FLOW_FAILURE';
export const AUTH_FLOW_SUCCESS = 'agentsPwa/App/AUTH_FLOW_SUCCESS';
export const CHECKED_AGENT = 'agentsPwa/App/CHECKED_AGENT';
export const CHECKED_AGENT_FAILURE = 'agentsPwa/App/CHECKED_AGENT_FAILURE';
export const UNAUTHORIZED_AGENT = 'agentsPwa/App/UNAUTHORIZED_AGENT';
export const FETCH_AGENT_DATA_FAILURE =
  'agentsPwa/App/FETCH_AGENT_DATA_FAILURE';
export const FETCH_AGENT_DATA_SUCCESS =
  'agentsPwa/App/FETCH_AGENT_DATA_SUCCESS';
export const FIREBASE_AUTH_STATE_CHANGED =
  'agentsPwa/App/FIREBASE_AUTH_STATE_CHANGED';
export const HIDE_DIALOG = 'agentsPwa/App/HIDE_DIALOG';
export const HIDE_LOADING = 'agentsPwa/App/HIDE_LOADING';
export const HIDE_SNACKBAR = 'agentsPwa/App/HIDE_SNACKBAR';
export const OFFER_LINK_FAILURE = 'agentsPwa/App/OFFER_LINK_FAILURE';
export const OFFER_LINK_REQUEST = 'agentsPwa/App/OFFER_LINK_REQUEST';
export const OFFER_LINK_SUCCESS = 'agentsPwa/App/OFFER_LINK_SUCCESS';
export const SHOW_DIALOG = 'agentsPwa/App/SHOW_DIALOG';
export const SHOW_LOADING = 'agentsPwa/App/SHOW_LOADING';
export const SHOW_SNACKBAR = 'agentsPwa/App/SHOW_SNACKBAR';
export const UPDATE_DIALOG_STATUS = 'agentsPwa/App/UPDATE_DIALOG_STATUS';
export const UPDATE_FIRESTORE_NOTIFICATION_FLAG =
  'agentsPwa/App/UPDATE_FIRESTORE_NOTIFICATION_FLAG';
export const UPDATE_ROUTE = 'agentsPwa/App/UPDATE_ROUTE';
export const HIDE_ERROR_DIALOG = 'agentsPwa/App/HIDE_ERROR_DIALOG';
export const SHOW_ERROR_DIALOG = 'agentsPwa/App/SHOW_ERROR_DIALOG';
export const ASK_FOR_PUSH = 'agentsPwa/App/ASK_FOR_PUSH';
export const FETCH_AGENT_RATING = 'agentsPwa/App/FETCH_AGENT_RATING';
export const FETCH_HAS_UNREAD_MESSAGES =
  'agentsPwa/App/FETCH_HAS_UNREAD_MESSAGES';
export const FETCH_SHOW_AGENT_KEYS = 'agentsPwa/App/FETCH_SHOW_AGENT_KEYS';
export const PAGES_THAT_CAN_LOAD_ASYNC = Object.freeze([
  ROUTES.TIME_BLOCK,
  ROUTES.REDIRECTING,
]);
export const USERS_ME_URL = `${AGENTS_API_URL}/users/me`;
