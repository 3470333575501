import { fetchHouseEntryInformationDetails } from 'api/agents/house';
import { getVisitsByAgentId } from 'api/providers/visitAPI/VisitV3API';
import VISIT_STATUS from 'enums/visitStatus';
import {
  FILTERS_MAP_VISIT_STATUS,
  FILTERS_VISITS_GROUPS,
} from 'containers/Filters/enums';
import {
  COUNT_VISITS_FAILURE,
  COUNT_VISITS_REQUEST,
  COUNT_VISITS_SUCCESS,
  DONE_VISITS_CARD_OPENED_ANALYTICS,
  DONE_VISITS_FILTER_CLICKED_ANALYTICS,
  DONE_VISITS_PAGE_VIEWED_ANALYTICS,
  DONE_VISITS_PENDING_VISITS_TAB_CLICKED_ANALYTICS,
  DONE_VISITS_TAB_CLICKED_ANALYTICS,
  FETCH_VISITS_FAILURE,
  FETCH_VISITS_REQUEST,
  FETCH_VISITS_SUCCESS,
  FETCH_DONE_VISITS,
  FETCH_HOUSE_AUTONOMOUS_AGENT,
  SET_VISIBLE_DONE_VISITS_DIALOG,
  SHOULD_FETCH_VISITS,
  VISIT_HOUSE_FETCH_SUCCESS,
  VISIT_USER_FETCH_SUCCESS,
  DONE_VISITS_CALL_TENANT_CLICKED_ANALYTICS,
  DONE_VISITS_CHAT_WITH_TENANT_CLICKED_ANALYTICS,
  DONE_VISITS_SEND_OFFER_ANALYTICS,
  DONE_VISITS_CHAT_WITH_OWNER_CLICKED_ANALYTICS,
  DONE_VISITS_FINISH_VISIT_CLICKED_ANALYTICS,
  FETCH_VISITS_OFFER_LABEL_REQUEST,
  FETCH_VISITS_OFFER_LABEL_SUCCESS,
} from './constants';

export const countVisitsRequest = ({ filters, businessContext = '' }) => ({
  type: COUNT_VISITS_REQUEST,
  payload: {
    filters,
    businessContext,
  },
});

export const countVisitsFailure = (message) => ({
  type: COUNT_VISITS_FAILURE,
  payload: {
    message,
  },
});

export const countVisitsSuccess = (count) => ({
  type: COUNT_VISITS_SUCCESS,
  payload: {
    count,
  },
});

export const fetchVisitsRequest = ({
  userId,
  agentId,
  filters,
  lastItem,
  businessContext,
}) => ({
  type: FETCH_VISITS_REQUEST,
  payload: {
    userId,
    agentId,
    filters,
    lastItem,
    businessContext,
  },
});

export const fetchVisitsOfferLabelRequest = ({
  agentId,
  filters,
  lastItem,
  businessContext,
}) => ({
  type: FETCH_VISITS_OFFER_LABEL_REQUEST,
  payload: {
    agentId,
    filters,
    lastItem,
    businessContext,
  },
});

export const fetchVisitsOfferLabelSuccess = ({ data }) => ({
  type: FETCH_VISITS_OFFER_LABEL_SUCCESS,
  payload: {
    data,
  },
});

export const fetchDoneVisits = ({
  filters,
  agentId,
  businessContext,
  currentPage,
  totalItems,
}) => {
  const sortedBy = `DATE_${filters[FILTERS_VISITS_GROUPS.SORTING]?.selected}`;

  const filterStatusSelected = filters[FILTERS_VISITS_GROUPS.STATUS].selected;

  const hasFilterByStatus =
    filters.hasFiltersApplied &&
    filterStatusSelected !== FILTERS_MAP_VISIT_STATUS.ALL.name;

  return {
    type: FETCH_DONE_VISITS,
    promise: getVisitsByAgentId(
      businessContext,
      currentPage,
      hasFilterByStatus ? totalItems : 10,
      [
        'ACTOR_DETAILS',
        'HOUSE_DETAILS',
        'STRUCTURED_STATUS',
        'FOLLOW_UP',
        'TEMP_MIGRATION_SUPPORT',
      ],
      {
        status: VISIT_STATUS.DONE,
        sortedBy,
        startDate: filters.period.start.selected,
        endDate: filters.period.end.selected,
      },
    ),
    meta: {
      filters,
      agentId,
    },
  };
};

export const fetchHouseAutonomousAgent = ({
  agentId,
  houseId,
  businessContext,
}) => {
  const fields = ['type', 'ciq', 'listings'];
  return {
    type: FETCH_HOUSE_AUTONOMOUS_AGENT,
    promise: fetchHouseEntryInformationDetails({ agentId, houseId, fields }),
    meta: {
      houseId,
      businessContext,
    },
  };
};

export const fetchVisitsFailure = (message) => ({
  type: FETCH_VISITS_FAILURE,
  payload: {
    message,
  },
});

export const fetchVisitsSuccess = ({ data, agentId, businessContext }) => ({
  type: FETCH_VISITS_SUCCESS,
  payload: {
    data,
    agentId,
    businessContext,
  },
});

export const setVisibleDoneVisitsDialog = (payload) => ({
  type: SET_VISIBLE_DONE_VISITS_DIALOG,
  payload,
});

export const shouldFetchVisits = (status) => ({
  type: SHOULD_FETCH_VISITS,
  payload: {
    status,
  },
});

export const visitHouseFetchSuccess = (payload) => ({
  type: VISIT_HOUSE_FETCH_SUCCESS,
  payload,
});

export const visitUserFetchSuccess = (payload) => ({
  type: VISIT_USER_FETCH_SUCCESS,
  payload,
});

export const triggerDoneVisitsPageViewed = () => ({
  type: DONE_VISITS_PAGE_VIEWED_ANALYTICS,
});

export const triggerDoneVisitsFilterClicked = () => ({
  type: DONE_VISITS_FILTER_CLICKED_ANALYTICS,
});

export const triggerDoneVisitsCardOpened = () => ({
  type: DONE_VISITS_CARD_OPENED_ANALYTICS,
});

export const doneVisitsPhoneClickedAnalytics = ({
  agentId,
  visitorId,
  channel,
}) => ({
  type: DONE_VISITS_CALL_TENANT_CLICKED_ANALYTICS,
  extraProperties: {
    agent_id: agentId,
    visitor_id: visitorId,
    channel,
  },
});

export const doneVisitsChatWithTenantClickedAnalytics = ({
  agentId,
  visitorId,
  channel,
}) => ({
  type: DONE_VISITS_CHAT_WITH_TENANT_CLICKED_ANALYTICS,
  extraProperties: {
    agent_id: agentId,
    visitor_id: visitorId,
    channel,
  },
});

export const doneVisitsSendOfferClickedAnalytics = ({
  agentId,
  visitorId,
}) => ({
  type: DONE_VISITS_SEND_OFFER_ANALYTICS,
  extraProperties: {
    agent_id: agentId,
    visitor_id: visitorId,
  },
});

export const doneVisitsChatWithOwnerClicked = () => ({
  type: DONE_VISITS_CHAT_WITH_OWNER_CLICKED_ANALYTICS,
});

export const doneVisitsFinishVisitClicked = () => ({
  type: DONE_VISITS_FINISH_VISIT_CLICKED_ANALYTICS,
});

export const doneVisitsPendingTabClicked = () => ({
  type: DONE_VISITS_PENDING_VISITS_TAB_CLICKED_ANALYTICS,
});

export const doneVisitsTabClicked = () => ({
  type: DONE_VISITS_TAB_CLICKED_ANALYTICS,
});
