import BUSINESS_CONTEXTS from 'enums/businessContexts';

export function agentWorksWithSales(agentBusinessContexts) {
  return agentBusinessContexts.includes(BUSINESS_CONTEXTS.FOR_SALE);
}

export function agentWorksWithRent(agentBusinessContexts) {
  return agentBusinessContexts.includes(BUSINESS_CONTEXTS.FOR_RENT);
}

export function isAgentAllowedForHouse(house, businessContexts) {
  const forRentAllowed = house.forRent && agentWorksWithRent(businessContexts);
  const forSaleAllowed = house.forSale && agentWorksWithSales(businessContexts);
  return forRentAllowed || forSaleAllowed;
}
