import { defineMessages } from 'react-intl';

export default defineMessages({
  houseType: {
    id: 'app.houseType',
    defaultMessage: 'Casa',
  },
  apartmentType: {
    id: 'app.apartmentType',
    defaultMessage: 'Apartamento',
  },
  condoHouseType: {
    id: 'app.condoHouseType',
    defaultMessage: 'Casa de condomínio',
  },
  studioType: {
    id: 'app.studioType',
    defaultMessage: 'Studio',
  },
  numberValue: {
    id: 'app.numberValue',
    defaultMessage: '{amount}',
  },
  included: {
    id: 'app.included',
    defaultMessage: 'Incluso',
  },
  notInformed: {
    id: 'app.notInformed',
    defaultMessage: 'Sem info',
  },
  noMoney: {
    id: 'app.noMoney',
    defaultMessage: '0',
  },
});
