import styled from 'styled-components';
import Dimens from 'utils/dimens';

import ListItem from '@quintoandar/cozy-core/List/ListItem';

export default styled(ListItem)`
  padding: ${Dimens.noMargin} ${Dimens.defaultSideSpacing};
  margin: ${Dimens.defaultHalfSpacing} auto;
  height: ${Dimens.defaultDoubleSpacing};
`;
