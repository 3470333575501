export const ENTRANCE_PROBLEMS_REASON =
  'agentsPwa/FollowUp/ENTRANCE_PROBLEMS_REASON';
export const ENTRANCE_PROBLEMS_REASON_SELECTED =
  'agentsPwa/FollowUp/ENTRANCE_PROBLEMS_REASON_SELECTED';
export const ENTRANCE_PROBLEMS_ACCESS_AUTHORIZATION_TYPE =
  'agentsPwa/FollowUp/ENTRANCE_PROBLEMS_ACCESS_AUTHORIZATION_TYPE';
export const FETCH_MULTIPLE_HOUSES_INFO =
  'agentsPwa/FollowUp/FETCH_MULTIPLE_HOUSES_INFO';
export const FETCH_HOUSE_AGENT_INFO =
  'agentsPwa/FollowUp/FETCH_HOUSE_AGENT_INFO';
export const FETCH_USERS_NAMES = 'agentsPwa/FollowUp/FETCH_USERS_NAMES';
export const FINISH_FOLLOW_UP = 'agentsPwa/FollowUp/FINISH_FOLLOW_UP';
export const SET_AGENT_HAS_KEY = 'agentsPwa/FollowUp/SET_AGENT_HAS_KEY';
export const SET_HAS_AGENT_ATTENDED =
  'agentsPwa/FollowUp/SET_HAS_AGENT_ATTENDED';
export const SET_HAS_OWNER_ATTENDED =
  'agentsPwa/FollowUp/SET_HAS_OWNER_ATTENDED';
export const SET_HAS_ENTRANCE_PROBLEM =
  'agentsPwa/FollowUp/SET_HAS_ENTRANCE_PROBLEM';
export const SET_VISIT_TYPE = 'agentsPwa/FollowUp/SET_VISIT_TYPE';
export const SET_VISIT_COMMENT = 'agentsPwa/FollowUp/SET_VISIT_COMMENT';
export const SET_VISITOR_IS_CHECKED =
  'agentsPwa/FollowUp/SET_VISITOR_IS_CHECKED';
export const SET_OWNER_FEEDBACK = 'agentsPwa/FollowUp/SET_OWNER_FEEDBACK';
export const SEND_OWNER_FEEDBACK = 'agentsPwa/FollowUp/SEND_OWNER_FEEDBACK';
export const FETCH_OWNER_INFO = 'agentsPwa/FollowUp/FETCH_OWNER_INFO';
export const SET_HAS_OTHER_HOUSE_KEY =
  'agentsPwa/FollowUp/SET_HAS_OTHER_HOUSE_KEY';
export const SET_AGENT_HAS_OTHER_HOUSES_KEYS =
  'agentsPwa/FollowUp/SET_AGENT_HAS_OTHER_HOUSES_KEYS';
export const SET_ALL_OTHER_HOUSES_CHECKED =
  'agentsPwa/FollowUp/SET_ALL_OTHER_HOUSES_CHECKED';
export const SHOW_KEY_HOLDER_INFO = 'agentsPwa/FollowUp/SHOW_KEY_HOLDER_INFO';
export const FETCH_EXTRA_HOUSE_DETAILS =
  'agentsPwa/FollowUp/FETCH_EXTRA_HOUSE_DETAILS';
