/**
 * Enum of possible status during the negotiation
 * This should be used in place of every hard-coded value. e.g. (STATUS_COLORS, STATUS_ICONS)
 * @type {Readonly<{WAITING: string, WAITING5A: string, COMPLETED: string, CANCELLED: string, TERMINATED: string}>}
 */
export const NEGOTIATION_STATUS = Object.freeze({
  WAITING: 'waiting',
  WAITING5A: 'waiting5a',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  TERMINATED: 'terminated',
});

export const STATUS_COLORS = Object.freeze({
  [NEGOTIATION_STATUS.WAITING]: 'warning',
  [NEGOTIATION_STATUS.WAITING5A]: 'warning',
  [NEGOTIATION_STATUS.COMPLETED]: 'positive',
  [NEGOTIATION_STATUS.TERMINATED]: 'critical',
  [NEGOTIATION_STATUS.CANCELLED]: 'critical',
});
