import { handle } from 'redux-pack';

import { FIREBASE_AUTH_STATUS } from '../../api/firebase/constants';
import { initialState } from './reducer';

export const handleFetchAgentData = (state, payload) => {
  const { agentData, ...rest } = payload;
  if (agentData) {
    return state
      .mergeIn(['agentData'], agentData)
      .merge({ hasError: false, ...rest })
      .set('hasLoadedRating', agentData.rating !== undefined);
  }

  return state
    .mergeIn(['agentData'], initialState.get('agentData'))
    .set('refreshedProfile', false)
    .set('hasError', false);
};

export const handleFirebaseAuthStatus = (state, payload) => {
  if (!payload) {
    return state.set('firebaseAuthStatus', FIREBASE_AUTH_STATUS.NOT_LOGGED);
  }
  const status = payload.isAnonymous
    ? FIREBASE_AUTH_STATUS.LOGGED_ANONYMOUSLY
    : FIREBASE_AUTH_STATUS.LOGGED;
  return state.set('firebaseAuthStatus', status);
};

export const handleShowErrorDialog = (state, payload) => {
  const { title, body } = payload;

  return state.mergeIn(['errorDialog'], {
    isOpen: true,
    title,
    body,
  });
};

export const handleFetchHasUnreadMessages = (state, action) => {
  const { payload } = action;

  return handle(state, action, {
    success: (prevState) =>
      prevState.set('hasUnreadMessages', payload.hasUnreadMessage || false),
    failure: (prevState) => prevState.set('hasError', true),
  });
};

export const handleFetchShowAgentKeys = (state, action) => {
  const { payload } = action;

  return handle(state, action, {
    success: (prevState) =>
      prevState.set('showAgentKeys', payload.showHouseAgent || false),
    failure: (prevState) => prevState.set('showAgentKeys', false),
  });
};
