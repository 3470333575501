export const defaultEventProperties = ({
  extraProperties = {},
  pageName,
  loggedUser,
}) => ({
  current_page: pageName,
  uri: window.location.href,
  referrer: window.document.referrer,
  login_status: loggedUser.login_status,
  user_email: loggedUser.user_email,
  ...extraProperties,
});
