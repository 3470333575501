import { defineMessages } from 'react-intl';

export default defineMessages({
  emptyMessage: {
    id: 'app.containers.Snackbar.emptyMessage',
    defaultMessage: '{whiteSpace}',
    values: {
      whiteSpace: ' ',
    },
  },
  genericError: {
    id: 'app.containers.Snackbar.genericError',
    defaultMessage:
      'Ops, tivemos um erro. Tente novamente e se esse erro continuar, nos conte!',
  },
});
