import { List } from 'immutable';
import { createSelector } from 'reselect';

import {
  addDays,
  dateDiff,
  formatDateToPattern,
  formatDateYearMonthDayPattern,
} from 'utils/date';
import {
  BLOCK_TYPES,
  ACTION_TYPES,
  STATES,
  WEEKDAY_LABEL_MAP,
} from './constants';
import messages from './messages';
import {
  getTimeLabel,
  getDatesRangeDescription,
  getIsMultipleDates,
} from './utils';

export const selectTimeBlock = (state) => state.get('timeBlock');
export const selectHasBlockedTime = (state) =>
  selectTimeBlock(state).get('hasBlockedTime');
export const selectIsSuspendedAgenda = (state) =>
  selectTimeBlock(state).get('isSuspendedAgenda');
export const selectHasError = (state) => selectTimeBlock(state).get('hasError');
export const selectIsSubmitLoading = (state) =>
  selectTimeBlock(state).get('isSubmitLoading');
export const selectHasSubmitError = (state) =>
  selectTimeBlock(state).get('hasSubmitError');
export const selectIsSubmitSuccess = (state) =>
  selectTimeBlock(state).get('isSubmitSuccess');
export const selectTemporaryHoursRange = (state) =>
  selectTimeBlock(state).get('hoursRange');
export const selectTemporaryIsDateIntervalsLoaded = (state) =>
  selectTimeBlock(state).get('isDateIntervalsLoaded');
export const selectTemporaryIsHoursRangeLoaded = (state) =>
  selectTimeBlock(state).get('isHoursRangeLoaded');

export const selectDateIntervals = (state) =>
  selectTimeBlock(state).get('dateIntervals');
export const selectDateMaxInterval = (state) =>
  selectDateIntervals(state).get('maxInterval');
export const selectDateMinInterval = (state) =>
  selectDateIntervals(state).get('minInterval');

export const selectUserInterfaceOptions = (state) =>
  selectTimeBlock(state).get('userInterfaceOptions');
export const selectIsAddBlockTimeDialogOpen = (state) =>
  selectUserInterfaceOptions(state).get('isAddBlockTimeDialogOpen');

export const selectBlockType = (state) =>
  selectUserInterfaceOptions(state).get('blockType');
export const selectBlockStepIndex = (state) =>
  selectUserInterfaceOptions(state).get('blockStepIndex');
export const selectFixedBlockDay = (state) =>
  selectUserInterfaceOptions(state).get('fixedBlockDay');
export const selectTemporaryBlockType = (state) =>
  selectUserInterfaceOptions(state).get('temporaryBlockType');

export const selectTemporaryBlockDay = (state) =>
  selectUserInterfaceOptions(state).get('temporaryBlockDay');
export const selectEditableTimes = (state) =>
  selectUserInterfaceOptions(state).get('editableTimes');
export const selectIsConfirmDialogOpen = (state) =>
  selectUserInterfaceOptions(state).get('isConfirmDialogOpen');
export const selectActionType = (state) =>
  selectUserInterfaceOptions(state).get('actionType');

export const selectTemporaryBlockDates = (state) =>
  selectUserInterfaceOptions(state).get('temporaryBlockDates');
export const selectTemporaryBlockStartDate = (state) =>
  selectTemporaryBlockDates(state).get('startDate');
export const selectTemporaryBlockEndDate = (state) =>
  selectTemporaryBlockDates(state).get('endDate');

export const selectContractHours = (state) =>
  selectTimeBlock(state).get('contractHours');
export const selectContractHoursIsLoaded = (state) =>
  selectContractHours(state).get('isLoaded');
export const selectContractHoursData = (state) =>
  selectContractHours(state).get('data');

export const selectTemporaryBlocks = (state) =>
  selectTimeBlock(state).get('temporary');

export const selectTemporaryIsLoaded = (state) =>
  selectTemporaryBlocks(state).get('isLoaded');
export const selectTemporaryBlocksData = (state) =>
  selectTemporaryBlocks(state).get('data');
export const selectTemporaryBlocksSummary = (state) =>
  selectTemporaryBlocksData(state).get('summary');

export const selectFixedBlocks = (state) => selectTimeBlock(state).get('fixed');
export const selectFixedIsLoaded = (state) =>
  selectFixedBlocks(state).get('isLoaded');
export const selectFixedBlocksData = (state) =>
  selectFixedBlocks(state).get('data');
export const selectFixedBlocksSummary = (state) =>
  selectFixedBlocksData(state).get('summary');
export const selectFixedBlocksDetailed = (state) =>
  selectFixedBlocksData(state).get('detailed');

export const makeSelectTimeBlockState = () =>
  createSelector(
    selectHasBlockedTime,
    selectIsSuspendedAgenda,
    selectTemporaryIsLoaded,
    selectTemporaryIsDateIntervalsLoaded,
    selectTemporaryIsHoursRangeLoaded,
    selectFixedIsLoaded,
    selectContractHoursIsLoaded,
    selectHasError,
    selectIsSubmitLoading,
    (
      hasBlockedTime,
      isSuspendedAgenda,
      isTemporaryLoaded,
      isDateIntervalsLoaded,
      isHoursRangeLoaded,
      isFixedLoaded,
      isContractHoursLoaded,
      hasError,
      isSubmitLoading,
    ) => {
      if (hasError) {
        return STATES.ERROR;
      }

      if (
        !isTemporaryLoaded ||
        !isDateIntervalsLoaded ||
        !isHoursRangeLoaded ||
        !isFixedLoaded ||
        !isContractHoursLoaded ||
        isSubmitLoading
      ) {
        return STATES.NOT_DEFINED;
      }

      if (isSuspendedAgenda) {
        return STATES.AGENDA_SUSPENDED;
      }

      if (hasBlockedTime) {
        return STATES.HAS_BLOCKED_TIME;
      }

      return STATES.EMPTY;
    },
  );

export const makeSelectContractHoursData = () =>
  createSelector(selectContractHoursData, (contractHoursData) =>
    contractHoursData ? contractHoursData.toJS() : {},
  );

export const makeSelectTemporaryBlocksSummary = () =>
  createSelector(selectTemporaryBlocksSummary, (temporarySummary) =>
    temporarySummary ? temporarySummary.toJS() : [],
  );

export const makeSelectFixedBlocksSummary = () =>
  createSelector(selectFixedBlocksSummary, (summary) =>
    summary ? summary.toJS() : {},
  );

export const makeSelectAreDatesFilled = () =>
  createSelector(
    selectTemporaryBlockStartDate,
    selectTemporaryBlockEndDate,
    (startDate, endDate) => Boolean(startDate && endDate),
  );

export const makeSelectFixedBlocksEditable = () =>
  createSelector(selectEditableTimes, (editableTimes) =>
    editableTimes ? editableTimes.toJS() : [],
  );

const isItemBlocked = (item) => item.get('isBlocked') === true;

export const makeSelectIsAllTimesChecked = () =>
  createSelector(selectEditableTimes, (editableTimes) =>
    editableTimes ? editableTimes.every(isItemBlocked) : false,
  );

export const makeSelectIsFixedBlock = () =>
  createSelector(
    selectBlockType,
    (blockType) => blockType === BLOCK_TYPES.FIXED,
  );

export const makeSelectIsEdit = () =>
  createSelector(
    selectActionType,
    (actionType) => actionType === ACTION_TYPES.EDIT,
  );

export const makeSelectIsDelete = () =>
  createSelector(
    selectActionType,
    (actionType) => actionType === ACTION_TYPES.DELETE,
  );

export const makeSelectFixedBlockConfirmDayLabel = () =>
  createSelector(
    selectFixedBlockDay,
    (fixedBlockDay) => messages[fixedBlockDay],
  );

export const makeSelectTemporaryBlockConfirmDayLabel = (intl) =>
  createSelector(
    selectTemporaryBlockStartDate,
    selectTemporaryBlockEndDate,
    (startDate, endDate) => {
      const isMultipleDates = getIsMultipleDates({ startDate, endDate });
      const dateFormat = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
      };

      return getDatesRangeDescription({
        startDate: intl.formatDate(startDate, dateFormat),
        endDate: endDate && intl.formatDate(endDate, dateFormat),
        isMultipleDates,
      });
    },
  );

const formatDetailedToIntervals = (intervalsAccumulator, currentHours) => {
  if (!currentHours.get('isBlocked')) {
    return intervalsAccumulator;
  }

  const lastInterval = intervalsAccumulator.last();
  const isConnectedInterval =
    lastInterval &&
    lastInterval.get('endTime') === currentHours.get('startTime');

  if (intervalsAccumulator.size === 0 || !isConnectedInterval) {
    return intervalsAccumulator.push(currentHours);
  }

  return intervalsAccumulator.mergeIn([-1], {
    endTime: currentHours.get('endTime'),
  });
};

const mapIntervalsToTimeLabel = (interval, intl) => {
  const startTime = interval.get('startTime');
  const endTime = interval.get('endTime');

  return getTimeLabel({ startTime, endTime }, intl);
};

export const makeSelectConfirmTimeLabel = (intl) =>
  createSelector(selectEditableTimes, (editableTimes) => {
    if (editableTimes.size === 0) {
      return [];
    }

    const isEntireDay = editableTimes.every(isItemBlocked);

    if (isEntireDay) {
      return [messages.entireDay];
    }

    return editableTimes
      .reduce(formatDetailedToIntervals, List())
      .map((interval) => mapIntervalsToTimeLabel(interval, intl))
      .toJS();
  });

export const makeSelectConfirmDeleteTemporaryBlockTimeLabel = (intl) =>
  createSelector(
    selectTemporaryBlocksSummary,
    selectTemporaryBlockStartDate,
    selectTemporaryBlockEndDate,
    (temporarySummary, startDate, endDate) => {
      const summary = temporarySummary.find(
        (possibleBlock) =>
          possibleBlock.get('dates').includes(startDate) &&
          possibleBlock.get('dates').includes(endDate),
      );

      if (summary.get('isEntireDay')) {
        return [messages.entireDay];
      }

      return summary
        .get('intervals')
        .map((interval) => mapIntervalsToTimeLabel(interval, intl))
        .toJS();
    },
  );

export const makeSelectUpdateFixedBlockPayload = () =>
  createSelector(
    selectEditableTimes,
    selectFixedBlockDay,
    (editableTimes, fixedBlockDay) => ({
      dayOfTheWeek: fixedBlockDay,
      hours: editableTimes ? editableTimes.toJS() : null,
    }),
  );

const hasAnyHours = (day) =>
  day.get('hours') ? day.get('hours').size !== 0 : false;

export const makeSelectAvailableDaysToBlock = () =>
  createSelector(selectFixedBlocksDetailed, (detailed) =>
    detailed ? detailed.filter(hasAnyHours).keySeq().toJS() : [],
  );

export const makeSelectShouldFetchBlockInfo = () =>
  createSelector(
    selectTemporaryIsLoaded,
    selectFixedIsLoaded,
    selectContractHoursIsLoaded,
    (isTemporaryLoaded, isFixedLoaded, isContractHoursLoaded) =>
      !isTemporaryLoaded && !isFixedLoaded && !isContractHoursLoaded,
  );

export const makeSelectIsNextButtonDisabled = () =>
  createSelector(
    selectBlockStepIndex,
    selectBlockType,
    selectEditableTimes,
    (stepIndex, blockType, editableTimes) => {
      if (!blockType && stepIndex === 0) {
        return true;
      }

      if (stepIndex === 1) {
        return editableTimes ? !editableTimes.some(isItemBlocked) : true;
      }

      return false;
    },
  );

export const makeSelectIsBackButtonDisabled = () =>
  createSelector(
    selectBlockStepIndex,
    selectActionType,
    (stepIndex, actionType) => {
      const isEdit = actionType === ACTION_TYPES.EDIT;
      const isFirstStep = stepIndex === 0;

      return isFirstStep || isEdit;
    },
  );

export const makeSelectDeleteTemporaryBlockPayload = () =>
  createSelector(
    selectTemporaryBlockStartDate,
    selectTemporaryBlockEndDate,
    (startDate, endDate) => ({ startDate, endDate }),
  );

export const makeSelectCreateTemporaryBlockPayload = () =>
  createSelector(
    selectTemporaryBlockStartDate,
    selectTemporaryBlockEndDate,
    selectEditableTimes,
    (startDate, endDate, editableTimes) => {
      const isEntireDay = editableTimes.every(isItemBlocked);

      return {
        startDate,
        endDate: endDate || startDate,
        isEntireDay,
        hours: editableTimes.toJS(),
      };
    },
  );

export const makeSelectFixedBlocksInRange = () =>
  createSelector(
    selectTemporaryBlockStartDate,
    selectTemporaryBlockEndDate,
    makeSelectFixedBlocksSummary(),
    (startDate, endDate, fixedBlocks) => {
      if (!startDate) {
        return [];
      }

      if (!endDate || startDate === endDate) {
        const weekday = Number(formatDateToPattern(startDate, 'e'));
        const label = WEEKDAY_LABEL_MAP[weekday];

        return [
          {
            ...fixedBlocks[label],
            weekday: (weekday + 6) % 7,
            label: messages[label],
          },
        ];
      }

      const diff = dateDiff(startDate, endDate, 'days');
      const blocks = {};

      for (let i = 0; i <= diff; i += 1) {
        const weekday = addDays(i, startDate).day();
        const label = WEEKDAY_LABEL_MAP[weekday];
        const hasIntervals =
          fixedBlocks[label] && fixedBlocks[label].intervals.length > 0;

        if (!(label in blocks) && hasIntervals) {
          blocks[label] = {
            ...fixedBlocks[label],
            weekday: (weekday + 6) % 7,
            label: messages[label],
          };
        }
      }

      return Object.values(blocks).sort((a, b) => a.weekday - b.weekday);
    },
  );

export const makeSelectHasFixedBlocksInRange = () =>
  createSelector(makeSelectFixedBlocksInRange(), (blocks) => {
    if (Object.keys(blocks).length === 0) {
      return false;
    }

    let hasFixedBlocksInRange = false;

    Object.values(blocks).forEach((block) => {
      if (block.isEntireDay || block.intervals.length > 0) {
        hasFixedBlocksInRange = true;
      }
    });

    return hasFixedBlocksInRange;
  });

export const makeSelectTemporaryBlockByDate = (date) =>
  createSelector(selectTemporaryBlocksSummary, (blockedSchedules) => {
    const formattedDate = formatDateYearMonthDayPattern(date);

    const currentDateBlock =
      blockedSchedules &&
      blockedSchedules.find((block) =>
        block.get('dates').includes(formattedDate),
      );

    return currentDateBlock?.toJS() || {};
  });

export const makeSelectFixedBlockByDate = (date) =>
  createSelector(selectFixedBlocksSummary, (blockedSchedules) => {
    const weekday = Number(formatDateToPattern(date, 'e'));
    const label = WEEKDAY_LABEL_MAP[weekday];

    return (blockedSchedules && blockedSchedules.get(label)?.toJS()) || {};
  });

export const makeSelectIsLoadingBlocks = () =>
  createSelector(
    selectTemporaryIsLoaded,
    selectFixedIsLoaded,
    (isTemporaryLoaded, isFixedLoaded) => !isTemporaryLoaded || !isFixedLoaded,
  );
