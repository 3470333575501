export const SEARCH_BY_HOUSE_ID = 'agentsPwa/Search/SEARCH_BY_HOUSE_ID';
export const SEARCH_BY_HOUSE_ID_FAILURE =
  'agentsPwa/Search/SEARCH_BY_HOUSE_ID_FAILURE';
export const SEARCH_BY_HOUSE_ID_SUCCESS =
  'agentsPwa/Search/SEARCH_BY_HOUSE_ID_SUCCESS';
export const SEARCH_HOUSE_FETCH_SUCCESS =
  'agentsPwa/Search/SEARCH_HOUSE_FETCH_SUCCESS';
export const SEARCH_USER_FETCH_SUCCESS =
  'agentsPwa/Search/SEARCH_USER_FETCH_SUCCESS';
export const SEARCH_SHOULD_SHOW_PENDING_VISITS =
  'agentsPwa/Search/SEARCH_SHOULD_SHOW_PENDING_VISITS';
export const SEARCH_PENDING_VISITS_BY_HOUSE_ID =
  'agentsPwa/Search/SEARCH_PENDING_VISITS_BY_HOUSE_ID';
export const SEARCH_PENDING_VISITS_BY_HOUSE_ID_SUCCESS =
  'agentsPwa/Search/SEARCH_PENDING_VISITS_BY_HOUSE_ID_SUCCESS';
export const DAYS_AGO = 1;
