export const ENTRY_INFO_URL_COPIED_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_URL_COPIED_CLICKED_ANALYTICS';
export const ENTRY_INFO_FIND_PROPERTY_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_FIND_PROPERTY_ANALYTICS';
export const ENTRY_INFO_PAGE_VIEWED_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_PAGE_VIEWED_ANALYTICS';
export const ENTRY_INFO_REGISTER_CLICKED_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_REGISTER_CLICKED_ANALYTICS';
export const ENTRY_INFO_SCHEDULE_CLICKED_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_SCHEDULE_CLICKED_ANALYTICS';
export const ENTRY_INFO_NEW_VISIT_CLICKED_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_NEW_VISIT_CLICKED_ANALYTICS';
export const ENTRY_INFO_SEARCH_SUBMITTED_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_SEARCH_SUBMITTED_ANALYTICS';
export const ENTRY_INFO_CHAT_WITH_AGENT_CLICKED_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_CHAT_WITH_AGENT_CLICKED_ANALYTICS';
export const ENTRY_INFO_CHAT_WITH_OWNER_CLICKED_ANALYTICS =
  'agentsPwa/EntryInformation/ENTRY_INFO_CHAT_WITH_OWNER_CLICKED_ANALYTICS';
export const CLEAR_ERRORS = 'agentsPwa/EntryInformation/CLEAR_ERRORS';
export const FETCH_HOUSE_ENTRY_INFORMATION =
  'agentsPwa/EntryInformation/FETCH_HOUSE_ENTRY_INFORMATION';
export const OWNER_CONTACT_OPEN_CLICKED_ANALYTICS =
  'agentsPwa/EntryInformation/OWNER_CONTACT_OPEN_CLICKED_ANALYTICS';
export const OWNER_CONTACT_CLOSE_CLICKED_ANALYTICS =
  'agentsPwa/EntryInformation/OWNER_CONTACT_CLOSE_CLICKED_ANALYTICS';

export const HOUSE_NOT_AVAILABLE =
  'agentsPwa/EntryInformation/HOUSE_NOT_AVAILABLE';
export const KEYS_WITH_AGENT = 'agentsPwa/EntryInformation/KEYS_WITH_AGENT';
export const KEYS_WITH_OWNER = 'agentsPwa/EntryInformation/KEYS_WITH_OWNER';
export const KEYS_WITH_OWNER_TO_AGENT =
  'agentsPwa/EntryInformation/KEYS_WITH_OWNER_TO_AGENT';
export const KEYS_ON_LOCKBOX = 'agentsPwa/EntryInformation/KEYS_ON_LOCKBOX';
export const KEYS_ON_FRONT_DOOR =
  'agentsPwa/EntryInformation/KEYS_ON_FRONT_DOOR';
export const KEYS_ON_LOCKER = 'agentsPwa/EntryInformation/KEYS_ON_LOCKER';
export const PASSWORD_WITH_OWNER =
  'agentsPwa/EntryInformation/PASSWORD_WITH_OWNER';
export const PASSWORD_PROVIDED = 'agentsPwa/EntryInformation/PASSWORD_PROVIDED';
export const BIOMETRY_WITH_OWNER =
  'agentsPwa/EntryInformation/BIOMETRY_WITH_OWNER';
export const UNKNOWN_LABEL = 'agentsPwa/EntryInformation/UNKNOWN_LABEL';
export const OWNER_IN_HOUSE = 'agentsPwa/EntryInformation/OWNER_IN_HOUSE';
export const TENANT_IN_HOUSE = 'agentsPwa/EntryInformation/TENANT_IN_HOUSE';
export const ERROR_LABEL = 'agentsPwa/EntryInformation/ERROR_LABEL';

export const EMPTY = 'Empty';
export const COMMON = 'Common';
export const PUBLISHED = 'publicado';
export const OWNER_PRESENT = 'OwnerPresent';
export const TENANT_PRESENT = 'TenantPresent';
export const LOCKBOX = 'LockBox';
export const FRONT_DOOR = 'FrontDoor';
export const KEYS_LOCKER = 'KeysLocker';
export const PASSWORD = 'Password';
export const BIOMETRY = 'Biometry';
export const BUILDING_MANAGER = 'BuildingManager';
export const KEY_LOCATION_KEYS_WITH_AGENT = 'KeysWithAgent';
export const OTHER = 'Other';
export const OWNER = 'Owner';
export const TENANT = 'Tenant';
export const NONE = 'None';

export const AGENT_NOT_IN_BUSINESS_CONTEXT =
  'agentsPwa/EntryInformation/AGENT_NOT_IN_BUSINESS_CONTEXT';

export const SNACKBAR_TIMEOUT = 3000;

export const ENTRANCE_STATUS = Object.freeze({
  KEYS_WITH_AGENT: 'keys_with_agent',
  UNAVAILABLE: 'unavailable',
  BOOK_FIRST: 'book_first',
  FREE: 'free',
  NA: 'NA',
});
