import produce from 'immer';
import { fromJS } from 'immutable';
import { handle, KEY } from 'redux-pack';

export const initialState = fromJS({
  pendingRequests: 0,
});

function progressReducer(draft = initialState, action) {
  if (action.meta && action.meta[KEY.LIFECYCLE]) {
    return handle(draft, action, {
      start: (state) =>
        draft.merge({
          pendingRequests: state.get('pendingRequests') + 1,
        }),
      finish: (state) =>
        draft.merge({
          pendingRequests: state.get('pendingRequests') - 1,
        }),
    });
  }
  return draft;
}

export default progressReducer;
