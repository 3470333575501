/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import React, { Fragment } from 'react';
import '!file-loader?name=[name].[ext]!./favicon.ico';
import { polyfillIntl } from 'Base/Provider/Polyfills/intl';
import 'core-js';

import 'file-loader?name=[name].[ext]!./.htaccess';
import 'moment/locale/pt-br';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, browserHistory, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import useScroll from 'react-router-scroll/lib/useScroll';

import 'regenerator-runtime/runtime';
import createRoutes from 'routes';
import '@quintoandar/cozy-typeface';
import 'sanitize.css/sanitize.css';

import store from 'store';

import '@quintoandar/cozy-core/styles/index.css';

import { Theme, ThemeWrapper } from '@quintoandar/cozy-core/theming';

import { detectLocale } from '@quintoandar/js-intl';

import { RUMProvider } from '@quintoandar/rum-react';

import { init } from '@quintoandar/fwp-error-monitoring';

import '@quintoandar/typeface';

import { setupFirebaseAndServices } from 'api/firebase';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import App from 'containers/App';
/* eslint-enable import/no-unresolved, import/extensions */

import { makeSelectLocationState } from 'containers/App/selectors';

import LanguageProvider from 'containers/LanguageProvider';
import { getMessages, getCozyThemeName } from 'i18n';

import GlobalStyle from './global-styles';

setupFirebaseAndServices();

init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE,
  ignoreErrors: [/network error/i],
});

const MOUNT_NODE = document.getElementById('app');

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
});

// Setup routes
const rootRoute = {
  component: App,
  childRoutes: createRoutes(store),
};
const routes = [rootRoute];

const noop = () => {};

const queryClient = new QueryClient();

const render = (messages, userLocale) => {
  const currentTheme = getCozyThemeName(userLocale);
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <ThemeWrapper name={Theme[currentTheme]}>
        <Provider store={store}>
          <LanguageProvider locale={userLocale} messages={messages}>
            <RUMProvider callback={noop}>
              <Fragment>
                <Router
                  history={history}
                  render={
                    // Scroll to top when going to a new page to imitate browser default behavior
                    applyRouterMiddleware(useScroll())
                  }
                  routes={routes}
                />
                <GlobalStyle />
              </Fragment>
            </RUMProvider>
          </LanguageProvider>
        </Provider>
      </ThemeWrapper>
    </QueryClientProvider>,
    MOUNT_NODE,
  );
};

const locale = detectLocale();

// Chunked polyfill for browsers without Intl support
polyfillIntl(locale)
  .then(async () => {
    const messages = await getMessages(locale);
    render(messages, locale);
  })
  .catch((err) => {
    throw err;
  });

// Created a new NODE_ENV just for this case
// Running whyDidYouUpdate while on development mode REALLY slows down the app
if (process.env.NODE_ENV === 'render-audit') {
  const { whyDidYouUpdate } = require('why-did-you-update'); // eslint-disable-line
  whyDidYouUpdate(React);
}
