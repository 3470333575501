/**
 * Asynchronously loads the component for RentProgress
 */
import Loadable from 'react-loadable';

export default Loadable({
  loader: () =>
    import(/* webpackChunkName: "errorBoundary" */ './ErrorBoundary'),
  loading: () => null,
});
