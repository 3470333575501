import { logout as biomaAuthLogout } from '@quintoandar/bioma-auth/containers/actions';
import { COOKIE_DOMAIN } from 'config';
import { CRM_CONTEXT } from 'containers/Redirecting/constants';
import RiskAssessment from './riskAssessment';

const logout = () => {
  localStorage.removeItem(CRM_CONTEXT);
  RiskAssessment.clearAccountId();
  return biomaAuthLogout(COOKIE_DOMAIN);
};

export default logout;
