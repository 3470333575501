export const BLOCK_ALL_TIMES = 'agentsPwa/TimeBlock/BLOCK_ALL_TIMES';
export const CHANGE_CHECKED_STATUS =
  'agentsPwa/TimeBlock/CHANGE_CHECKED_STATUS';
export const CLOSE_ADD_TIME_BLOCK_DIALOG =
  'agentsPwa/TimeBlock/CLOSE_ADD_TIME_BLOCK_DIALOG';
export const CLOSE_CONFIRM_DIALOG = 'agentsPwa/TimeBlock/CLOSE_CONFIRM_DIALOG';
export const CONFIRM_FIXED_BLOCK = 'agentsPwa/TimeBlock/CONFIRM_FIXED_BLOCK';
export const DECREMENT_BLOCK_STEP_INDEX =
  'agentsPwa/TimeBlock/DECREMENT_BLOCK_STEP_INDEX';
export const DELETE_FIXED_BLOCK = 'agentsPwa/TimeBlock/DELETE_FIXED_BLOCK';
export const EDIT_FIXED_BLOCK = 'agentsPwa/TimeBlock/EDIT_FIXED_BLOCK';
export const FETCH_CONTRACT_HOURS = 'agentsPwa/TimeBlock/FETCH_CONTRACT_HOURS';
export const FETCH_TEMPORARY_BLOCKS =
  'agentsPwa/TimeBlock/FETCH_TEMPORARY_BLOCKS';
export const FETCH_TEMPORARY_HOURS_RANGE =
  'agentsPwa/TimeBlock/FETCH_TEMPORARY_HOURS_RANGE';
export const FETCH_FIXED_BLOCKS = 'agentsPwa/TimeBlock/FETCH_FIXED_BLOCKS';
export const INCREMENT_BLOCK_STEP_INDEX =
  'agentsPwa/TimeBlock/INCREMENT_BLOCK_STEP_INDEX';
export const OPEN_ADD_TIME_BLOCK_DIALOG =
  'agentsPwa/TimeBlock/OPEN_ADD_TIME_BLOCK_DIALOG';
export const OPEN_ADD_CONFIRM_DIALOG =
  'agentsPwa/TimeBlock/OPEN_ADD_CONFIRM_DIALOG';
export const OPEN_DELETE_CONFIRM_DIALOG =
  'agentsPwa/TimeBlock/OPEN_DELETE_CONFIRM_DIALOG';
export const OPEN_EDIT_FIXED_BLOCK_DIALOG =
  'agentsPwa/TimeBlock/OPEN_EDIT_FIXED_BLOCK_DIALOG';
export const SET_FIXED_BLOCK_DAY = 'agentsPwa/TimeBlock/SET_FIXED_BLOCK_DAY';
export const SET_BLOCK_TYPE = 'agentsPwa/TimeBlock/SET_BLOCK_TYPE';
export const SET_TEMPORARY_BLOCK_START_DATE =
  'agentsPwa/TimeBlock/SET_TEMPORARY_BLOCK_START_DATE';
export const SET_TEMPORARY_BLOCK_END_DATE =
  'agentsPwa/TimeBlock/SET_TEMPORARY_BLOCK_END_DATE';
export const SET_TEMPORARY_BLOCK_DAY =
  'agentsPwa/TimeBlock/SET_TEMPORARY_BLOCK_DAY';
export const SET_TEMPORARY_BLOCK_TYPE =
  'agentsPwa/TimeBlock/SET_TEMPORARY_BLOCK_TYPE';
export const TIME_BLOCK_PAGE_VIEWED =
  'agentsPwa/TimeBlock/TIME_BLOCK_PAGE_VIEWED';
export const DELETE_TEMPORARY_BLOCK =
  'agentsPwa/TimeBlock/DELETE_TEMPORARY_BLOCK';
export const CLEAR_STATE = 'agentsPwa/TimeBlock/CLEAR_STATE';
export const CONFIRM_TEMPORARY_BLOCK =
  'agentsPwa/TimeBlock/CONFIRM_TEMPORARY_BLOCK';
export const OPEN_ADD_TEMPORARY_BLOCK_DIALOG =
  'agentsPwa/TimeBlock/OPEN_ADD_TEMPORARY_BLOCK_DIALOG';
