import { addBreadcrumb } from '@quintoandar/fwp-error-monitoring';
import store from 'store';
import {
  signInAnonymously,
  signInWithToken,
  signOut,
} from '@quintoandar/firebase-client/auth';
import { hasAuthCookie } from '@quintoandar/wat-cookie';

import { FIREBASE_AUTH_STATE_CHANGED } from '../../containers/App/constants';

const loginToFirebaseWithToken = (token) => {
  addBreadcrumb({
    message: 'Will login to firebase',
    category: 'auth flow',
    data: { hasAuthCookie: hasAuthCookie() },
  });

  return signInWithToken(token);
};

const logoutFromFirebase = () => {
  addBreadcrumb({
    message: 'Will log out from firebase',
    category: 'auth flow',
    data: { hasAuthCookie: hasAuthCookie() },
  });

  signOut();
};

const handleAuthStateChanged = (user) => {
  store.dispatch({ type: FIREBASE_AUTH_STATE_CHANGED, payload: user });
  if (!user) signInAnonymously();
};

export { loginToFirebaseWithToken, logoutFromFirebase, handleAuthStateChanged };
