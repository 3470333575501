/* eslint-disable global-require */
import { useState, useEffect } from 'react';

import { parseCookiesToUser } from '@quintoandar/wat-cookie';

import { LISTING_BUSINESS_CONTEXT_FEATURE_FLAG } from 'containers/Agenda/constants';
import { getAgentIdByCookie } from 'containers/App/User/Data/useAgentData';
import { FEATURE_FLAGS } from 'enums/featureFlags';

const cacheTimeToLiveSeconds = 60;
const defaultFeatureFlagValue = false;
let provider;

const isServer = () => typeof window === 'undefined';

if (isServer()) {
  const { createClientWithLazyLoad } = require('configcat-js-ssr');

  provider = process.env.CONFIG_CAT_KEY
    ? createClientWithLazyLoad(process.env.CONFIG_CAT_KEY, {
        cacheTimeToLiveSeconds,
      })
    : { getValueAsync: () => Promise.resolve(defaultFeatureFlagValue) };
} else {
  const {
    getLazyLoadingProvider,
    LazyLoadMode,
  } = require('@quintoandar/feature-flag-tools');

  provider = process.env.CONFIG_CAT_KEY
    ? getLazyLoadingProvider(process.env.CONFIG_CAT_KEY, LazyLoadMode.LIGHT)
    : { isFeatureEnabled: () => Promise.resolve(defaultFeatureFlagValue) };
}

export const getRelistingEnabledFlag = () =>
  isServer()
    ? provider?.getValueAsync(
        LISTING_BUSINESS_CONTEXT_FEATURE_FLAG,
        defaultFeatureFlagValue,
      )
    : provider?.isFeatureEnabled(
        LISTING_BUSINESS_CONTEXT_FEATURE_FLAG,
        defaultFeatureFlagValue,
      );

export const getVisitAutomaticCancellationFeatureFlag = () => {
  const agentId = getAgentIdByCookie();

  if (isServer()) {
    return provider?.getValueAsync(
      FEATURE_FLAGS.VISIT_AUTOMATIC_CANCELLATION,
      defaultFeatureFlagValue,
    );
  }

  return provider?.isFeatureEnabled(
    FEATURE_FLAGS.VISIT_AUTOMATIC_CANCELLATION,
    defaultFeatureFlagValue,
    { identifier: String(agentId) },
  );
};

export const getFeatureFlagEnabled = (key) => {
  const userFromCookie = parseCookiesToUser();

  // eslint-disable-next-line camelcase
  const agentId = userFromCookie?.agent_id || '';

  if (isServer()) {
    return provider?.getValueAsync(key, defaultFeatureFlagValue);
  }

  return provider?.isFeatureEnabled(key, defaultFeatureFlagValue, {
    identifier: String(agentId),
  });
};

export const useFeatureFlag = (key, options, defaultValue = false) => {
  const [isEnabled, setIsEnabled] = useState(defaultValue);

  if (isServer()) {
    return isEnabled;
  }

  const getFeatureFlag = async () => {
    const isFeatureEnabled = await provider?.isFeatureEnabled(
      key,
      defaultValue,
      { ...options },
    );
    setIsEnabled(isFeatureEnabled);
  };

  useEffect(() => {
    getFeatureFlag();
  }, [key, defaultValue, options]);

  return isEnabled;
};
