import tokens from '@quintoandar/cozy-theme/tokens/js';
import { isWidthDown } from '@quintoandar/cozy-core/media/withWidth';

export const carouselHeight = 320;
export const defaultMapClusterPadding = 25;
export const defaultSpacingValue = tokens.alias.spacing.xsmall; // 16
export const defaultSideSpacingValue = tokens.alias.spacing.small; // 24
export const defaultSixSpacingValue = tokens.alias.spacing.xlarge; // 48
export const defaultThumbWidth = 120;
export const maxWidth = 1032;
export const appBarDefaultHeight = 56;
export const appBarDefaultHeightDesktop = 64;

const Dimens = {
  defaultSixSpacing: defaultSixSpacingValue, // 48
  defaultDoubleSpacing: tokens.alias.spacing.medium, // 32
  defaultSideSpacing: defaultSideSpacingValue, // 24
  halfSideSpacing: '12.5px',
  quarterSideSpacing: '6px',

  defaultSpacing: tokens.alias.spacing.xsmall, // 16
  defaultHalfSpacing: tokens.alias.spacing.xxsmall, // 8
  defaultQuarterSpacing: tokens.alias.spacing.hxxsmall, // 4
  noMargin: '0px',
  defaultNineSpacing: tokens.alias.spacing.huge, // 72
  defaultTwelveSpacing: '96px',

  defaultButtonHeight: '68px',
  defaultTopBarHeight: tokens.alias.spacing.xxlarge, // 56
  defaultActionBarHeight: tokens.alias.spacing.xhuge, // 80
  largeButtonHeight: defaultSixSpacingValue,
  buttonCustomMinWidth: '220px',
  defaultTabsHeight: defaultSixSpacingValue,

  searchBarHeight: defaultSixSpacingValue,
  appBarSearchBarHeight: tokens.alias.spacing.large, // 40

  houseCardHeight: carouselHeight,
  alertOverlayHeight: '136px',
  alertOverlayTextHeight: '96px',

  frontMostZIndex: 10,
  noElevation: 0,
  defaultElevation: 2,
};

const getAppBarHeight = (width) =>
  isWidthDown('sm', width) ? appBarDefaultHeight : appBarDefaultHeightDesktop;

export { getAppBarHeight };
export default Dimens;
