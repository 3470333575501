import BUSINESS_CONTEXTS from 'enums/businessContexts';

const handleBusinessContext = (payload) => {
  if (!payload) {
    return null;
  }

  const { businessContext } = payload;

  // Rent is defaulted in case business context is not set.
  if (
    businessContext &&
    Array.isArray(businessContext) &&
    businessContext.length > 0
  ) {
    return businessContext;
  }

  return [BUSINESS_CONTEXTS.FOR_RENT];
};

export default handleBusinessContext;
