import { getBaseDomain } from 'config';

export const ABOUT_5A_URL = 'https://press.quintoandar.com.br';

export function getProposalLink(houseId) {
  const newUrl = `/propostas/alugar?imovel=${houseId}`;
  return newUrl;
}

export function getSaleOfferLink(houseId) {
  const newUrl = `/proposta/comprar/${houseId}`;
  return newUrl;
}

export function getMyProposalsLink(useHTMLLink = false) {
  const newUrl = '/tenants/rentFlows';

  return {
    linkPath: newUrl,
    externalLink: false,
    useHTMLLink,
  };
}

export const getAdvertiseLink = (queryStr = '') => {
  let query = queryStr;
  if (!query && typeof window !== 'undefined') {
    query = window.location.search;
  }
  const baseDomain = getBaseDomain();
  return baseDomain.includes('quintoandar')
    ? `https://proprietario.${baseDomain}/anunciar-imovel${query}`
    : `https://mkt.${baseDomain}/consultor-inmobiliario/`;
};

export const getMortgageSimulatorLink = (queryStr = '') =>
  `https://mkt.${getBaseDomain()}/financiamento-imobiliario${queryStr}`;

export const getPortabilityLink = (queryStr = '') =>
  `https://mkt.${getBaseDomain()}/portabilidade-inquilinos${queryStr}`;

export const getAffiliatesAppLink = (queryStr = '') =>
  `https://indicaai.${getBaseDomain()}${queryStr}`;

export const getHelpCenterLink = () =>
  `https://help.${getBaseDomain()}/hc/pt-br`;

export const getIndicateLink = (queryStr = '') =>
  `${getAffiliatesAppLink()}${queryStr}`;

export const getDefaultHelpCenterLink = () =>
  `${getHelpCenterLink()}/articles/218779737`;

export const getBecomeAgentLink = (queryStr = '') =>
  `https://mkt.${getBaseDomain()}/corretor${queryStr}`;

export const getPartnerDoormanLink = (queryStr = '') =>
  `https://mkt.${getBaseDomain()}/porteiroparceiro${queryStr}`;

export const getUberRoute = (address) =>
  `https://m.uber.com/ul/?action=setPickup&pickup=my_location&&dropoff[formatted_address]=${address}`;

const NineNineTaxiCategories = {
  POP: 316,
  TOP: 326,
  TAXIS: 327,
};
export const getNineNineRoute = (lat, lng, address) =>
  `taxis99://call?dropoff_latitude=${lat}&dropoff_longitude=${lng}&dropoff_title=${address}&deep_link_product_id=${NineNineTaxiCategories.POP}&client_id=MAP_QUINTOANDAR`;

// TODO: https://quintoandar.atlassian.net/browse/LIQ-1103
export const getOwnersAppLink = () =>
  process.env.OWNERS_APP_URL || `https://proprietario.${getBaseDomain()}`;

export const getOwnersPropertiesLink = () => {
  const propertiesPath = '/meus-imoveis';
  return `${getOwnersAppLink()}${propertiesPath}`;
};

export const getLinkIndicateAndWin = (queryStr = '') =>
  `https://indicaai.${getBaseDomain()}/indique-imoveis-e-ganhe/${queryStr}`;

export const getTenantsAppLink = (queryStr = '') =>
  `https://${getBaseDomain()}${queryStr}`;

export const getAgentsAppLink = (queryStr = '') =>
  `https://corretor.${getBaseDomain()}${queryStr}`;

export const getBecomeInspectorLink = (queryStr = '') =>
  `https://mkt.${getBaseDomain()}/vistoriadores-quintoandar/${queryStr}`;

export const getBecomePhotographerLink = (queryStr = '') =>
  `https://mkt.${getBaseDomain()}/fotografos-quintoandar/${queryStr}`;

export const getCareersLink = (queryStr = '') =>
  `https://carreiras.${getBaseDomain()}/${queryStr}`;

export const getPartnersLandingLink = (queryStr = '') =>
  `https://mkt.${getBaseDomain()}/parcerias/${queryStr}`;

export const getStatisticsLink = (queryStr = '') =>
  `https://mkt.${getBaseDomain()}/plataforma-de-estatisticas/${queryStr}`;

export const getSupportedRegionsLink = (queryStr = '') =>
  `https://${getBaseDomain()}/regioes-atendidas${queryStr}`;

export const getAboutGuaranteeLink = (queryStr = '') =>
  `https://sobre.${getBaseDomain()}/garantia${queryStr}`;

export const getMapsRoute = (address) =>
  `https://www.google.com/maps/search/?api=1&query=${address}`;
