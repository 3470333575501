/*
 * RentProgress Messages
 *
 * This contains all the text for the RentProgress component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  visits: {
    id: 'app.components.RentProgress.visits',
    defaultMessage: 'Visitas',
  },
  negotiation: {
    id: 'app.components.RentProgress.negotiation',
    defaultMessage: 'Negociação',
  },
  documentation: {
    id: 'app.components.RentProgress.documentation',
    defaultMessage: 'Documentação',
  },
  contracts: {
    id: 'app.components.RentProgress.contracts',
    defaultMessage: 'Contrato',
  },
  header: {
    id: 'app.components.AppBar.header',
    defaultMessage: 'Status de proposta',
  },
  emptyFiltersTitle: {
    id: 'app.containers.RentProgress.emptyFiltersTitle',
    defaultMessage: 'Nenhum resultado encontrado',
  },
});
