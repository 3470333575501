import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    font-family: 'Oatmeal', sans-serif;
  }

  #app {
    min-height: 100%;
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
  }

  p,
  label {
    line-height: 1.5em;
  }

  // Force intercom z-index so the FAB doesn't appear above Dialogs and Snackbars
  // Using "1100" because this is the value between AppBar and Snackbar
  #intercom-container {
    z-index: 1100 !important;
  }
  #intercom-container > * {
    z-index: 1100 !important;
  }

  .root svg.MuiSvgIcon-root {
    color: inherit;
  }

  a {
    cursor: pointer !important;
  }
`;

export default GlobalStyle;
