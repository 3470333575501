import { fromJS } from 'immutable';

import { HIDE_DIALOG } from 'containers/App/constants';

import {
  SEARCH_BY_HOUSE_ID,
  SEARCH_BY_HOUSE_ID_FAILURE,
  SEARCH_BY_HOUSE_ID_SUCCESS,
  SEARCH_HOUSE_FETCH_SUCCESS,
  SEARCH_USER_FETCH_SUCCESS,
  SEARCH_SHOULD_SHOW_PENDING_VISITS,
  SEARCH_PENDING_VISITS_BY_HOUSE_ID_SUCCESS,
  SEARCH_PENDING_VISITS_BY_HOUSE_ID,
} from 'containers/Search/constants';

import {
  handleSearchByHouseIdSuccess,
  handleSearchHouseFetchSuccess,
  handleSearchUserFetchSuccess,
  handleSearchPendingVisitsByHouseIdSuccess,
} from 'containers/Search/handlers';

export const initialState = fromJS({
  data: {
    contracts: [],
    offers: [],
    visits: [],
    documentations: [],
    saleOffers: [],
    pendingVisits: [],
  },
  error: {
    message: '',
  },
  hasError: false,
  hasSearched: false,
  isLoading: false,
  showPendingVisits: false,
  isLoadingPendingVisits: false,
});

/**
 * Search container reducer
 * @param {Immutable.Map} state - previous state
 * @param {object} action
 * @returns {Immutable.Map} state - modified state
 */
function searchReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    default:
      return state;
    case SEARCH_BY_HOUSE_ID:
      return state.set('isLoading', true);
    case SEARCH_PENDING_VISITS_BY_HOUSE_ID:
      return state.set('isLoadingPendingVisits', true);
    case SEARCH_BY_HOUSE_ID_FAILURE:
      return state
        .set('hasError', true)
        .setIn(['error', 'message'], payload.message)
        .set('hasSearched', true);
    case SEARCH_BY_HOUSE_ID_SUCCESS:
      return handleSearchByHouseIdSuccess(state, payload.data);
    case HIDE_DIALOG:
      return state.merge(initialState);
    case SEARCH_HOUSE_FETCH_SUCCESS:
      return handleSearchHouseFetchSuccess(state, action);
    case SEARCH_PENDING_VISITS_BY_HOUSE_ID_SUCCESS:
      return handleSearchPendingVisitsByHouseIdSuccess(state, payload.data);
    case SEARCH_USER_FETCH_SUCCESS:
      return handleSearchUserFetchSuccess(state, action);
    case SEARCH_SHOULD_SHOW_PENDING_VISITS:
      return state.set('showPendingVisits', payload.showPendingVisits);
  }
}

export default searchReducer;
