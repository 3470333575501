import { DONE_VISITS_FILTER_SUBMITTED_ANALYTICS } from 'containers/DoneVisits/Dialog/constants';
import {
  DONE_VISITS_CARD_OPENED_ANALYTICS,
  DONE_VISITS_PAGE_VIEWED_ANALYTICS,
  DONE_VISITS_CALL_TENANT_CLICKED_ANALYTICS,
  DONE_VISITS_CHAT_WITH_TENANT_CLICKED_ANALYTICS,
  DONE_VISITS_SEND_OFFER_ANALYTICS,
  DONE_VISITS_FILTER_CLICKED_ANALYTICS,
  DONE_VISITS_CHAT_WITH_OWNER_CLICKED_ANALYTICS,
  DONE_VISITS_FINISH_VISIT_CLICKED_ANALYTICS,
  DONE_VISITS_PENDING_VISITS_TAB_CLICKED_ANALYTICS,
  DONE_VISITS_TAB_CLICKED_ANALYTICS,
} from 'containers/DoneVisits/constants';

import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleDoneVisitsActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case DONE_VISITS_PAGE_VIEWED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_page_viewed',
        immutablePage(PAGE_NAMES.DONE_VISITS),
        action.extraProperties,
      );
      break;
    case DONE_VISITS_FILTER_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_filter_clicked',
        immutablePage(PAGE_NAMES.DONE_VISITS),
        action.extraProperties,
      );
      break;
    case DONE_VISITS_FILTER_SUBMITTED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_filter_submitted',
        immutablePage(PAGE_NAMES.DONE_VISITS),
        action.extraProperties,
      );
      break;
    case DONE_VISITS_CARD_OPENED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_card_opened',
        immutablePage(PAGE_NAMES.DONE_VISITS),
        action.extraProperties,
      );
      break;
    case DONE_VISITS_CALL_TENANT_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_card_call_clicked',
        immutablePage(PAGE_NAMES.DONE_VISITS),
        action.extraProperties,
      );
      break;
    case DONE_VISITS_CHAT_WITH_TENANT_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_card_send_message_clicked',
        immutablePage(PAGE_NAMES.DONE_VISITS),
        action.extraProperties,
      );
      break;
    case DONE_VISITS_SEND_OFFER_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_card_send_proposal_clicked',
        immutablePage(PAGE_NAMES.DONE_VISITS),
        action.extraProperties,
      );
      break;
    case DONE_VISITS_CHAT_WITH_OWNER_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'chatcrpp_access_chat_clicked',
        immutablePage(PAGE_NAMES.DONE_VISITS),
      );
      break;
    case DONE_VISITS_FINISH_VISIT_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_finish_visit_clicked',
        immutablePage(PAGE_NAMES.DONE_VISITS),
      );
      break;
    case DONE_VISITS_PENDING_VISITS_TAB_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_tab_pending_clicked',
        immutablePage(PAGE_NAMES.DONE_VISITS),
      );
      break;
    case DONE_VISITS_TAB_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'finished_schedulings_tab_finished_clicked',
        immutablePage(PAGE_NAMES.DONE_VISITS),
      );
      break;

    default:
      break;
  }
};

export default handleDoneVisitsActions;
