import { defineMessages } from 'react-intl';

export default defineMessages({
  phraseLoadingStepOne: {
    id: 'Redirecting.phraseLoadingStepOne',
    defaultMessage: 'Você está sendo redirecionado para o {companyName}...',
  },
  phraseLoadingStepTwo: {
    id: 'Redirecting.phraseLoadingStepTwo',
    defaultMessage: 'Faça o seu login de corretor',
  },
  phraseLoadingStepThree: {
    id: 'Redirecting.phraseLoadingStepThree',
    defaultMessage: 'Agende sua visita aos imóveis desejados',
  },
  phraseLoadingStepFour: {
    id: 'Redirecting.phraseLoadingStepFour',
    defaultMessage: 'Aguarde só um instante...',
  },
  phraseLoadingStepFive: {
    id: 'Redirecting.phraseLoadingStepFive',
    defaultMessage: 'Redirecionando!',
  },
});
