import { HTTP_METHODS } from 'config';

import { AGENT_RATING } from './endpoints';
import agentsApiConfig from './index';

export const fetchAgentRating = ({ businessContext, days }) =>
  agentsApiConfig({
    endpoint: AGENT_RATING,
    method: HTTP_METHODS.GET,
    queryString: `businessContext=${businessContext}&days=${days}`,
  });
