import { fromJS } from 'immutable';

import { parseSnapshotToArray } from 'utils';
import { VISIT } from 'enums/rentProgressTypes';
import { parseRentFlowData, parseVisitData } from 'utils/reducerPagination';

import {
  VISITS_PATH_NAME,
  VISITOR_PATH_NAME,
  TENANT_PATH_NAME,
} from './pathNames';

export const handleSearchByHouseIdSuccess = (state, data) => {
  const contractsData = parseSnapshotToArray(data.contracts);
  const offersData = parseSnapshotToArray(data.offers);
  const visitsData = parseSnapshotToArray(data.visits);
  const documentationsData = parseSnapshotToArray(data.documentations);
  const saleOffersData = parseSnapshotToArray(data.saleOffers);

  const parsedContracts = parseRentFlowData(contractsData);
  const parsedOffers = parseRentFlowData(offersData);
  const parsedVisits = parseVisitData(visitsData);
  const parsedDocumentations = parseRentFlowData(documentationsData);

  return state
    .setIn(['data', 'contracts'], fromJS(parsedContracts))
    .setIn(['data', 'offers'], fromJS(parsedOffers))
    .setIn(['data', 'visits'], fromJS(parsedVisits))
    .setIn(['data', 'documentations'], fromJS(parsedDocumentations))
    .setIn(['data', 'saleOffers'], fromJS(saleOffersData))
    .set('isLoading', false)
    .set('hasSearched', true);
};
export const handleSearchPendingVisitsByHouseIdSuccess = (state, data) =>
  state
    .setIn(['data', 'pendingVisits'], fromJS(data))
    .set('isLoadingPendingVisits', false);

export const getEntityPathNameFromEntityType = (entityType) => {
  if (entityType === VISIT) {
    return VISITS_PATH_NAME;
  }
  return `${entityType}s`;
};

export const handleSearchHouseFetchSuccess = (state, action) => {
  const {
    entityId,
    address,
    mainImageUrl,
    owner,
    entityType,
    displayCode,
    rentValue,
  } = action.payload;

  const entityPathName = getEntityPathNameFromEntityType(entityType);
  const entities = state.getIn(['data', entityPathName]);
  const entityIndex = entities.findKey(
    (entity) => entity.get(`${entityType}Id`) === entityId,
  );

  if (entityIndex === undefined) {
    return state;
  }

  const houseInfo = fromJS({
    ...address,
    shortId: displayCode,
    coverImageUrl: mainImageUrl,
    owner,
    rentValue,
  });

  return state.setIn(['data', entityPathName, entityIndex, 'house'], houseInfo);
};

export const getUserPathNameByEntityType = (entityType) => {
  if (entityType === VISIT) {
    return VISITOR_PATH_NAME;
  }
  return TENANT_PATH_NAME;
};

export const handleSearchUserFetchSuccess = (state, action) => {
  const { entityId, cpf, id, name, phone, email, entityType } = action.payload;

  const entityPathName = getEntityPathNameFromEntityType(entityType);
  const entities = state.getIn(['data', entityPathName]);
  const entityIndex = entities.findKey(
    (entity) => entity.get(`${entityType}Id`) === entityId,
  );

  if (entityIndex === undefined) {
    return state;
  }

  const userInfo = {
    cpf,
    id,
    name,
    cellphone: phone,
    email,
  };

  const userPathName = getUserPathNameByEntityType(entityType);
  return state.setIn(
    ['data', entityPathName, entityIndex, userPathName],
    fromJS(userInfo),
  );
};
