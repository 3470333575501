export const COUNT_DOCUMENTATIONS_FAILURE =
  'agentsPwa/Documentations/COUNT_DOCUMENTATIONS_FAILURE';
export const COUNT_DOCUMENTATIONS_SUCCESS =
  'agentsPwa/Documentations/COUNT_DOCUMENTATIONS_SUCCESS';
export const FETCH_DOCUMENTATIONS_FAILURE =
  'agentsPwa/Documentations/FETCH_DOCUMENTATIONS_FAILURE';
export const FETCH_DOCUMENTATIONS_REQUEST =
  'agentsPwa/Documentations/FETCH_DOCUMENTATIONS_REQUEST';
export const FETCH_DOCUMENTATIONS_SUCCESS =
  'agentsPwa/Documentations/FETCH_DOCUMENTATIONS_SUCCESS';
export const SHOULD_FETCH_DOCUMENTATIONS =
  'agentsPwa/Documentations/SHOULD_FETCH_DOCUMENTATIONS';
export const DOCUMENTATION_HOUSE_FETCH_SUCCESS =
  'agentsPwa/Documentations/DOCUMENTATION_HOUSE_FETCH_SUCCESS ';
export const DOCUMENTATION_USER_FETCH_SUCCESS =
  'agentsPwa/Documentations/DOCUMENTATION_USER_FETCH_SUCCESS ';
export const CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION =
  'agentsPwa/Documentations/CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION ';
export const DOCUMENTATION_RETRY_COUNT_INCREMENT =
  'agentsPwa/Documentations/DOCUMENTATION_RETRY_COUNT_INCREMENT';
export const FETCH_RENT_FLOW_DOCUMENTATIONS =
  'agentsPwa/Documentations/FETCH_RENT_FLOW_DOCUMENTATIONS';

export const RETRY_COUNT_THRESHOLD = 3;
