import { FEATURES } from 'intl/constants';

export const SPECIAL_CONDITION_TYPE = Object.freeze({
  EXCLUSIVE: 'Exclusivity',
  ORIGINALS_READY: 'OriginalsReady',
  ORIGINALS_RENO: 'OriginalsReno',
});

export const PRICE_INFO_PROPS = Object.freeze({
  [FEATURES.HOME_OWNER_INSURANCE]: ['homeProtection'],
  [FEATURES.SELF_SERVICE_TAXES]: ['iptuType', 'iptu'],
  [FEATURES.TENANT_SERVICE_FEE]: ['tenantServiceFee'],
});
