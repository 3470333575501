import {
  SEARCH_BY_HOUSE_ID,
  SEARCH_BY_HOUSE_ID_FAILURE,
  SEARCH_BY_HOUSE_ID_SUCCESS,
  SEARCH_HOUSE_FETCH_SUCCESS,
  SEARCH_USER_FETCH_SUCCESS,
  SEARCH_SHOULD_SHOW_PENDING_VISITS,
  SEARCH_PENDING_VISITS_BY_HOUSE_ID,
  SEARCH_PENDING_VISITS_BY_HOUSE_ID_SUCCESS,
} from 'containers/Search/constants';

export const searchByHouseId = ({ agentId, houseId, businessContext }) => ({
  type: SEARCH_BY_HOUSE_ID,
  payload: {
    agentId,
    houseId,
    businessContext,
  },
});

export const searchPendingVisitsByHouseId = ({ params }) => ({
  type: SEARCH_PENDING_VISITS_BY_HOUSE_ID,
  payload: {
    params,
  },
});

export const searchByHouseIdFailure = (message) => ({
  type: SEARCH_BY_HOUSE_ID_FAILURE,
  payload: {
    message,
  },
});

export const searchByHouseIdSuccess = (data) => ({
  type: SEARCH_BY_HOUSE_ID_SUCCESS,
  payload: {
    data,
  },
});

export const searchPendingVisitsByHouseIdSuccess = (data) => ({
  type: SEARCH_PENDING_VISITS_BY_HOUSE_ID_SUCCESS,
  payload: {
    data,
  },
});

export const searchHouseFetchSuccess = (entityType, payload) => ({
  type: SEARCH_HOUSE_FETCH_SUCCESS,
  payload: {
    ...payload,
    entityType,
  },
});

export const searchUserFetchSuccess = (entityType, payload) => ({
  type: SEARCH_USER_FETCH_SUCCESS,
  payload: {
    ...payload,
    entityType,
  },
});

export const searchShouldShowPendingVisits = (showPendingVisits) => ({
  type: SEARCH_SHOULD_SHOW_PENDING_VISITS,
  payload: {
    showPendingVisits,
  },
});
