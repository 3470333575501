export const FILTERS_MAP_DATE = Object.freeze({
  TODAY: {
    name: 'TODAY',
    value: 0,
  },
  LAST_7_DAYS: {
    name: 'LAST_7_DAYS',
    value: 7,
  },
  LAST_10_DAYS: {
    name: 'LAST_10_DAYS',
    value: 10,
  },
  LAST_25_DAYS: {
    name: 'LAST_25_DAYS',
    value: 25,
  },
  LAST_30_DAYS: {
    name: 'LAST_30_DAYS',
    value: 30,
  },
});

export const FILTERS_MAP_STATUS = Object.freeze({
  ALL: {
    name: 'ALL',
    value: 'All',
  },
  CONTRACT: {
    name: 'CONTRACT',
    value: 'Contract',
  },
  DOCUMENTATION: {
    name: 'DOCUMENTATION',
    value: 'Documentation',
  },
  OFFER: {
    name: 'OFFER',
    value: 'Offer',
  },
});

export const FILTERS_MAP_VISIT_STATUS = Object.freeze({
  ALL: {
    name: 'ALL',
    value: 'All',
    message: 'emptyMessage',
  },
  PROPOSAL_SENT: {
    name: 'PROPOSAL_SENT',
    value: 'ProposalSent',
    message: 'filtersStatusOptionProposalSent',
  },
  PROPOSAL_NOT_SENT: {
    name: 'PROPOSAL_NOT_SENT',
    value: 'ProposalNotSent',
    message: 'filtersStatusOptionProposalNotSent',
  },
  ENTRANCE_PROBLEMS: {
    name: 'ENTRANCE_PROBLEMS',
    value: 'EntranceProblems',
    message: 'filtersStatusOptionEntranceProblems',
  },
});

export const FILTERS_MAP_CONTRACT_STATUS = Object.freeze({
  SIGNED: {
    name: 'SIGNED',
    value: 'Ativo',
    message: 'filtersStatusOptionSigned',
  },
  CANCELLED: {
    name: 'CANCELLED',
    value: 'Cancelado',
    message: 'filtersStatusOptionCancelled',
  },
  DRAFT: {
    name: 'DRAFT',
    value: 'Minuta',
    message: 'filtersStatusOptionDraft',
  },
  WAITING_SIGNATURE: {
    name: 'WAITING_SIGNATURE',
    value: 'PreAssinaturas',
    message: 'filtersStatusOptionWaitingSignature',
  },
  ALL: {
    name: 'ALL',
    value: 'All',
    message: 'filtersStatusOptionAll',
  },
});

export const FILTERS_MAP_SORTING = Object.freeze({
  ASC: {
    name: 'ASC',
    value: 'asc',
  },
  DESC: {
    name: 'DESC',
    value: 'desc',
  },
});

export const RENT_FLOWS_SORTING_OPTIONS = Object.freeze({
  ASC: 'CREATED_AT_ASC',
  DESC: 'CREATED_AT_DESC',
});

export const FILTERS_VISITS_GROUPS = Object.freeze({
  STATUS: 'visits-filters-status',
  SORTING: 'visits-sorting',
  DATE: 'visits-filters',
});
