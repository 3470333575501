import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Dialog from 'components/Dialog/Loadable';
import MessageDialog from 'components/MessageDialog';
import Snackbar from 'components/Snackbar/Loadable';
import {
  hideDialog,
  hideSnackbar,
  updateDialogStatus,
  hideErrorDialog,
} from 'containers/App/actions';
import { makeSelectApp } from 'containers/App/selectors';

export const SharedContent = ({
  app,
  handleCloseDialog,
  handleCloseSnackbar,
  handleDialogOnEntered,
  reload,
  handleCloseErrorDialog,
}) => {
  const { snackbar, dialog, errorDialog } = app;
  const { isVisible: isDialogVisible, screen, tab, businessContext } = dialog;
  const {
    color,
    message: snackbarMessage,
    isVisible: isSnackbarVisible,
    timeout,
    actionTitle,
  } = snackbar;
  const { isOpen: isErrorDialogOpen, body, title } = errorDialog;

  const renderSnackbar = isSnackbarVisible ? (
    <Snackbar
      autoHideDuration={timeout}
      buttonLabel={actionTitle}
      color={color}
      handleAction={reload}
      handleClose={handleCloseSnackbar}
      message={snackbarMessage}
      open={isSnackbarVisible}
    />
  ) : null;

  const renderDialog = isDialogVisible ? (
    <Dialog
      businessContext={businessContext}
      handleClose={handleCloseDialog}
      handleOnEntered={handleDialogOnEntered}
      screen={screen}
      showDialog={isDialogVisible}
      tab={tab}
    />
  ) : null;
  const renderErrorDialog = isErrorDialogOpen ? (
    <MessageDialog
      content={body}
      isOpen={isErrorDialogOpen}
      onClose={handleCloseErrorDialog}
      title={title}
    />
  ) : null;

  return (
    <Fragment>
      {renderSnackbar}
      {renderDialog}
      {renderErrorDialog}
    </Fragment>
  );
};

SharedContent.propTypes = {
  app: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleCloseErrorDialog: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  handleDialogOnEntered: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  app: makeSelectApp(),
});

export const mapDispatchToProps = (dispatch) => ({
  handleCloseDialog: () => dispatch(hideDialog()),
  handleCloseSnackbar: () => dispatch(hideSnackbar()),
  handleDialogOnEntered: () => dispatch(updateDialogStatus({ entered: true })),
  reload: () => window.location.reload(false),
  handleCloseErrorDialog: () => dispatch(hideErrorDialog()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SharedContent);
