export const OPEN_NEW_VISIT_TIMEPICKER =
  'agentsPwa/Agenda/NewVisit/OPEN_NEW_VISIT_TIMEPICKER';
export const CLOSE_NEW_VISIT_TIMEPICKER =
  'agentsPwa/Agenda/NewVisit/CLOSE_NEW_VISIT_TIMEPICKER';
export const OPEN_NEW_VISIT_DATEPICKER =
  'agentsPwa/Agenda/NewVisit/OPEN_NEW_VISIT_DATEPICKER';
export const CLOSE_NEW_VISIT_DATEPICKER =
  'agentsPwa/Agenda/NewVisit/CLOSE_NEW_VISIT_DATEPICKER';
export const FETCH_NEW_VISIT_AVAILABILITY =
  'agentsPwa/Agenda/NewVisit/FETCH_NEW_VISIT_AVAILABILITY';
export const FETCH_REGISTER_AVAILABILITY =
  'agentsPwa/Agenda/NewVisit/FETCH_REGISTER_AVAILABILITY';
export const NEW_VISIT_SCHEDULED_ANALYTICS =
  'agentsPwa/Agenda/NewVisit/NEW_VISIT_SCHEDULED_ANALYTICS';
export const NEW_VISIT_NO_KEYS_VIEWED =
  'agentsPwa/Agenda/NewVisit/NEW_VISIT_NO_KEYS_VIEWED';
export const NEW_VISIT_URL_COPIED_ANALYTICS =
  'agentsPwa/Agenda/NewVisit/NEW_VISIT_URL_COPIED_ANALYTICS';
export const SET_NEW_VISIT_DATE =
  'agentsPwa/Agenda/NewVisit/SET_NEW_VISIT_DATE';
export const SET_NEW_VISIT_TIME =
  'agentsPwa/Agenda/NewVisit/SET_NEW_VISIT_TIME';
export const SET_NEW_VISIT_EVENT =
  'agentsPwa/Agenda/NewVisit/SET_NEW_VISIT_EVENT';
export const SET_NEW_VISIT_VISITOR_INPUT_VALUE =
  'agentsPwa/Agenda/NewVisit/SET_NEW_VISIT_VISITOR_INPUT_VALUE';
export const SET_NEW_VISIT_HOUSE_ID_INPUT_VALUE =
  'agentsPwa/Agenda/NewVisit/SET_NEW_VISIT_HOUSE_ID_INPUT_VALUE';
export const FETCH_USER_BY_EMAIL_OR_PHONE =
  'agentsPwa/Agenda/NewVisit/FETCH_USER_BY_EMAIL_OR_PHONE';
export const FETCH_HOUSE_INFO = 'agentsPwa/Agenda/NewVisit/FETCH_HOUSE_INFO';
export const ADD_HOUSE_INFO = 'agentsPwa/Agenda/NewVisit/ADD_HOUSE_INFO';
export const FINISH_SCHEDULE = 'agentsPwa/Agenda/NewVisit/FINISH_SCHEDULE';
export const FINISH_REGISTER = 'agentsPwa/Agenda/NewVisit/FINISH_REGISTER';
export const FINISH_REGISTER_SUPPORT =
  'agentsPwa/Agenda/NewVisit/FINISH_REGISTER_SUPPORT';
export const SET_VISIT_COMMENT = 'agentsPwa/Agenda/NewVisit/SET_VISIT_COMMENT';
export const SET_VISIT_TYPE = 'agentsPwa/Agenda/NewVisit/SET_VISIT_TYPE';
export const NEW_VISIT_REGISTER_DATE_YESTERDAY_ANALYTICS =
  'agentsPwa/Agenda/NewVisit/NEW_VISIT_REGISTER_DATE_YESTERDAY_ANALYTICS';
export const SELECTED_CLIENT = 'agentsPwa/Agenda/SELECTED_CLIENT';
