import { getDefaultPhoneCountryCode } from 'constants.js';

/**
 * @param {string} areaCode
 * @param {string} countryCode
 * @param {string} phoneNumber
 * @return {string} parsedPhone
 */
const parsePhone = ({ areaCode, countryCode, phoneNumber }) =>
  `${countryCode}${areaCode}${phoneNumber}`;

const hasCountryCode = ({ phone }) => phone.startsWith('+');

/**
 * Normalizes provided phone number by getting rid of parenthesis and spaces
 * @param {string} phone phone number, possibly containing parenthesis and spaces
 * @return {string} normalized phone number that can be called or opened in Whatsapp
 */
const normalizePhone = ({ phone, locale }) => {
  const normalized = phone.trim().replace(/[() -]/g, '');

  return hasCountryCode({ phone: normalized })
    ? normalized
    : `${getDefaultPhoneCountryCode(locale)}${normalized}`;
};

const normalizePhoneV2 = ({ phone, locale }) => {
  const normalized = phone.replace(/[^\d+]/g, '').trim();

  return hasCountryCode({ phone: normalized })
    ? normalized
    : `${getDefaultPhoneCountryCode(locale)}${normalized}`;
};

const phoneFormatter = ({ phone }) =>
  phone.replace(/(\d\d)(\d\d)(\d\d\d\d\d)(\d\d\d\d)/, '$1 $2 $3-$4');

export { parsePhone, normalizePhone, normalizePhoneV2, phoneFormatter };
