import { createSelector } from 'reselect';
import { MISSPLACED_KEYS } from 'enums/entranceProblem';
import BUSINESS_CONTEXTS from 'enums/businessContexts';

import { PROBLEM_WITH_KEYS } from './EntranceProblemsSection/ProblemsSection/constants';

export const selectFollowUp = (state) => state.get('followUp');
export const selectLoginDomain = (state) => state.get('login');
export const selectCurrentUser = (state) =>
  selectLoginDomain(state).get('user');
export const selectActionsOptions = (state) =>
  selectFollowUp(state).get('actionsOptions');

export const selectHouseAgentInfo = (state) =>
  selectFollowUp(state).get('houseAgentInfo');
export const selectVisitInfo = (state) =>
  selectFollowUp(state).get('visitInfo');
export const selectUserSelection = (state) =>
  selectFollowUp(state).get('userSelection');
export const selectEntranceProblem = (state) =>
  selectFollowUp(state).get('entranceProblem');
export const selectVisitType = (state) =>
  selectFollowUp(state).get('visitType');

export const selectHouse = (state) => selectVisitInfo(state).get('house');
export const selectHouseId = (state) => selectHouse(state).get('id');

export const selectVisitBusinessContext = (state) =>
  selectVisitInfo(state).get('businessContext');

export const selectHasAgentAttended = (state) =>
  selectUserSelection(state).get('hasAgentAttended');

export const selectHasOwnerAttended = (state) =>
  selectUserSelection(state).get('hasOwnerAttended');

export const selectOwner = (state) => selectFollowUp(state).get('owner');

export const selectAccessType = (state) =>
  selectFollowUp(state).get('accessType');

export const selectHasError = (state) => selectFollowUp(state).get('hasError');

export const selectIsSuccess = (state) =>
  selectFollowUp(state).get('isSuccess');

export const selectIsLoading = (state) =>
  selectFollowUp(state).get('isLoading');

export const selectVisitComment = (state) =>
  selectFollowUp(state).get('comment');

export const selectOwnerFeedback = (state) =>
  selectFollowUp(state).get('feedback');

export const makeSelectAccessType = () =>
  createSelector(selectAccessType, (accessType) => accessType.toJS());

export const makeSelectOwnerName = () =>
  createSelector(selectOwner, (owner) => owner.get('name'));

export const makeSelectAgentAlreadyHadKeys = () =>
  createSelector(selectHouseAgentInfo, (houseAgentInfo) =>
    houseAgentInfo.get('agentAlreadyHadKeys'),
  );

export const makeSelectMultipleHouses = () =>
  createSelector(selectHouseAgentInfo, (houseAgentInfo) =>
    houseAgentInfo.get('houseList'),
  );

export const makeSelectHouseDetails = () =>
  createSelector(selectHouseAgentInfo, (houseAgentInfo) =>
    houseAgentInfo.get('houseDetails').toJS(),
  );

export const makeSelectOwnerAttendsVisit = () =>
  createSelector(
    selectOwner,
    makeSelectAgentAlreadyHadKeys(),
    (owner, agentAlreadyHadKeys) =>
      owner.get('attendsVisit') && !agentAlreadyHadKeys,
  );

export const makeSelectEntranceProblemReasons = () =>
  createSelector(selectActionsOptions, (actionsOptions) =>
    actionsOptions.get('entranceProblemReasons').toJS(),
  );

export const makeSelectVisitComment = () =>
  createSelector(selectVisitComment, (comment) => comment);

export const makeSelectOwnerFeedback = () =>
  createSelector(selectOwnerFeedback, (feedback) => feedback);

export const makeSelectVisitType = () =>
  createSelector(selectVisitType, (visitType) => visitType);

export const makeSelectEntranceProblem = () =>
  createSelector(selectEntranceProblem, (entranceProblem) =>
    entranceProblem.toJS(),
  );

export const makeSelectHasEntranceProblem = () =>
  createSelector(selectEntranceProblem, (entranceProblem) =>
    entranceProblem.get('hasEntranceProblem'),
  );

export const makeSelectAccessAuthorizationType = () =>
  createSelector(selectEntranceProblem, (entranceProblem) =>
    entranceProblem.get('accessAuthorizationType'),
  );

export const makeSelectAgentHasKey = () =>
  createSelector(selectHouseAgentInfo, (houseAgentInfo) =>
    houseAgentInfo.get('agentHasKeys'),
  );

export const makeSelectShowKeyHolderInfo = () =>
  createSelector(selectHouseAgentInfo, (houseAgentInfo) =>
    houseAgentInfo.get('showKeyHolderInfo'),
  );

export const makeSelectVisitors = () =>
  createSelector(selectUserSelection, (userSelection) =>
    userSelection.get('visitors').toJS(),
  );

export const makeSelectHouseListChecks = () =>
  createSelector(selectHouseAgentInfo, (houseAgentInfo) =>
    houseAgentInfo.get('houseListChecks'),
  );

const isHasAgentAttendedValid = (hasAgentAttended) =>
  hasAgentAttended !== null && hasAgentAttended;

export const makeSelectHasAgentAttended = () =>
  createSelector(
    selectHasAgentAttended,
    (hasAgentAttended) => hasAgentAttended,
  );

export const makeSelectIsHasAgentAttendedValid = () =>
  createSelector(
    selectHasAgentAttended,
    (hasAgentAttended) => !isHasAgentAttendedValid(hasAgentAttended),
  );

export const makeSelectIsEntranceProblemSelectorDisabled = () =>
  createSelector(selectUserSelection, (userSelection) => {
    const visitors = userSelection.get('visitors');
    const noVisitorShowed = visitors.every(
      (visitor) => !visitor.get('isChecked'),
    );
    const hasNoVisitorSelected = visitors.some(
      (visitor) => visitor.get('isChecked') === null,
    );

    return noVisitorShowed || hasNoVisitorSelected;
  });

const isEntranceProblemValid = (
  ownerAttendsVisit,
  hasOwnerAttended,
  entranceProblem,
) => {
  const hasEntranceProblem = entranceProblem.get('hasEntranceProblem');

  if (ownerAttendsVisit && !hasOwnerAttended) {
    return hasEntranceProblem !== null;
  }

  return (
    hasEntranceProblem !== null &&
    (!hasEntranceProblem || entranceProblem.get('reason') !== null)
  );
};

export const makeSelectIsVisitTypeSelectorDisabled = () =>
  createSelector(
    selectEntranceProblem,
    (entranceProblem) => !isEntranceProblemValid(null, null, entranceProblem),
  );

const isVisitTypeValid = (visitType) => visitType !== null;

const isSelectionValid = (
  ownerAttendsVisit,
  hasOwnerAttended,
  entranceProblem,
  userSelection,
  visitTypeValid,
) => {
  const visitors = userSelection.get('visitors');
  const noVisitorShowed = visitors.every(
    (visitor) => visitor.get('isChecked') === false,
  );
  const entranceProblemValid = isEntranceProblemValid(
    ownerAttendsVisit,
    hasOwnerAttended,
    entranceProblem,
  );
  return visitTypeValid && (noVisitorShowed || entranceProblemValid);
};

const isEntranceInfoSectionValid = (
  entranceProblem,
  comment,
  visitors,
  agentAlreadyHadKeys,
) => {
  const noVisitorShowed = visitors.every(
    (visitor) => visitor.get('isChecked') === false,
  );
  const hasEntranceProblem = entranceProblem.get('hasEntranceProblem');
  const reason = entranceProblem.get('reason');

  if (
    noVisitorShowed ||
    hasEntranceProblem ||
    reason === 'None' ||
    agentAlreadyHadKeys
  ) {
    return true;
  }

  const accessAuthorizationType = entranceProblem.get(
    'accessAuthorizationType',
  );

  if (accessAuthorizationType === 'Other') {
    return !!comment;
  }

  return !!accessAuthorizationType;
};

const isEntranceProblemSectionValid = (
  ownerAttendsVisit,
  hasOwnerAttended,
  entranceProblem,
  comment,
  visitors,
) => {
  const noVisitorShowed = visitors.every(
    (visitor) => visitor.get('isChecked') === false,
  );
  const hasEntranceProblem = entranceProblem.get('hasEntranceProblem');
  const isValidOwnerDidNotAttendValue =
    ownerAttendsVisit && !hasOwnerAttended && hasEntranceProblem;

  if (noVisitorShowed || isValidOwnerDidNotAttendValue || !hasEntranceProblem) {
    return true;
  }

  const reason = entranceProblem.get('reason');
  const accessAuthorizationType = entranceProblem.get(
    'accessAuthorizationType',
  );

  const isReasonValid = reason !== PROBLEM_WITH_KEYS;
  const isCommentRequired =
    reason === MISSPLACED_KEYS && accessAuthorizationType === 'Other';
  const isCommentValid = isCommentRequired ? !!comment : true;
  const isAccessAuthorizationTypeValid =
    reason === MISSPLACED_KEYS ? !!accessAuthorizationType : true;

  return isReasonValid && isCommentValid && isAccessAuthorizationTypeValid;
};

export const makeSelectIsNextButtonEnabled = () =>
  createSelector(
    makeSelectOwnerAttendsVisit(),
    makeSelectAgentAlreadyHadKeys(),
    selectHasOwnerAttended,
    selectEntranceProblem,
    selectUserSelection,
    selectVisitType,
    selectVisitComment,
    selectOwnerFeedback,
    selectVisitBusinessContext,
    (
      ownerAttendsVisit,
      agentAlreadyHadKeys,
      hasOwnerAttended,
      entranceProblem,
      userSelection,
      visitType,
      comment,
      feedback,
      businessContext,
    ) => {
      if (userSelection.get('hasAgentAttended') === false) {
        return true;
      }

      const visitTypeValid = isVisitTypeValid(visitType);
      const selectionValid = isSelectionValid(
        ownerAttendsVisit,
        hasOwnerAttended,
        entranceProblem,
        userSelection,
        visitTypeValid,
      );

      const visitors = userSelection.get('visitors');
      const entranceInfoSectionValid = isEntranceInfoSectionValid(
        entranceProblem,
        comment,
        visitors,
        agentAlreadyHadKeys,
      );
      const entranceProblemSectionValid = isEntranceProblemSectionValid(
        ownerAttendsVisit,
        hasOwnerAttended,
        entranceProblem,
        comment,
        visitors,
      );

      const followUpSectionValid =
        businessContext === BUSINESS_CONTEXTS.FOR_SALE ? !!feedback : true;

      return (
        selectionValid &&
        entranceInfoSectionValid &&
        entranceProblemSectionValid &&
        followUpSectionValid
      );
    },
  );

const isAgentHasKeysValid = (agentHasKeys) =>
  agentHasKeys !== null && agentHasKeys !== undefined;

export const makeSelectIsSubmitButtonEnabled = () =>
  createSelector(
    makeSelectIsNextButtonEnabled(),
    selectHouseAgentInfo,
    selectHasAgentAttended,
    selectHasOwnerAttended,
    (
      isNextButtonEnabled,
      houseAgentInfo,
      agentHasAttended,
      ownerHasAttended,
    ) => {
      // Checks with false to avoid undefined or null
      if (agentHasAttended === false) {
        return true;
      }
      const showKeyHolderInfo = houseAgentInfo.get('showKeyHolderInfo');
      const agentHasKeys = houseAgentInfo.get('agentHasKeys');
      const agentHasOtherHousesKeys = houseAgentInfo.get(
        'agentHasOtherHousesKeys',
      );
      const allOtherHousesChecked = houseAgentInfo.get('allOtherHousesChecked');
      const houseList = [...houseAgentInfo.get('houseList')];
      const agentAlreadyHadKeys = houseAgentInfo.get('agentAlreadyHadKeys');

      const multipleHouseSectionValid =
        houseList.length > 0 && !agentAlreadyHadKeys
          ? agentHasOtherHousesKeys === false || allOtherHousesChecked
          : true;
      const keySectionValid =
        !ownerHasAttended ||
        !showKeyHolderInfo ||
        (isAgentHasKeysValid(agentHasKeys) && multipleHouseSectionValid);

      return isNextButtonEnabled && keySectionValid;
    },
  );

export const makeSelectIsEntranceProblemsSectionVisible = () =>
  createSelector(
    makeSelectOwnerAttendsVisit(),
    selectHasOwnerAttended,
    makeSelectHasEntranceProblem(),
    (ownerAttendsVisit, hasOwnerAttended, hasEntranceProblem) => {
      if (ownerAttendsVisit) {
        return hasOwnerAttended && hasEntranceProblem;
      }

      return hasEntranceProblem;
    },
  );

export const makeSelectIsEntranceInfoSectionVisible = () =>
  createSelector(selectUserSelection, (userSelection) => {
    if (userSelection.get('hasAgentAttended') !== true) {
      return false;
    }

    const visitors = userSelection.get('visitors');
    const noVisitorShowed = visitors.every(
      (visitor) => !visitor.get('isChecked'),
    );

    return !noVisitorShowed;
  });

export const makeSelectIsVisitTypeSectionVisible = () =>
  createSelector(
    selectUserSelection,
    (userSelection) => userSelection.get('hasAgentAttended') === true,
  );

export const makeSelectSelectedReason = () =>
  createSelector(selectEntranceProblem, (entranceProblem) => {
    const reason = entranceProblem.get('reason');

    return reason === '' ? null : reason;
  });

export const makeSelectVisitInfo = () =>
  createSelector(selectFollowUp, (visitInfo) =>
    visitInfo.get('visitInfo').toJS(),
  );

export const makeSelectIsCommentSectionVisible = () =>
  createSelector(
    selectEntranceProblem,
    selectUserSelection,
    selectHasAgentAttended,
    selectVisitType,
    (entranceProblem, userSelection, agentHasAttended, visitType) => {
      const hasEntranceProblem = entranceProblem.get('hasEntranceProblem');
      const anyVisitorAttended = userSelection
        .get('visitors')
        .some((visitor) => visitor.get('isChecked') === true);
      const visitTypeValid = isVisitTypeValid(visitType);

      return (
        agentHasAttended &&
        anyVisitorAttended &&
        hasEntranceProblem === false &&
        visitTypeValid
      );
    },
  );

export const makeSelectIsKeySectionDisabled = () =>
  createSelector(
    selectHasAgentAttended,
    selectHouseAgentInfo,
    (agentHasAttended, houseAgentInfo) => {
      const showKeyHolderInfo = houseAgentInfo.get('showKeyHolderInfo');

      return !agentHasAttended || !showKeyHolderInfo;
    },
  );

export const makeSelectIsOwnerLoaded = () =>
  createSelector(selectOwner, (owner) => !!owner.size);

export const makeSelectIsFollowUpReady = () =>
  createSelector(
    makeSelectShowKeyHolderInfo(),
    makeSelectIsOwnerLoaded(),
    (showKeyHolderInfo, ownerLoaded) =>
      showKeyHolderInfo !== null && ownerLoaded,
  );

export const makeSelectAgentHasOtherHousesKeys = () =>
  createSelector(selectHouseAgentInfo, (houseAgentInfo) =>
    houseAgentInfo.get('agentHasOtherHousesKeys'),
  );
