import CreateAccountContainer from '@quintoandar/bioma-auth/containers/CreateAccount';
import ThirdPartyForbidden from 'containers/Forbidden/ThirdPartyForbidden';

import { CRM_CONTEXT } from 'containers/Redirecting/constants';

export const AgentsCreateAccount = (props) => {
  const hasCrmContext = localStorage.getItem(CRM_CONTEXT);
  if (hasCrmContext) {
    return <ThirdPartyForbidden />;
  }
  return <CreateAccountContainer {...props} />;
};

export default AgentsCreateAccount;
