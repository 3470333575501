export const trackingConfig = {
  globalConfig: {
    isOnServer: typeof window === 'undefined',
    build: process.env.BUILD,
    environment: process.env.ENV_PREFIX,
  },
  amplitudeConfig: {
    apiKey: process.env.AMPLITUDE_API_KEY,
  },
};
