import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { getBrandName } from 'utils/i18n/brand';

import { ICON_LINKS } from './links';
import messages from './messages';

const Head = () => {
  const intl = useIntl();
  const htmlAttributes = { lang: intl.locale };

  const iconLinks = ICON_LINKS[intl.locale];

  const agentsTitle = intl.formatMessage(messages.title, {
    companyName: getBrandName(),
  });

  return (
    <Helmet htmlAttributes={htmlAttributes}>
      <title>{agentsTitle}</title>
      <meta name="apple-mobile-web-app-title" content={agentsTitle} />

      {iconLinks.map((link) => (
        <link {...link} />
      ))}
    </Helmet>
  );
};

export default Head;
