export const FETCH_CANCELATION_REASON = 'FETCH_CANCELATION_REASON';
export const AGENDA_VISIT_DETAIL_SEND_MESSAGE_CLICKED =
  'agenda_visit_detail_send_message_clicked';
export const AGENDA_VISIT_DETAIL_NEW_VISIT_CLICKED =
  'agenda_visit_detail_new_visit_clicked';
export const AGENDA_VISIT_DETAIL_ALERT_STATUS_CONFIRMED =
  'app/containers/Agenda/Detail/VisitInfo/AGENDA_VISIT_DETAIL_ALERT_STATUS_CONFIRMED';
export const VISIT_DETAILS_THIRD_PARTY_INFO_CLICKED =
  'app/containers/Agenda/Detail/VisitInfo/VISIT_DETAILS_THIRD_PARTY_INFO_CLICKED';
export const AGENDA_VISIT_DETAIL_OWNER_CONTACT_OPEN_CLICKED_ANALYTICS =
  'app/containers/Agenda/Detail/VisitInfo/AGENDA_VISIT_DETAIL_OWNER_CONTACT_OPEN_CLICKED_ANALYTICS';
export const AGENDA_VISIT_DETAIL_OWNER_CONTACT_CLOSE_CLICKED_ANALYTICS =
  'app/containers/Agenda/Detail/VisitInfo/AGENDA_VISIT_DETAIL_OWNER_CONTACT_CLOSE_CLICKED_ANALYTICS';
export const FETCH_VISIT_DETAIL =
  'app/containers/Agenda/Detail/VisitInfo/FETCH_VISIT_DETAIL';
export const AGENDA_VISIT_DETAIL_CANCELED_VISIT_SEND_MESSAGE_CLICKED =
  'app/containers/Agenda/Detail/VisitInfo/AGENDA_VISIT_DETAIL_CANCELED_VISIT_SEND_MESSAGE_CLICKED';
export const AGENDA_VISIT_DETAIL_CANCELED_VISIT_RESCHEDULE_CLICKED =
  'app/containers/Agenda/Detail/VisitInfo/AGENDA_VISIT_DETAIL_CANCELED_VISIT_RESCHEDULE_CLICKED';
