export const FETCH_HOUSE_SUGGESTIONS_BY_VISITOR =
  'app.VisitorInfo.State.FETCH_HOUSE_SUGGESTIONS_BY_VISITOR';
export const FETCH_VISITOR_INFO = 'app.VisitorInfo.State.FETCH_VISITOR_INFO';
export const FETCH_NEXT_VISITS_WITH_ME =
  'app.VisitorInfo.State.FETCH_NEXT_VISITS_WITH_ME';
export const FETCH_VISITOR_LAST_VISITS =
  'app.VisitorInfo.State.FETCH_VISITOR_LAST_VISITS';

export const VISITOR_INFO_PAGE_VIEWED =
  'app.VisitorInfo.State.VISITOR_INFO_PAGE_VIEWED';
export const VISITOR_INFO_WHATSAPP_CLICKED =
  'app.VisitorInfo.State.VISITOR_INFO_WHATSAPP_CLICKED';
export const VISITOR_INFO_PHONE_CLICKED =
  'app.VisitorInfo.State.VISITOR_INFO_PHONE_CLICKED';
export const RESET_TO_INITIAL_STATE =
  'app.VisitorInfo.State.RESET_TO_INITIAL_STATE';
